<template>
  <header class="page-header">
      <div class="logo-wrap">
          <img src="@/assets/imgs/edm/logo-black.png" alt="logo">
      </div>
      <div class="branch-name">
          <span>{{ user.store_name }}</span>    
      </div>
  </header>
</template>

<script>
import { useRoute } from 'vue-router';
import QrcodeVue from 'qrcode.vue';
import imageToBase64 from "image-to-base64/browser";

export default {
  components: {
    QrcodeVue
  },
  props: ['user'],
  data() {
    return {
      basePath: process.env.VUE_APP_BASEPATH,
      Packages: [],
    }
  },
  async mounted() {
    this.userImage = await imageToBase64("https://www.century21.com.tw/store/images/jobnewsman.png");
  },
  methods: {
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : '';
    },
    getQRcode(employee_id) {
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(employee_id)}`;
    },
  }
}
</script>


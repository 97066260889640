<template>
  <div class="edm-3-1 w-620" id="printHTML">
    <!-- EDM預覽 -->
    <div v-for="items in list" class="page-wrap layout-count-1">
        
        <Edm3Header620 :user="user" />

        <section class="page-body">

            <div class="card-wrap">
                <div class="card-hero-wrap">
                    <div class="card-hero-img">
                        <img :src="`data:image/png;base64,${items.image}`" alt="物件照片">
                    </div>
                    <div class="card-title">
                        <p>{{ items.name }}</p>
                    </div>
                    <div class="card-price">
                        <span>{{ items.price.toLocaleString() }}萬</span>
                    </div>
                </div>
        
                <div class="contact-wrap">
                    <div class="info-wrap">
                        <div class="img-wrap">
                            <img :src="user.picture_base64 ? user.picture_base64 : userImage" alt="專業經理人">
                        </div>
                        <div class="text-wrap">
                            <span class="info-title">專業經理人</span>
                            <p class="info-name">{{ user.name }}</p>
                            <p class="info-tel">{{ user.phone }}</p>
                        </div>
                    </div>
                    <div class="info-wrap">
                        <div class="img-wrap">
                            <qrcode-vue 
                              id="QRcode"
                              :value="getQRcode(items.d_type, items.source_number, user.employee_id)"
                              style="width: 95px; height: 95px;"
                            ></qrcode-vue>
                        </div>
                        <div class="text-wrap">
                            <span class="info-title">{{ user.company_name }}</span>
                            <span class="info-title">地址</span>
                            <p class="info-address">{{ user.store_address }}</p>
                        </div>
                    </div>
                </div>
        
                <div class="card-info">
                    <div class="card-info-inner">
                        <div class="card-img" v-if="imageB">
                            <img :src="`data:image/png;base64,${imageB}`" alt="物件照片">
                        </div>
                        <div class="card-img" v-if="imageC">
                            <img :src="`data:image/png;base64,${imageC}`" alt="物件照片">
                        </div>
                        <div class="card-img" v-if="imageD">
                            <img :src="`data:image/png;base64,${imageD}`" alt="物件照片">
                        </div>
                    </div>
                    <div class="card-detail">
                        <div class="detail-list-title">物件基本資料</div>
                        <ul class="detail-list">
                            <li class="list-item">
                                <span class="item-title">地址</span>
                                <span class="item-content pt-1" style="height: 1.5em; line-height: 1em;">
                                  {{ items.county }}{{ items.district }}{{ items.address }}
                                </span>
                            </li>
                            <li class="list-item">
                                <span class="item-title">總坪數</span>
                                <span class="item-content">
                                  <span class="text-danger">{{ Number(items.total_floor_space).toFixed(2) }}</span> 坪
                                </span>
                            </li>
                            <li class="list-item">
                                <span class="item-title">格局</span>
                                <span class="item-content">
                                  {{
                                    setStructure(
                                      Number(items.structure_room),
                                      Number(items.structure_hall),
                                      Number(items.structure_bath)
                                    )
                                  }}
                                </span>
                            </li>
                            <li class="list-item">
                                <span class="item-title">樓層</span>
                                <span class="item-content">{{ items.floorDescription.replace(/(\S)\/(\S)/g, '$1 / $2').replace(/(\d+)\s+(樓)/g, '$1$2') }}</span>
                            </li>
                            <li class="list-item">
                                <span class="item-title">屋齡</span>
                                <span class="item-content">{{ Number(items.age) > 0 ? items.age + "年" : "-" }}</span>
                            </li>
                            <li class="list-item">
                                <span class="item-title">種類</span>
                                <span class="item-content">{{ items.type.replace(/,/g, '．') }}</span>
                            </li>
                            <li class="list-item">
                                <span class="item-title">車位</span>
                                <span class="item-content">{{ items.if_parking_space }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        
        <Edm3Footer />

    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import Edm3Header620 from "@/components/Edm3Header620.vue";
import Edm3Contact from "@/components/Edm3Contact.vue";
import Edm3Footer from "@/components/Edm3Footer.vue";
import QrcodeVue from "qrcode.vue";
import imageToBase64 from "image-to-base64/browser";
import html2Canvas from "html2canvas";

export default {
  components: {
    Edm3Header620,
    Edm3Contact,
    Edm3Footer,
    QrcodeVue,
  },
  data() {
    return {
      list: [],
      userImage: "",
      imageB: "",
      imageC: "",
      imageD: "",
    };
  },
  props: ["data", "user", "isPrint", "isDownload"],
  watch: {
    isPrint: function print(newVal) {
      if (newVal) {
        const canvas = document.getElementById("QRcode");
        const QRbase64 = canvas.toDataURL();
        this.$emit("printHTML", this.$el.innerHTML, QRbase64);
      }
    },
    isDownload: function print(newVal) {
      if (newVal) {
        // const canvas = document.getElementById("QRcode");
        // const QRbase64 = canvas.toDataURL();
        let filename = "";
        this.data.edm.items.map((item) => {
          filename += `${item.name}, `;
        });
        this.$emit("downloadHTML", filename, 1);
      }
    },
  },
  async mounted() {
    this.userImage = await imageToBase64(
      "https://www.century21.com.tw/store/images/jobnewsman.png"
    );

    let array = this.data.edm.items;
    for (let i = 0; i < array.length; i++) {
      const image = await imageToBase64(array[i].image);
      this.list.push({ ...array[i], image: image });

      // 重組image url取得其他圖片
      // 21取得的圖片檔名第一張Ai.jpg, 第二張Bi.jpg, 第三張Ci.jpg...
      let urls = array[i].image.split('Ai');
      this.imageB = await imageToBase64(urls[0] + 'Bi' + urls[1]);
      this.imageC = await imageToBase64(urls[0] + 'Ci' + urls[1]);
      this.imageD = await imageToBase64(urls[0] + 'Di' + urls[1]);
    }
    setTimeout(() => {
      this.getHTML(document.getElementById("printHTML"));
    }, 1000);
  },
  methods: {
    setStructure(room, hall, bath) {
      const rooms = [];
      if (room > 0) {
        rooms.push(room + " 房");
      }
      if (hall > 0) {
        rooms.push(hall + " 廳");
      }
      if (bath > 0) {
        rooms.push(bath + " 衛");
      }
      return rooms.length > 0 ? rooms.join(" | ") : '-';
    },
    async getHTML(data) {
      let pageData = await html2Canvas(data, {
        scale: 3,
        allowTaint: true,
      });
      let imgUrl = await pageData.toDataURL("image/jpeg");
      this.$emit("imgUrl", imgUrl);
    },
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : "";
    },
    getQRcode(type, id, employee_id) {
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(employee_id)}/${
        type == "R" ? "rent" : "buy"
      }/${id}`;
    },
    getFloor(floor, max_floor, total_floor) {
      return (!total_floor && Number(total_floor) == 0) || (!floor && !max_floor)
        ? "未提供資訊"
        : (Number(floor) == 0 && Number(max_floor) == 0) ||
          (Number(floor) == 0 && !max_floor) ||
          (!floor && Number(max_floor) == 0)
        ? "整棟"
        : (Number(floor) == -1 && Number(max_floor) == -1) ||
          (Number(floor) == -1 && !max_floor) ||
          (!floor && Number(max_floor) == -1)
        ? "地下室"
        : (!floor || Number(floor) == 0) && Number(max_floor) != 0
        ? `${max_floor}樓/${total_floor}樓`
        : (!max_floor || Number(max_floor) == 0) && Number(floor) != 0
        ? `${floor}樓/${total_floor}樓`
        : floor == max_floor
        ? `${floor}樓/${total_floor}樓`
        : `${floor}-${max_floor}樓/${total_floor}樓`;
    },
  },
};
</script>

<template>
  <div class="edm-1-1 w-620" id="printHTML">
    <!-- EDM預覽 -->
    <section class="section py-0">
      <div class="container-fluid p-0">
        <!-- EDM風格一 -->
        <div v-for="items in list" class="edm-style-1">
          <div class="image">
            <img class="cover" :src="`data:image/png;base64,${items.image}`" />
            <div class="logo">
              <img class="img-fluid" src="@/assets/imgs/edm/logo.png" />
            </div>
          </div>
          <div class="header">
            <div class="slogan">我們為你 找到21世紀的家</div>
            <div class="title">{{ items.name }}</div>
          </div>
          <div class="body">
            <div class="left box">
              <div class="title">物件基本資料</div>
              <ul class="list">
                <li>
                  <div class="label">地址</div>
                  <div class="address-wrap" style="height: 1.5em;">{{ items.county }}{{ items.district }}{{ items.address }}</div>
                </li>
                <li>
                  <div class="label">總坪數</div>
                  <span class="text-danger">
                    {{ Number(items.total_floor_space).toFixed(2) }}
                  </span>
                  坪
                </li>
                <li>
                  <div class="label">格局</div>
                  <div class="text">
                    {{
                      setStructure(
                        Number(items.structure_room),
                        Number(items.structure_hall),
                        Number(items.structure_bath)
                      )
                    }}
                  </div>
                </li>
                <li>
                  <div class="label">樓層</div>
                  {{ items.floorDescription }}
                </li>
                <li>
                  <div class="label">屋齡</div>
                  {{ Number(items.age) > 0 ? items.age + "年" : "-" }}
                </li>
                <li>
                  <div class="label">種類</div>
                  <div class="text">{{ items.type }}</div>
                </li>
                <li>
                  <div class="label">車位</div>
                  {{ items.if_parking_space }}
                </li>
              </ul>
            </div>
            <div class="right">
              <div class="total-box box">
                <div class="total">
                  總價 $ <b class="text-danger">{{ items.price.toLocaleString() }}</b> 萬
                </div>
              </div>

              <div class="space"></div>
              <div class="sales-box box">
                <div>
                  <div class="avatar">
                    <img :src="user.picture_base64 ? user.picture_base64 : userImage" />
                  </div>
                </div>
                <div class="title">
                  <!-- 業務品牌 -->
                  <div class="job-title">專業經理人</div>
                  <!-- 業務名稱 -->
                  <div class="name">{{ user.name }}</div>
                  <div class="name">{{ user.phone }}</div>
                </div>
              </div>
              <div class="qrcode">
                  <qrcode-vue
                  id="QRcode"
                  size="64"
                  :value="getQRcode(items.d_type, items.source_number, user.employee_id)"
                ></qrcode-vue>
              </div>
              <!-- 業務加盟店名 -->
              <div class="store-title">
                21世紀不動產
                {{ user.store_name }}
              </div>

              <div class="store-box box">
                <ul class="list">
                  <li>
                    <div class="label">經紀業名稱</div>
                    {{ user.company_name }}
                  </li>
                  <li>
                    <div class="label">地址</div>
                    {{ user.store_address }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <EdmFooter />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import EdmFooter from "@/components/EdmFooter.vue";
import QrcodeVue from "qrcode.vue";
import imageToBase64 from "image-to-base64/browser";
import html2Canvas from "html2canvas";

export default {
  components: {
    EdmFooter,
    QrcodeVue,
  },
  data() {
    return {
      list: [],
      userImage: "",
    };
  },
  props: ["data", "user", "isPrint", "isDownload"],
  watch: {
    isPrint: function print(newVal) {
      if (newVal) {
        const canvas = document.getElementById("QRcode");
        const QRbase64 = canvas.toDataURL();
        this.$emit("printHTML", this.$el.innerHTML, QRbase64);
      }
    },
    isDownload: function print(newVal) {
      if (newVal) {
        // const canvas = document.getElementById("QRcode");
        // const QRbase64 = canvas.toDataURL();
        let filename = "";
        this.data.edm.items.map((item) => {
          filename += `${item.name}, `;
        });
        this.$emit("downloadHTML", filename, 1);
      }
    },
  },
  async mounted() {
    this.userImage = await imageToBase64(
      "https://www.century21.com.tw/store/images/jobnewsman.png"
    );

    let array = this.data.edm.items;
    for (let i = 0; i < array.length; i++) {
      const image = await imageToBase64(array[i].image);
      this.list.push({ ...array[i], image: image });
    }
    setTimeout(() => {
      this.getHTML(document.getElementById("printHTML"));
    }, 1000);
  },
  methods: {
    setStructure(room, hall, bath) {
      const rooms = [];
      if (room > 0) {
        rooms.push(room + " 房");
      }
      if (hall > 0) {
        rooms.push(hall + " 廳");
      }
      if (bath > 0) {
        rooms.push(bath + " 衛");
      }
      return rooms.length > 0 ? rooms.join(" | ") : '-';
    },
    async getHTML(data) {
      let pageData = await html2Canvas(data, {
        scale: 3,
        allowTaint: true,
      });
      let imgUrl = await pageData.toDataURL("image/jpeg");
      this.$emit("imgUrl", imgUrl);
    },
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : "";
    },
    getQRcode(type, id, employee_id) {
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(employee_id)}/${
        type == "R" ? "rent" : "buy"
      }/${id}`;
    },
    getFloor(floor, max_floor, total_floor) {
      return (!total_floor && Number(total_floor) == 0) || (!floor && !max_floor)
        ? "未提供資訊"
        : (Number(floor) == 0 && Number(max_floor) == 0) ||
          (Number(floor) == 0 && !max_floor) ||
          (!floor && Number(max_floor) == 0)
        ? "整棟"
        : (Number(floor) == -1 && Number(max_floor) == -1) ||
          (Number(floor) == -1 && !max_floor) ||
          (!floor && Number(max_floor) == -1)
        ? "地下室"
        : (!floor || Number(floor) == 0) && Number(max_floor) != 0
        ? `${max_floor}樓/${total_floor}樓`
        : (!max_floor || Number(max_floor) == 0) && Number(floor) != 0
        ? `${floor}樓/${total_floor}樓`
        : floor == max_floor
        ? `${floor}樓/${total_floor}樓`
        : `${floor}-${max_floor}樓/${total_floor}樓`;
    },
  },
};
</script>

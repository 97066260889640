<template>
  <NavBar2 type="member"/>
  <div>
    <!-- {{ Packages.items[0].image }}
    {{ Packages.items[0].id }}
    {{ Packages.items[0].name }} -->

    <!-- {{ Packages.items[12] }} -->

    <!-- <div v-for="items in Packages.items" > {{items.id}}</div> -->

  </div>

  <section class="section">
    <div class="container">

      <!-- <a href="{{ route('front.estate-century.filter') }}" class="btn btn-primary">重新篩選</a> -->
      <!-- <h5>21物件</h5> -->

      <!--呈現的條件-->
      <small class="filter-text d-none">
        <!-- <span v-if="keyword">{{ keyword }},</span>
        <span v-if="address">{{ address }},</span>
        <span v-if="store_name">{{ store_name }},</span>
        <span v-if="county">{{ county }},</span>
        <span v-if="district">{{ district }},</span>
        <span v-if="floor">總樓層{{ floor }},</span>
        <span v-if="structure">格局{{ structure }},</span>
        <span v-if="age">屋齡{{ age }},</span>
        <span v-if="min_floor_space">主建物坪數{{ min_floor_space }}~</span>
        <span v-if="max_floor_space">{{ max_floor_space }}坪,</span>
        <span v-if="min_total_floor_space">總坪數{{ min_total_floor_space }}~</span>
        <span v-if="max_total_floor_space">{{ max_total_floor_space }}坪,</span>
        <span v-if="min_price">總價{{ min_price }}~</span>
        <span v-if="max_price">{{ max_price }}萬,</span>
        <span v-if="date_range">{{ date_range }},</span>
        <span v-if="type">{{ type }},</span>
        <span v-if="status">{{ status }},</span>
        <span v-if="d_type">{{ d_type }},</span> -->
        <!-- {{ $d_param_key }}: --><br>
      </small>
      <form id="Form" action="">

        <!--要帶入的條件參數-->
        <input type="hidden" name="" value="">
        <div class="form-row mb-4">
          <div class="col-5">
            <!-- <label>排序方式</label> -->
            <select class="custom-select" @change="sortByOption($event)" name="sort" id="sort">
              <option value="">不限</option>
              <option value="price-desc">總價高到低排序</option>
              <option value="price-asc">總價低到高排序</option>
              <option value="total_floor_space-desc">坪數大到小排序</option>
              <option value="total_floor_space-asc">坪數小到大排序</option>
              <option value="age-desc">屋齡高到低排序</option>
              <option value="age-asc">屋齡低到高排序</option>
              <option value="post_at-desc" selected="selected">上架日期新到舊</option>
              <option value="post_at-asc">上架日期舊到新</option>
            </select>
          </div>
          <div class="col-6 d-flex">
            <small class="text-muted my-auto">共<span class="text-danger">{{ Packages?.pagination?.total }}
              </span>筆資料</small>
          </div>
        </div>

      </form>

      <!-- loding..... -->
      <div class="loding d-flex justify-content-center">
        <loading v-model:active="isLoading" :loader="'dots'" :can-cancel="true" />
      </div>

      <div class="empty-list" v-if="Packages?.items?.length == 0 && isLoading == false">
        <span>目前沒有物件符合您的需求，請重新搜尋</span>
      </div>

      <ul class="estate-list" v-else>
        <li v-for="items in Packages.items" class="estate-list-item">
          <div class="form-row">
            <div class="col-8">
              <div class="form-row top-row mb-2">
                <div class="col">
                  <a class="name font-weight-bold" @click="objectLink(items.hUrl)">
                    {{ items.name }}
                  </a>
                </div>

              </div>
              <div class="estate-item">
                {{ items.county }}{{ items.district }}{{ items.address }}
                <span class="name">
                </span><br>
                <small>坪數{{ Number(items.total_floor_space).toFixed(2) }}｜{{ items.structure ? items.structure.replaceAll('.0', '') : '-' }}｜{{ Number(items.age) > 0 ? items.age : '-' }}年
                  {{ '｜' + items.floorDescription }}{{ items.type ? '｜' + items.type : '' }}</small>
              </div>

              <!-- 案件來源、上架時間 -->
              <div class="ObjectInfo d-flex mb-2">
                <div class="brand">
                  <small class="date">來源：{{ items.brand }}</small>
                </div>
                <div class="createTime ml-3">
                  <small class="date">上架時間：{{ dateFormat(items.post_at) }}</small>
                </div>
              </div>

              <button class="btn btn-primary btn-sm" type="button" @click="goObject(items)">分享案件</button>
            </div>

            <div class="col-4">
              <div class="image">
                <img :src="`${items.image}`" class="estate-item" data-id="">
              </div>
              <div class="text-right mt-1">
                <span class="price">{{ items.price.toLocaleString() }}</span><small>萬</small>
              </div>
              <div class="col-auto pr-0 d-block favorite-button">
                <button v-if="`${items.is_favorite}` == 1" v-on:click="changeFavorite(`${items.id}`)"
                  class="btn btn-sm favorite " :id="'favorite' + `${items.id}`" data-type="estate_century">
                  <i class="fa-solid fa-heart" style="color:#C0AC79;"></i>
                </button>

                <button v-else-if="`${items.is_favorite}` == 0" class="btn btn-sm favorite"
                  :id="'favorite' + `${items.id}`" v-on:click="changeFavorite(`${items.id}`)"
                  data-type="estate_century">
                  <i class="fa-regular fa-heart" style="color:#C0AC79;"></i>
                </button>
              </div>
            </div>

          </div>

        </li>

      </ul>
    </div>
  </section>

  <!-- 頁數選單 -->
  <div class="d-flex justify-content-center mb-5" v-if="Packages?.items?.length > 0">
    <paginate :page-count="`${Packages.pagination.total_pages}`" :page-range="3" :margin-pages="2"
      :click-handler="clickCallback" :prev-text="'<'" :next-text="'>'" :container-class="'pagination'"
      :page-class="'page-item'" v-model="page">
    </paginate>
  </div>

</template>

<script>
import $ from 'jquery';
import NavBar2 from '@/components/NavBar2.vue';
import { useRoute } from 'vue-router';

// 頁面按鈕
import Paginate from "vuejs-paginate-next";

// 排序引用
import dictionary from '@/assets/data/dictionary.json'

import Loading from "vue-loading-overlay";

export default {
  name: 'member-century',
  components: {
    NavBar2,
    paginate: Paginate,
    Loading
  }, data() {
    const route = useRoute();
    return {
      basePath: process.env.VUE_APP_BASEPATH,
      
      page: '',

      Packages: [],

      // 點擊收藏功能
      isFavorite: true,

      sort: ['post_at', 'desc'],

      isLoading: false

    }

  }, mounted() {
    // 這裡寫jQuery
    $(document).ready(function () {
      // 按鈕文字顏色
      $('.pagination').attr('class', 'pagination mb-0');
      $('.page-link').css('border', 'transparent');
      $('.page-link').css('color', 'black');
      $('li.page-item').css('color', 'black');
      // $('.page-item.active .page-link').css('border', 'transparent')

      // 按下一頁後的樣式
      $('.page-item').css('background-color', 'transparent');

    });

  }, methods: {
    sortByOption(event) {
      this.sort = event.target.value.split('-');
      this.page = 1;
      this.clickCallback(1);
    },

    // 頁面按鈕：更換頁面重新取得下一頁資料&返回最上頁
    clickCallback(pageNum) {
      this.isLoading = true;
      const route = useRoute();

      let url = `${this.basePath}/api/favorite`;
      if(this.sort.length > 0) {
        url += `?sort_field=${this.sort[0]}&sort_type=${this.sort[1]}`;
      }

      let token = sessionStorage.getItem('token');
      let access_token = sessionStorage.getItem('access_token');
      let id_token = sessionStorage.getItem('id_token');

      url += `?access_token=${access_token}&id_token=${id_token}`;

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          token: token
        },
        method: "POST",
      }).then(res => {
        this.isLoading = false;
        return res.json();
      }).then(formData => {
        this.Packages = formData.data;
      })
    },


    // 收藏按鈕功能
    changeFavorite(id) {
      let type = 'estate_century';

      let token = sessionStorage.getItem('token');
      let url = `${this.basePath}/api/favorite/toggle`;

      let access_token = sessionStorage.getItem('access_token');
      let id_token = sessionStorage.getItem('id_token');

      url += `?access_token=${access_token}&id_token=${id_token}`;

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          token: token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'post',

        body: JSON.stringify(
          {
            id: id,
            type: type
          }
        )
      }).then(res => {
        return res.json();
      }).then(res => {
        let favorite = res.data.is_favorite;
        let favoriteID = document.getElementById('favorite' + id);

        if (favorite == 0) {
          this.Packages.items = this.Packages.items.filter(item => item.id != id);
          alert('您已移除收藏此物件！')
        }

      })
    },

    objectLink(url) {
      window.open(url, '_black');
    },

    goObject(item) {
      const params = {
        name: item.name ?? '',
        type: item.type ?? '',
        structure_bath: item.structure_bath ?? 0,
        structure_hall: item.structure_hall ?? 0,
        structure_room: item.structure_room ?? 0,
        floor_space: item.total_floor_space ?? 0,
        price: item.price ?? 0,
        hUrl: item.hUrl ?? '',
        hSellerName: item.hSellerName ?? '',
        hSeller: item.hSeller ?? '',
        image: item.image ?? ''
      }
      this.$router.push({path: '/member/estate-century/object', query: params});
    },

    getFloor(floor, total_floor) {
      return (floor == 0 && total_floor == 0) || !floor || !total_floor ? "未提供資訊" : 
      floor == 0 ? "整棟" : floor == -1 ? "地下室" : `${floor}/${total_floor}樓`;
    },

    dateFormat(date) {
      return date.replaceAll("-", "/");
    },

  }, 
  watch: {
    // 更換頁碼返回最上頁
    page: (NewVal, OldVal) => {
      window.scrollTo(0, 0);
    }

  },
  created() {
    // 搜尋欄的值
    const route = useRoute();

    // 獲取資料
    let url = `${this.basePath}/api/favorite`;

    this.isLoading = true;

    let token = sessionStorage.getItem('token');
    let access_token = sessionStorage.getItem('access_token');
    let id_token = sessionStorage.getItem('id_token');

    url += `?access_token=${access_token}&id_token=${id_token}`;

    if(this.sort.length > 0) {
      url += `?sort_field=${this.sort[0]}&sort_type=${this.sort[1]}`;
    }

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        token: token
      },
      method: "POST",
    }).then(res => {
      return res.json();
    }).then(formData => {
      this.Packages = formData.data;
      this.isLoading = false;

      // 頁碼
      if ((Packages.pagination.total_pages) <= 0) {
        Packages.pagination.total_pages = 1
      } else {
        Packages.pagination.total_pages = Packages.pagination.total_pages;
      }
    })
  },
}

</script>

<style lang="css">
.page-item {
  background-color: transparent;
  color: black;
}

.page-item.active .page-link {
  background-color: #C0AC79;
  color: black;
  border: 1px solid #C0AC79;
}

.page-item.active {
  background-color: #C0AC79;
  color: black;
}

.page-link {
  background-color: transparent;
  color: black;
  border: transparent;
}

.page-link:hover {
  background-color: transparent;
  color: black;
  border: transparent;
}

.favorite-button {
  height: 20px;
}
</style>

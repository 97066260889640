<template>
  <div class="header sticky-top">

    <nav class="navbar">

      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a id="back_href" class="nav-link text-white" @click="$router.push({ path: '/edm-generator/filter' })" v-if="type == 'generator'">
            <i data-eva="arrow-ios-back"></i>
            <span id="back_text">返回</span>
          </a>
          <a id="back_href" class="nav-link text-white" @click="$router.go(-1)" v-else>
            <i data-eva="arrow-ios-back"></i>
            <span id="back_text">返回</span>
          </a>
        </li>

      </ul>

      <span class="navbar-brand mx-auto">
        <img alt="Logo" v-bind:src="LogoImage">
      </span>

      <span class="navbar-brand ml-auto home-icon" @click="goMenu">
        <img alt="Logo" v-bind:src="homeIcon">
      </span>

      <!-- <div class="ml-auto d-flex" style="width: 92px;">
        <button class="navbar-toggler" type="button">
          <i data-eva="search"></i>
        </button>

        <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbar"
          aria-expanded="false">
          <i data-eva="menu"></i>
          <i data-eva="close"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbar">
        <ul class="navbar-nav ml-auto">


          <li class="nav-item">
            <router-link class="nav-link" to="/">
              登出
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" to="/">
              登入
            </router-link>
          </li>


        </ul>
      </div> -->

    </nav>


  </div>

  <div>
    <div class="image">
      <img class="w-100" v-bind:src="Bgimage" alt="Backgorund">
    </div>
  </div>
</template>

<script>

export default {
  name: 'NavBar2',
  data() {
    return {
      LogoImage: '/imgs/logo.png',
      Bgimage: '/imgs/longCover.png',
      homeIcon: '/imgs/round_home_white_24dp.png',
    }
  },
  props: {
    type: String
  },
  methods: {
    goMenu() {
      location.href = process.env.VUE_APP_C21;
    },
  }
};
</script>


<style>
#back_href:hover {
  cursor: pointer;
}
.home-icon img {
  width: 30px;
}
</style>

<template>
  <div class="edm-2-8 edm1240 mx-auto">
    <!-- EDM預覽 -->
    <div class="page-wrap layout-count-8">

        <Edm2Header :user="user" />
        
        <section class="page-body">
            <div
              v-for="(items, index) in Packages.items"
              class="card-wrap"
              @click="goObject(items.hUrl, items.d_type)"
            >
                <div class="card-title">
                    <p>{{ items.name }}</p>
                </div>
                <div class="card-info">
                    <div class="card-img">
                        <img :src="`${items.image}`" alt="物件照片">
                    </div>
                    <div class="card-info-inner">
                        <div class="card-detail">
                            <ul class="detail-list">
                                <li class="list-item">
                                    <span class="item-title">地址</span>
                                    <span class="item-content" style="height: 1.75em; line-height: 1em;">
                                      {{ items.county }}{{ items.district }}{{ items.address }}
                                    </span>
                                </li>
                                <li class="list-item">
                                    <span class="item-title">總坪數</span>
                                    <span class="item-content">
                                      <span class="text-danger">{{ Number(items.total_floor_space).toFixed(2) }}</span> 坪
                                    </span>
                                </li>
                                <li class="list-item">
                                    <span class="item-title">格局</span>
                                    <span class="item-content">
                                      {{
                                        setStructure(
                                          Number(items.structure_room),
                                          Number(items.structure_hall),
                                          Number(items.structure_bath)
                                        )
                                      }}
                                    </span>
                                </li>
                                <li class="list-item">
                                    <span class="item-title">種類</span>
                                    <span class="item-content">{{ items.type.replace(/,/g, '．') }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="card-price">
                            <span>{{ items.price.toLocaleString() }}萬</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <Edm2Footer :user="user" />

    </div>

  </div>
</template>

<script>
import Edm2Footer from "@/components/Edm2Footer.vue";
import Edm2Header from "@/components/Edm2Header.vue";
import { useRoute } from "vue-router";

export default {
  components: {
    Edm2Header,
    Edm2Footer,
  },
  data() {
    return {
      Packages: [],
      basePath: process.env.VUE_APP_BASEPATH,
      user: {},
    };
  },
  created() {
    $("head").append(
      '<meta name="viewport" content="width=device-width,initial-scale=0.3,user-scalable=yes">'
    );

    // // 取得物件數量判定是符合此模板
    // let itemsArr = items.split(',')

    // 取得物件與列印
    const route = useRoute();
    let { id, c } = route.query;
    let url = `${this.basePath}/api/edm?id=${id}&c=${c}`;

    fetch(url, {})
      .then((res) => {
        return res.json();
      })
      .then((formData) => {
        let edmLength = formData.data.edm.items.length;
        // 若物件不符合模板轉跳回首頁
        if (edmLength != 8) {
          alert("物件數量不符合此風格！即將轉跳回首頁");
          window.location.href = "/";
        } else {
          this.Packages = formData.data.edm;
          this.user = this.Packages.user.user_info;
        }
      });
  },
  methods: {
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : "";
    },
    getURL(url, type) {
      let urlArray = url.split("/");
      let id = urlArray[urlArray.length - 1];
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(this.user.employee_id)}/${
        type == "R" ? "rent" : "buy"
      }/${id}`;
    },
    goObject(url, type) {
      window.open(this.getURL(url, type));
    },
    setStructure(room, hall, bath) {
      const rooms = [];
      if (room > 0) {
        rooms.push(room + " 房");
      }
      if (hall > 0) {
        rooms.push(hall + " 廳");
      }
      if (bath > 0) {
        rooms.push(bath + " 衛");
      }
      return rooms.length > 0 ? rooms.join(" | ") : "-";
    },
  },
};
</script>


<template>
  <div class="nb3-bg-color">
    <div class="  header sticky-top">

      <nav class="navbar">

        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a id="back_href" class="nav-link text-white" @click="$router.go(-1)">
              <i data-eva="arrow-ios-back"></i>
              <span id="back_text">返回</span>
            </a>
          </li>

        </ul>

        <router-link class="navbar-brand mx-auto" to="/">
          <img alt="Logo" v-bind:src="LogoImage">
        </router-link>

        <div class="ml-auto d-flex">
          <button class="navbar-toggler" type="button">
            <i data-eva="search"></i>
          </button>

          <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbar"
            aria-expanded="false">
            <i data-eva="menu"></i>
            <i data-eva="close"></i>
          </button>
        </div>

        <div class="collapse navbar-collapse" id="navbar">
          <ul class="navbar-nav ml-auto">


            <li class="nav-item">
              <router-link class="nav-link" to="/">
                登出
              </router-link>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/">
                登入
              </router-link>
            </li>


          </ul>
        </div>

      </nav>


    </div>
  </div>

</template>

<script>

export default {
  name: 'NavBar2',
  data() {
    return {
      LogoImage: '/imgs/logo.png',
      Bgimage: '/imgs/cover.png',
    }
  }
};
</script>


<style>
#back_href:hover {
  cursor: pointer;
}

.nb3-bg-color {
  background-color: #383635;
}
</style>

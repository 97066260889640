<template>
  <NavBar2/>

  <div>
    <!-- {{ Packages.items[0].image }}
    {{ Packages.items[0].id }}
    {{ Packages.items[0].name }} -->

    <!-- {{ Packages.items[12] }} -->

    <!-- <div v-for="items in Packages.items" > {{items.id}}</div> -->
  </div>

  <section class="section">
    <div class="container">
      <!-- <a href="{{ route('front.estate-century.filter') }}" class="btn btn-primary">重新篩選</a> -->
      <!-- <h5>21物件</h5> -->
      <form id="Form" action="">
        <!--要帶入的條件參數-->
        <input type="hidden" name="" value="" />

        <div class="form-row mb-4">
          <div class="col-5">
            <!-- <label>排序方式</label> -->
            <select class="custom-select" @change="sortByOption($event)" name="sort" id="sort">
              <option value="">不限</option>
              <option value="price-desc">總價高到低排序</option>
              <option value="price-asc">總價低到高排序</option>
              <option value="total_floor_space-desc">坪數大到小排序</option>
              <option value="total_floor_space-asc">坪數小到大排序</option>
              <option value="age-desc">屋齡高到低排序</option>
              <option value="age-asc">屋齡低到高排序</option>
              <option value="post_at-desc" selected="selected">上架日期新到舊</option>
              <option value="post_at-asc">上架日期舊到新</option>
            </select>
          </div>
          <div class="col-6 d-flex">
            <small class="text-muted my-auto"
              >共<span class="text-danger">{{ Packages?.pagination?.total }} </span>筆資料</small
            >
          </div>
        </div>
      </form>

      <!-- loding..... -->
      <!-- <loading :active.sync="LoaderVisiable" :can-cancel="true"></loading> -->
      <div class="loding d-flex justify-content-center">
        <loading v-model:active="isLoading" :loader="'dots'" :can-cancel="true" />
      </div>

      <div class="empty-list" v-if="Packages?.items?.length == 0 && isLoading == false">
        <span>目前沒有物件符合您的需求，請重新搜尋</span>
      </div>

      <ul class="estate-list">
        <li v-for="(items, index) in Packages.items" class="estate-list-item">
          <div class="form-row">
            <div class="col-8">
              <div class="form-row top-row">
                <div class="col-auto pr-0 d-block">
                  <input
                    class="checkbox"
                    type="checkbox"
                    v-model="selectItems"
                    :id="`${items.id}`"
                    :value="`${items.id}`"
                  />
                </div>

                <div class="col">
                  <label :for="`${items.id}`" class="name font-weight-bold mb-0 mt-0">
                    <!-- (物件ID = {{ items.id }}) -->
                    {{ items.name }}
                  </label>
                </div>
              </div>

              <div class="estate-item">
                {{ items.county }}{{ items.district }}{{ items.address }} <span class="name"></span
                ><br />
                <small>坪數{{ Number(items.total_floor_space).toFixed(2) }}｜{{ items.structure ? items.structure.replaceAll('.0', '') : '-' }}｜{{ Number(items.age) > 0 ? items.age : '-' }}年
                  {{ '｜' + items.floorDescription }}{{ items.type ? '｜' + items.type : '' }}</small>
              </div>

              <!-- 案件來源、上架時間 -->
              <div class="ObjectInfo d-flex">
                <div class="store">
                  <small class="date">{{ items.store_name }}</small>
                </div>
                <div class="createTime ml-2">
                  <small class="date">更新日期：{{ dateFormat(items.hModifyDate) }}</small>
                </div>
              </div>

              <button class="btn btn-primary btn-sm" type="button" @click="goObject(items)">
                分享案件
              </button>
            </div>

            <div class="col-4">
              <div class="image">
                <img :src="`${items.image}`" class="estate-item" data-id="" />
              </div>
              <div class="text-right mt-1">
                <span class="price">{{ items.price.toLocaleString() }}</span
                ><small>萬</small>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>

  <!-- 物件列表(已選擇的物件數量) -->
  <div class="fixed-bar">
    <div class="row">
      <div class="col-7">
        <div class="px-3">
          已選擇物件：<span class="count">{{ selectItems.length }}</span>
        </div>
        <!-- <div class="display">物件為：
          {{ selectItems }}
        </div> -->
      </div>
      <div class="col-5">
        <a href="/edm-generator/uiSelect" v-on:click="SaveSelected" class="btn btn-primary"
          >製作EDM</a
        >
      </div>
    </div>
  </div>

  <!-- 頁數選單 -->
  <div class="d-flex justify-content-center mb-5" v-if="Packages?.items?.length > 0">
    <paginate
      :page-count="`${Packages.pagination.total_pages}`"
      :page-range="3"
      :margin-pages="2"
      :click-handler="clickCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'page-item'"
      v-model="page"
    >
    </paginate>
  </div>
</template>

<script>
import $ from "jquery";
import NavBar2 from "@/components/NavBar2.vue";
import { useRoute } from "vue-router";

// 頁面按鈕
import Paginate from "vuejs-paginate-next";

// 排序引用
import dictionary from "@/assets/data/dictionary.json";

import Loading from "vue-loading-overlay";


export default {
  components: {
    NavBar2,
    paginate: Paginate,
    Loading
  },
  data() {
    const route = useRoute();

    let {
      keyword,
      address,
      store_name,
      county,
      district,
      floor,
      age,
      min_floor_space,
      max_floor_space,
      min_total_floor_space,
      max_total_floor_space,
      min_price,
      max_price,
      date_range,
      type,
      status,
      d_type,
      min_structure_room,
      min_structure_hall,
      min_structure_bath,
      total_floor,
      hJointSale,
    } = route.query;

    return {
      basePath: process.env.VUE_APP_BASEPATH,

      // 搜尋欄的值
      keyword: keyword,
      address: address,
      store_name: store_name,
      county: county,
      district: district,
      floor: floor,
      total_floor: total_floor,
      age: age,
      min_floor_space: min_floor_space,
      max_floor_space: max_floor_space,
      min_total_floor_space: min_total_floor_space,
      max_total_floor_space: max_total_floor_space,
      min_price: min_price,
      max_price: max_price,
      date_range: date_range,
      type: type,
      status: status,
      d_type: d_type,
      min_structure_room: min_structure_room,
      min_structure_hall: min_structure_hall,
      min_structure_bath: min_structure_bath,
      hJointSale: hJointSale,
      page: "",

      // 聯賣加盟店
      hq_store_id: '',

      // 所有物件
      Packages: [],

      //勾選列表
      selectItems: [],

      sort: ['post_at', 'desc'],

      user: {},

      isLoading: false
    };
  },
  mounted() {
    // 這裡寫jQuery
    $(document).ready(function () {
      // 按鈕文字顏色
      $(".pagination").attr("class", "pagination mb-0");
      $(".page-link").css("border", "transparent");
      $(".page-link").css("color", "black");
      $("li.page-item").css("color", "black");
      // $('.page-item.active .page-link').css('border', 'transparent')

      // 按下一頁後的樣式
      $(".page-item").css("background-color", "transparent");
    });
  },
  methods: {
    filterType(type) {
      let typeArray = type.split(",");
      return [...new Set(typeArray)].join(", ");
    },
    // 製作EDM, 先將值存到session中
    SaveSelected() {
      let itemsLength = this.selectItems.length;
      let items = this.selectItems;

      if (
        itemsLength == 1 ||
        itemsLength == 2 ||
        itemsLength == 4 ||
        itemsLength == 6 ||
        itemsLength == 8
      ) {
        sessionStorage.setItem("items", items);
      } else {
        alert("請選擇1、2、4、6、8組物件");
        event.preventDefault();
      }
    },

    sortByOption(event) {
      this.sort = event.target.value.split("-");
      this.page = 1;
      this.clickCallback(1);
    },

    // 頁面按鈕：更換頁面重新取得下一頁資料&返回最上頁
    clickCallback(pageNum) {
      this.isLoading = true;

      let url = `${this.basePath}/api/estate-century/21century?page=${pageNum}&keyword=${this.keyword}&address=${this.address}&store_name=${this.store_name}&HQStoreID=${this.hq_store_id}&county=${this.county}&district=${this.district}&total_floor=${this.total_floor}&min_floor=${this.floor}&max_floor=${this.floor}&ages=${this.age}&min_floor_space=${this.min_floor_space}&max_floor_space=${this.max_floor_space}&min_total_floor_space=${this.min_total_floor_space}&max_total_floor_space=${this.max_total_floor_space}&min_price=${this.min_price}&max_price=${this.max_price}&date_range=${this.date_range}&type=${this.type}&status=${this.status}&d_type=${this.d_type}&min_structure_room=${this.min_structure_room}&max_structure_room=${this.min_structure_room}&min_structure_hall=${this.min_structure_hall}&max_structure_hall=${this.min_structure_hall}&min_structure_bath=${this.min_structure_bath}&max_structure_bath=${this.min_structure_bath}&hJointSale=${this.hJointSale}`;
      if (this.sort.length > 0) {
        url += `&sort_field=${this.sort[0]}&sort_type=${this.sort[1]}`;
      }

      let token = sessionStorage.getItem("token");
      let access_token = sessionStorage.getItem("access_token");
      let id_token = sessionStorage.getItem("id_token");

      url += `&access_token=${access_token}&id_token=${id_token}`;

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,

          token: token,
        },
        method: "POST",
      })
        .then((res) => {
          this.isLoading = false;
          return res.json();
        })
        .then((formData) => {
          this.Packages = formData.data;
        });
    },

    dateFormat(date) {
      return date.replaceAll("-", "/");
    },

    goObject(item) {
      let params;
      if (this.selectItems.length > 0) {
        let selectArray = this.Packages.items.filter((item) =>
          Object.values(this.selectItems).includes(item.id.toString())
        );
        params = {
          multiple: JSON.stringify(selectArray),
        };
      } else {
        params = {
          name: item.name ?? "",
          d_type: item.d_type ?? "",
          type: item.type ?? "",
          structure_bath: item.structure_bath ?? 0,
          structure_hall: item.structure_hall ?? 0,
          structure_room: item.structure_room ?? 0,
          floor_space: item.total_floor_space ?? 0,
          price: item.price ?? 0,
          hUrl: item.hUrl ?? "",
          hSellerName: item.hSellerName ?? "",
          hSeller: item.hSeller ?? "",
        };
      }

      this.$router.push({ path: "/edm-generator/object", query: params });
    },

    saveFilter() {
      let body = JSON.stringify({
        keyword: this.keyword,
        address: this.address,
        store_name: this.store_name,
        county: this.county,
        district: this.district,
        floor: this.floor,
        age: this.age,
        min_floor_space: this.min_floor_space,
        max_floor_space: this.max_floor_space,
        min_total_floor_space: this.min_total_floor_space,
        max_total_floor_space: this.max_total_floor_space,
        min_price: this.min_price,
        max_price: this.max_price,
        date_range: this.date_range,
        type: this.type,
        status: this.status,
        d_type: this.d_type,
        min_structure_room: this.min_structure_room,
        min_structure_hall: this.min_structure_hall,
        min_structure_bath: this.min_structure_bath,
        total_floor: this.total_floor,
      });
      localStorage.setItem("edm_generator_store_filter", body);
    },

    getDate(id) {
      let date;
      switch (id) {
        case "three-days":
          date = "三日內";
          break;
        case "one-week":
          date = "一週內";
          break;
        case "one-month":
          date = "本月";
          break;
        default:
          break;
      }
      return date;
    },

    getFloor(floor, total_floor) {
      return (floor == 0 && total_floor == 0) || !floor || !total_floor ? "未提供資訊" : 
      floor == 0 ? "整棟" : floor == -1 ? "地下室" : `${floor}/${total_floor}樓`;
    }
  },
  watch: {
    // 更換頁碼返回最上頁
    page: (OldVal, NewVal) => {
      window.scrollTo(0, 0);
    },
  },
  created() {
    const access_token = sessionStorage.getItem("access_token");
    const id_token = sessionStorage.getItem("id_token");
    const userURL = `${this.basePath}/api/user/info?access_token=${access_token}&id_token=${id_token}`;
    // 搜尋欄的值
    const route = useRoute();

    let {
      keyword,
      address,
      store_name,
      county,
      district,
      floor,
      age,
      min_floor_space,
      max_floor_space,
      min_total_floor_space,
      max_total_floor_space,
      min_price,
      max_price,
      date_range,
      type,
      status,
      d_type,
      min_structure_room,
      min_structure_hall,
      min_structure_bath,
      total_floor,
      hJointSale,
    } = route.query;

    this.isLoading = true;

    fetch(userURL, {
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.hq_store_id = data.data.user_info.hq_store_id;
        this.saveFilter();

        // 獲取資料
        let url = `${this.basePath}/api/estate-century/21century?page=1&keyword=${keyword}&address=${address}&store_name=${store_name}&HQStoreID=${this.hq_store_id}&county=${county}&district=${district}&total_floor=${total_floor}&min_floor=${floor}&max_floor=${floor}&ages=${age}&min_floor_space=${min_floor_space}&max_floor_space=${max_floor_space}&min_total_floor_space=${min_total_floor_space}&max_total_floor_space=${max_total_floor_space}&min_price=${min_price}&max_price=${max_price}&date_range=${date_range}&type=${type}&status=${status}&d_type=${d_type}&min_structure_room=${min_structure_room}&max_structure_room=${min_structure_room}&min_structure_hall=${min_structure_hall}&max_structure_hall=${min_structure_hall}&min_structure_bath=${min_structure_bath}&max_structure_bath=${min_structure_bath}`;

        if (this.sort.length > 0) {
          url += `&sort_field=${this.sort[0]}&sort_type=${this.sort[1]}`;
        }

        let token = sessionStorage.getItem("token");
        let access_token = sessionStorage.getItem("access_token");
        let id_token = sessionStorage.getItem("id_token");

        url += `&access_token=${access_token}&id_token=${id_token}`;

        fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            token: token,
          },
          method: "POST",
        })
          .then((res) => {
            this.isLoading = false;
            return res.json();
          })
          .then((formData) => {
            this.Packages = formData.data;
            
            if (Packages.pagination.total_pages <= 0) {
              Packages.pagination.total_pages = 1;
            } else {
              Packages.pagination.total_pages = Packages.pagination.total_pages;
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="css">
/* Adopt bootstrap pagination stylesheet. */
/* @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"; */

/* Write your own CSS for pagination */
.page-item {
  background-color: transparent;
  color: black;
}

.page-item.active .page-link {
  background-color: #c0ac79;
  color: black;
  border: 1px solid #c0ac79;
}

.page-item.active {
  background-color: #c0ac79;
  color: black;
}

.page-link {
  background-color: transparent;
  color: black;
  border: transparent;
}

.page-link:hover {
  background-color: transparent;
  color: black;
  border: transparent;
}

.checkbox {
  margin-top: 5px;
}
</style>

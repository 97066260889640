<template>
  <section class="section section-cover pt-0 pb-0 mb-4">
    <img src="~@/assets/imgs/cover.png" class="img-fluid">
  </section>
</template>

<script>
export default {
  name:'Banner',
}
</script>

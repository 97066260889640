<template>
  <NavBar2 type="object"/>

  <section class="section object">
    <div class="container">
      <form id="Form" action="">
        <div class="form-row content mb-3">
          <small class="mb-2">內文：</small>
          <textarea
            class="form-control"
            v-model="content"
            name="content"
            rows="10"
            required
          ></textarea>
        </div>
        <div class="form-row mb-3">
          <small>業務人員：{{ user.name }}</small>
        </div>
        <div class="form-row mb-3">
          <small>業務人員聯絡方式：{{ user.phone }}</small>
        </div>
        <div class="form-row">
          <button class="btn btn-primary btn-block mt-4" type="button" @click="shareLine()">分享內文</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { useRoute } from "vue-router";
import NavBar2 from '@/components/NavBar2.vue';

export default {
  name: "competitor-object",
  components: {
    NavBar2
  },
  data() {
    const route = useRoute();

    let { name, type, structure_bath, image,
      structure_hall, structure_room, floor_space, price, hUrl, hSellerName, hSeller} = route.query;

    return {
      basePath: process.env.VUE_APP_BASEPATH,
      
      content: "",
      name: name,
      type: type,
      structure_bath: structure_bath,
      structure_hall: structure_hall,
      structure_room: structure_room,
      floor_space: floor_space,
      price: price,
      hUrl: hUrl,
      hSellerName: hSellerName,
      hSeller: hSeller,
      image: image,
      user: {},
      lineUrl: ""
    };
  },
  mounted() {
    const mobileDevice = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone']
    let isMobileDevice = mobileDevice.some(e => navigator.userAgent.match(e));
    this.lineUrl = isMobileDevice ? 'https://line.me/R/msg/text/?' : 'https://social-plugins.line.me/lineit/share?url=&text=';
  },
  methods: {
    shareLine() {
      //LINE
      let h1 = this.name; //物件名稱
      let quote = encodeURIComponent(this.content); //顯示的文字內容,修改這裡

      if (h1 != undefined && h1 != "") {
        h1 = "【" + h1 + "】";
      }
      if (quote != "") {
        quote = "%0D%0A" + quote; //LINE 換行需要使用 '%0D%0A'
      }
      window.open(
        `${this.lineUrl}` +
          `${encodeURIComponent("21世紀不動產" + h1) +
          "%0D%0A" +
          quote}`,
        "sharer",
        "toolbar=100,status=100,width=800,height=800"
      );
    },
    setContent() {
      this.content = 
      `案名: ${this.name}
總價: ${Number(this.price).toLocaleString()} 萬
坪數: ${Number(this.floor_space).toFixed(2)} 坪
連結: ${this.hUrl}
你的專屬經紀人: ${this.user.name}
電話:  ${this.user.phone.slice(0, 4) + "-" + this.user.phone.slice(4, 7) + "-" + this.user.phone.slice(7)}
      `
    },
    getUserInfo() {
      const access_token = sessionStorage.getItem("access_token");
      const id_token = sessionStorage.getItem("id_token");
      const url = `${this.basePath}/api/user/info?access_token=${access_token}&id_token=${id_token}`;
      fetch(url, {
        method: "POST"
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.user = data.data.user_info;
        this.setContent();
      })
      .catch(error => {
        console.log(error);
      })
    },
  },
  computed: {},
  created() {
    this.getUserInfo();
  }
};
</script>

<style lang="css">
.object {
  padding: 30px;
}
.content {
  flex-direction: column;
}
</style>

<template>
  <div class="headSection w-500 d-flex ">
    <div class="left-box col-4 p-0">
      <!-- logo -->
      <div class="logoImage">
        <img src="@/assets/imgs/edm/logo.png" alt="logo">
      </div>
      <!-- store name -->
      <div class="storeName d-flex justify-content-end">
        <div class="box-content d-flex justify-content-center">
          <span>21世紀不動產</span>
          <span>{{ user.store_name }}</span>
        </div>
      </div>
    </div>
    <div class="right-box px-0">
      <!-- seller info -->
      <div class="sellerInfo d-flex">
        <div class="leftSection d-flex align-items-center">
          <div class="sellerImage">
            <img class="w-100" :src="user.picture_base64 ? user.picture_base64 : userImage" alt="業務員照片">
          </div>
        </div>
        <div class="middleSection d-flex align-items-start justify-content-center flex-column">
          <div class="sellerTitle">
            <span>專業經理人</span>
          </div>
          <div class="name">
            <span>{{ user.name }}</span>
          </div>
          <div class="phone">
            <span>
              {{ user.phone }}
            </span>
          </div>
        </div>
        <qrcode-vue id="QRcode" :value="getQRcode(user.employee_id)"></qrcode-vue>
        <div class="rightSection d-flex flex-column justify-content-center">
          <div class="componyName">
            <p>經紀業名稱</p>
            <p>{{ user.company_name }}</p>
          </div>
          <div class="componyAddress">
            <p>地址</p>
            <p>{{ user.store_address }}</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import QrcodeVue from 'qrcode.vue';
import imageToBase64 from "image-to-base64/browser";

export default {
  components: {
    QrcodeVue
  },
  props: ['user'],
  data() {
    return {
      basePath: process.env.VUE_APP_BASEPATH,
      Packages: [],
    }
  },
  async mounted() {
    this.userImage = await imageToBase64("https://www.century21.com.tw/store/images/jobnewsman.png");
  },
  methods: {
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : '';
    },
    getQRcode(employee_id) {
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(employee_id)}`;
    },
  }
}
</script>
<style>
.w-1240 .right-box .sellerInfo canvas {
  width: 80px !important;
  height: 80px !important;
  margin-top: 30px;
  margin-left: 20px;
}
</style>

<template>
  <NavBar />

  <section class="section px-3">
    <div class="container">
      <h5>全市場物件/屋主自售</h5>
      <form method="GET" id="Form" action="/estate-fsbo/index">
        <div class="form-group">
          <label>關鍵字 </label>
          <input
            type="text"
            class="form-control input-field"
            name="keyword"
            id="keyword"
            placeholder="輸入案名"
          />
        </div>

        <!-- <div class="form-group ">
      <label>地址 </label>
      <input type="text" class="form-control input-field"
      name="address" placeholder="輸入地址">
    </div> -->

        <div class="form-row city-selector">
          <div class="col-6">
            <fieldset class="form-group">
              <label for="county">縣市</label>
              <select
                v-model="county"
                class="county custom-select select-field"
                data-name="county"
                id="county"
                data-style="custom-select"
                :data-value="county"
              ></select>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset class="form-group">
              <label for="district">行政區</label>
              <select
                class="district custom-select select-field"
                data-name="district"
                id="district"
                data-style="custom-select"
                multiple="multiple"
              ></select>
            </fieldset>
          </div>
        </div>

        <div class="form-row">
          <div class="col-6">
            <fieldset class="form-group">
              <label>時間</label>
              <select class="custom-select select-field" name="date_range" id="date_range">
                <option value="">不限</option>
                <option value="three-days">三日內</option>
                <option value="one-week">一週內</option>
                <option value="one-month">本月</option>
              </select>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset class="form-group">
              <label>類型</label>
              <select class="custom-select select-field" name="type" id="type" multiple="multiple">
                <!-- <option value="">不限</option> -->
                <option v-for="(type, index) in types" :value="index">
                  {{ type }}
                </option>
              </select>
            </fieldset>
          </div>
        </div>

        <div class="form-row">
          <div class="col-6">
            <fieldset class="form-group">
              <label>狀態</label>
              <select class="custom-select select-field" name="status" id="status">
                <option value="Y">上架</option>
                <option value="">不限</option>
                <!-- <option v-for="(status, index) in statuses" :value="index">
                  {{ status }}
                </option> -->
              </select>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset class="form-group">
              <label>品牌</label>
              <select class="custom-select select-field" name="brand" id="brand">
                <option value="20" selected>591自售</option>
                <!-- <option value="">不限</option> -->
                <!-- <option v-for="(brand, index) in brands" :value="index">
                  {{ brand }}
                </option> -->
              </select>
            </fieldset>
          </div>
        </div>
        <div
          v-if="isActive == true"
          v-on:click="changeNoActive"
          v-bind:class="{ NoActive: isActive }"
        >
          顯示更多
        </div>

        <div v-bind:class="{ showMore: isActive }">
          <div class="form-group">
            <label>所在樓層 (請於下方欄位輸入數字)</label>
            <input
              type="text"
              class="form-control input-field"
              name="floor_start"
              id="floor_start"
              placeholder="請輸入總樓層"
            />
          </div>

          <div class="form-group">
            <label>格局 </label>
            <div class="structure-block">
              <input
                type="text"
                class="form-control input-field"
                name="structure_room_start"
                placeholder="輸入房"
                id="structure_room_start"
              />
              <label>房 </label>
              <input
                type="text"
                class="form-control input-field"
                name="structure_hall_start"
                placeholder="輸入廳"
                id="structure_hall_start"
              />
              <label>廳 </label>
              <input
                type="text"
                class="form-control input-field"
                name="structure_bath_start"
                placeholder="輸入衛"
                id="structure_bath_start"
              />
              <label>衛 </label>
            </div>
          </div>

          <div class="form-group">
            <label>屋齡 (請於下方欄位輸入數字) </label>
            <div class="radio-container">
              <div class="radio-block">
                <input type="radio" class="form-control" value="unlimited" v-model="ageRadio" />
                <label for="age">不限</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="0-5" v-model="ageRadio" />
                <label for="age">0-5年</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="6-10" v-model="ageRadio" />
                <label for="age">6-10年</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="11-20" v-model="ageRadio" />
                <label for="age">11-20年</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="21-30" v-model="ageRadio" />
                <label for="age">21-30年</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="31-40" v-model="ageRadio" />
                <label for="age">31-40年</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="41" v-model="ageRadio" />
                <label for="age">41年以上</label>
              </div>
            </div>

            <div class="form-row form-group mt-3">
              <div class="col d-flex">
                <small class="age-text">最低</small>
                <input type="number" class="form-control input-field" v-model="min_age" placeholder="輸入數字"/>
                <small class="age-text">年</small>
              </div>
              <div class="col-auto d-flex px-0">
                <div class="my-auto text-muted">～</div>
              </div>
              <div class="col d-flex">
                <small class="age-text">最高</small>
                <input type="number" class="form-control input-field" v-model="max_age" placeholder="輸入數字"/>
                <small class="age-text">年</small>
              </div>
            </div>
            <input
              type="string"
              class="form-control input-field"
              name="age"
              id="age"
              v-show="false"
            />
          </div>

          <label>總坪數</label><br />
          <div class="form-row form-group">
            <div class="col d-flex">
              <input
                type="number"
                class="form-control input-field"
                name="min_total_floor_space"
                id="min_total_floor_space"
                placeholder="輸入總坪數最低坪數"
              />
              <small class="mt-auto ml-1 text-muted">坪</small>
            </div>
            <div class="col-auto d-flex px-0">
              <div class="my-auto text-muted">～</div>
            </div>
            <div class="col d-flex">
              <input
                type="number"
                class="form-control input-field"
                name="max_total_floor_space"
                id="max_total_floor_space"
                placeholder="輸入總坪數最高坪數"
              />
              <small class="mt-auto ml-1 text-muted">坪</small>
            </div>
          </div>

          <label>主建物坪數</label><br />
          <div class="form-row form-group">
            <div class="col d-flex">
              <input
                type="number"
                class="form-control input-field"
                name="min_floor_space"
                id="min_floor_space"
                placeholder="輸入主建物最低坪數"
              />
              <small class="mt-auto ml-1 text-muted">坪</small>
            </div>
            <div class="col-auto d-flex px-0">
              <div class="my-auto text-muted">～</div>
            </div>
            <div class="col d-flex">
              <input
                type="number"
                class="form-control input-field"
                name="max_floor_space"
                id="max_floor_space"
                placeholder="輸入主建物最高坪數"
              />
              <small class="mt-auto ml-1 text-muted">坪</small>
            </div>
          </div>

          <label>總價</label><br />
          <div class="form-row form-group">
            <div class="col d-flex">
              <input
                type="number"
                class="form-control input-field"
                name="min_price"
                placeholder="輸入最低總價"
                id="min_price"
              />
              <small class="mt-auto ml-1 text-muted text-nowrap">萬元</small>
            </div>
            <div class="col-auto d-flex px-0">
              <div class="my-auto text-muted">～</div>
            </div>
            <div class="col d-flex">
              <input
                type="number"
                class="form-control input-field"
                name="max_price"
                placeholder="輸入最高總價"
                id="max_price"
              />
              <small class="mt-auto ml-1 text-muted text-nowrap">萬元</small>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center mt-3 pt-3 flex-column">
          <button class="btn btn-primary btn-block" type="submit" :disabled="isDisabled">確認</button>
          <button class="btn btn-dark btn-block mt-3" id="clear" type="button" @click="clearForm">
            清除
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
// 引入 
import NavBar from "@/components/NavBar.vue";
import dictionary from "@/assets/data/dictionary.json";

// 縣市選單
import twCitySelector from "@/assets/js/tw-city-selector.js";
import multipleSelect from "@/assets/js/multiple-select.js";

export default {
  name: "century",
  components: {
    NavBar,
  },
  data() {
    return {
      // 改變
      isActive: true,

      // 下拉選單
      // 時間
      date_ranges: dictionary.folders.date_range[0],
      // 類型
      types: dictionary.folders.type[0],
      // 狀態
      statuses: dictionary.folders.status[0],
      // 品牌
      // brands: dictionary.folders.brand[0],
      // brands:'永慶房屋'

      //屋齡
      ageRadio: "unlimited",
      min_age: "",
      max_age: "",

      county: "",
      isDisabled: false
    };
  },
  watch: {
    min_age: function () {
      document.getElementById("age").value = `${this.min_age}-${this.max_age}`;
    },
    max_age: function () {
      document.getElementById("age").value = `${this.min_age}-${this.max_age}`;
    },
    ageRadio: function () {
      document.getElementById("age").value = this.ageRadio == "unlimited" ? "" : this.ageRadio;
    },
    // county: function (newVal) {
    //   if(newVal.trim() != "") {
    //     this.isDisabled = false;
    //   } else {
    //     this.isDisabled = true;
    //     alert("請選擇縣市");
    //   }
    // }
  },
  mounted() {
    const queryObject = JSON.parse(localStorage.getItem("estate_fsbo_filter"));
    // document.getElementById("county").value = queryObject?.county ?? "台北市";
    this.county = queryObject?.county ?? "台北市";
    if (queryObject) {
      document.getElementById("keyword").value = queryObject?.keyword ? queryObject?.keyword : "";
      // document.getElementById("district").value = queryObject?.district ?? "";
      document.getElementById("date_range").value = queryObject?.date_range ?? "";
      // document.getElementById("type").value = queryObject?.type ?? "";
      $('#type').val(queryObject?.type ?? "");
      document.getElementById("status").value = queryObject?.status ?? "";
      // document.getElementById("brand").value = queryObject?.brand ?? "";
      $('#brand').val(queryObject?.brand ?? "");
      document.getElementById("floor_start").value = queryObject?.floor_start ?? "";
      document.getElementById("structure_room_start").value =
        queryObject?.structure_room_start ?? "";
      document.getElementById("structure_hall_start").value =
        queryObject?.structure_hall_start ?? "";
      document.getElementById("structure_bath_start").value =
        queryObject?.structure_bath_start ?? "";
      document.getElementById("age").value = queryObject?.age ?? "";
      if (
        queryObject?.age == "0-5" ||
        queryObject?.age == "6-10" ||
        queryObject?.age == "11-20" ||
        queryObject?.age == "21-30" ||
        queryObject?.age == "31-40" ||
        queryObject?.age == "41"
      ) {
        this.ageRadio = queryObject?.age ?? "";
        this.min_age = "";
        this.max_age = "";
      } else {
        let ageArray = queryObject?.age.split("-");
        this.min_age = ageArray[0] ?? "";
        this.max_age = ageArray[1] ?? "";
        this.ageRadio = "";
      }
      document.getElementById("min_floor_space").value = queryObject?.min_floor_space ?? "";
      document.getElementById("max_floor_space").value = queryObject?.max_floor_space ?? "";
      document.getElementById("min_total_floor_space").value = queryObject?.min_total_floor_space ?? "";
      document.getElementById("max_total_floor_space").value = queryObject?.max_total_floor_space ?? "";
      document.getElementById("min_price").value = queryObject?.min_price ?? "";
      document.getElementById("max_price").value = queryObject?.max_price ?? "";
    }

    if(
      document.getElementById('floor_start').value != "" ||
      document.getElementById("structure_room_start").value != "" ||
      document.getElementById("structure_hall_start").value != "" ||
      document.getElementById("structure_bath_start").value != "" ||
      document.getElementById("age").value != "" ||
      document.getElementById("min_total_floor_space").value != "" ||
      document.getElementById("max_total_floor_space").value != "" ||
      document.getElementById("min_floor_space").value != "" ||
      document.getElementById("max_floor_space").value != "" ||
      document.getElementById("min_price").value != "" ||
      document.getElementById("max_price").value
      ) {
        this.isActive = false;
      }

    // 縣市選單
    new twCitySelector({
      el: ".city-selector",
      elCounty: ".county", // 在 el 裡查找 county
      elDistrict: ".district", // 在 el 裡查找 District
    });

    const vm = this;
    vm.initMultipleSelect();

    $('#county').change(function(event) {
      vm.initMultipleSelect();
    });
  },
  methods: {
    initMultipleSelect(){
      setTimeout(() => {
        $('#district option[value=""]').remove();
        $('#district').multipleSelect({
          width: '100%',
          placeholder: '選擇區域',
          ellipsis: true,
          minimumCountSelected: 8,
          selectAll: false,
        });

        $('#type').multipleSelect({
          width: '100%',
          placeholder: '不限',
          ellipsis: true,
          minimumCountSelected: 12,
          selectAll: false,
        });
      });
    },
    changeNoActive: function () {
      this.isActive = false;
    },
    changeActive: function () {
      this.isActive = true;
    },
    // alertText() {
    //   let county = document.getElementById("county").value;
    //   if (!county.trim()) {
    //     alert("請先選擇縣市");
    //   }
    // },
    clearForm() {
      document.getElementById("keyword").value = "";
      // document.getElementById("county").value = "";
      // document.getElementById("district").value = "";
      this.county = "台北市";
      new twCitySelector({
        el: ".city-selector",
        elCounty: ".county", // 在 el 裡查找 county
        elDistrict: ".district", // 在 el 裡查找 District
      });
      document.getElementById("date_range").value = "";
      // document.getElementById("type").value = "";
      $('#type').multipleSelect('uncheckAll');
      document.getElementById("status").value = "Y";
      document.getElementById("brand").value = "20";
      document.getElementById("floor_start").value = "";
      document.getElementById("structure_room_start").value = "";
      document.getElementById("structure_hall_start").value = "";
      document.getElementById("structure_bath_start").value = "";
      document.getElementById("age").value = "";
      document.getElementById("min_floor_space").value = "";
      document.getElementById("max_floor_space").value = "";
      document.getElementById("min_total_floor_space").value = "";
      document.getElementById("max_total_floor_space").value = "";
      document.getElementById("min_price").value = "";
      document.getElementById("max_price").value = "";
      this.ageRadio = "";
      this.min_age = "";
      this.max_age = "";
      localStorage.removeItem("estate_fsbo_filter");
    },
  },
};
</script>
<style>
@media screen and (min-width: 560px) {
  .radio-container {
    display: flex !important;
    justify-content: space-between;
  }
}
.radio-container {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 100px 100px 100px;
}
.age-text {
  white-space: nowrap;
  margin: auto 5px;
}
</style>

<template>
  <div class="edm-1-1 edm1240">
    <!-- EDM預覽 -->
    <section class="section py-0">
      <div class="container-fluid p-0">
        <!-- EDM風格一 -->
        <div
          v-for="items in Packages.edm.items"
          class="edm-style-1"
          @click="goObject(items.hUrl, items.d_type)"
        >
          <div class="image">
            <img class="cover" :src="`${items.image}`" />
            <div class="logo">
              <img class="img-fluid" src="@/assets/imgs/edm/logo.png" />
            </div>
          </div>
          <div class="header">
            <div class="slogan">我們為你 找到21世紀的家</div>
            <div class="title">{{ items.name }}</div>
          </div>
          <div class="body">
            <div class="left box">
              <div class="title">物件基本資料</div>
              <ul class="list">
                <li>
                  <div class="label">地址</div>
                  <div class="address-wrap" style="height: 1.5em;">{{ items.county }}{{ items.district }}{{ items.address }}</div>
                </li>
                <li>
                  <div class="label">總坪數</div>
                  <span class="text-danger">
                    {{ Number(items.total_floor_space).toFixed(2) }}
                  </span>
                  坪
                </li>
                <li>
                  <div class="label">格局</div>
                  <div class="text">
                    {{
                      setStructure(
                        Number(items.structure_room),
                        Number(items.structure_hall),
                        Number(items.structure_bath)
                      )
                    }}
                  </div>
                </li>
                <li>
                  <div class="label">樓層</div>
                  {{ items.floorDescription }}
                </li>
                <li>
                  <div class="label">屋齡</div>
                  {{ Number(items.age) > 0 ? items.age + "年" : "-" }}
                </li>
                <li>
                  <div class="label">種類</div>
                  <div class="text">{{ items.type }}</div>
                </li>
                <li>
                  <div class="label">車位</div>
                  {{ items.if_parking_space }}
                </li>
              </ul>
            </div>
            <div class="right">
              <div class="total-box box">
                <div class="total">
                  總價 $ <b class="text-danger">{{ items.price.toLocaleString() }}</b> 萬
                </div>
              </div>

              <div class="space"></div>
              <div class="sales-box box">
                <div>
                  <div class="avatar">
                    <img :src="user.picture_base64 ? user.picture_base64 : userImage" />
                  </div>
                </div>
                <div class="title">
                  <!-- 業務品牌 -->
                  <div class="job-title">專業經理人</div>
                  <!-- 業務名稱 -->
                  <div class="name">{{ user.name }}</div>
                  <div class="name">{{ user.phone }}</div>
                </div>
                <qrcode-vue id="QRcode" :value="getQRcode(items.d_type, items.source_number, user.employee_id)"></qrcode-vue>
              </div>
              <!-- 業務加盟店名 -->
              <div class="store-title">
                21世紀不動產
                {{ user.store_name }}
              </div>

              <div class="store-box box">
                <ul class="list">
                  <li>
                    <div class="label">經紀業名稱</div>
                    {{ user.company_name }}
                  </li>
                  <li>
                    <div class="label">地址</div>
                    {{ user.store_address }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <EdmFooter />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import EdmFooter from "@/components/EdmFooter.vue";
import QrcodeVue from "qrcode.vue";
import imageToBase64 from "image-to-base64/browser";

export default {
  components: {
    EdmFooter,
    QrcodeVue,
  },
  data() {
    return {
      Packages: [],
      user: {},
      basePath: process.env.VUE_APP_BASEPATH,
      userImage: "",
    };
  },
  async mounted() {
    this.userImage = await imageToBase64(
      "https://www.century21.com.tw/store/images/jobnewsman.png"
    );
  },
  methods: {
    setStructure(room, hall, bath) {
      const rooms = [];
      if (room > 0) {
        rooms.push(room + " 房");
      }
      if (hall > 0) {
        rooms.push(hall + " 廳");
      }
      if (bath > 0) {
        rooms.push(bath + " 衛");
      }
      return rooms.length > 0 ? rooms.join(" | ") : "-";
    },
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : "";
    },
    getQRcode(type, id, employee_id) {
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(employee_id)}/${
        type == "R" ? "rent" : "buy"
      }/${id}`;
    },
    getURL(url, type) {
      let urlArray = url.split("/");
      let id = urlArray[urlArray.length - 1];
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(this.user.employee_id)}/${
        type == "R" ? "rent" : "buy"
      }/${id}`;
    },
    goObject(url, type) {
      window.open(this.getURL(url, type));
    },
    getFloor(floor, max_floor, total_floor) {
      return (!total_floor && Number(total_floor) == 0) || (!floor && !max_floor)
        ? "未提供資訊"
        : (Number(floor) == 0 && Number(max_floor) == 0) ||
          (Number(floor) == 0 && !max_floor) ||
          (!floor && Number(max_floor) == 0)
        ? "整棟"
        : (Number(floor) == -1 && Number(max_floor) == -1) ||
          (Number(floor) == -1 && !max_floor) ||
          (!floor && Number(max_floor) == -1)
        ? "地下室"
        : (!floor || Number(floor) == 0) && Number(max_floor) != 0
        ? `${max_floor}樓/${total_floor}樓`
        : (!max_floor || Number(max_floor) == 0) && Number(floor) != 0
        ? `${floor}樓/${total_floor}樓`
        : floor == max_floor
        ? `${floor}樓/${total_floor}樓`
        : `${floor}-${max_floor}樓/${total_floor}樓`;
    },
  },
  created() {
    $("head").append(
      '<meta name="viewport" content="width=device-width,initial-scale=0.3,user-scalable=yes">'
    );

    // 取得物件與列印
    const route = useRoute();
    let { id, c } = route.query;

    let url = `${this.basePath}/api/edm?id=${id}&c=${c}`;

    fetch(url, {})
      .then((res) => {
        return res.json();
      })
      .then((formData) => {
        let edmLength = formData.data.edm.items.length;
        if (edmLength != 1) {
          alert("物件數量不符合此風格！即將轉跳回首頁");
          window.location.href = "/";
        } else {
          // 若物件不符合模板轉跳回首頁
          this.Packages = formData.data;
          this.user = this.Packages.edm.user.user_info;
        }
      });
  },
};
</script>

<style>
.edm1240 .header .title {
  font-size: 40px !important;
}

.edm1240 .slogan {
  font-size: 28px !important;
}

.edm1240 .body .left .list li {
  font-size: 27px !important;
  font-weight: 700;
}

.edm1240 .body .left .title {
  font-weight: 700;
  font-size: 30px !important;
  border-bottom: 6px solid #5a5a5a !important;
}

.edm1240 .body .left .list li .label {
  font-size: 20px !important;
}

.edm1240 .body .right .total-box .total {
  font-size: 27px !important;
}

.edm1240 .body .right .total-box .total .text-danger {
  font-size: 36px !important;
}

.edm1240 .body .right .sales-box .title .job-title {
  font-size: 20px !important;
}

.edm1240 .body .right .sales-box .title .name {
  font-size: 24px !important;
}

.edm1240 .body .right .store-title {
  font-size: 24px !important;
}

.edm1240 .body .right .store-box .list li {
  font-size: 18px !important;
  font-weight: 700;
}

.edm1240 .body .right .store-box .list .label {
  font-size: 18px !important;
  font-weight: 700;
}

.edm-style-1 {
  cursor: pointer;
}
.edm-1-1 .edm-style-1 .body .right .sales-box .title .name {
  font-size: 18px !important;
}
</style>

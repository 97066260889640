<template>
  <NavBar3 />
  <!-- 選擇版型 -->
  <section class="section px-4 edm-list-wrap">
    <div class="container-fluid">

      <div class="row">
        <!-- 風格一 -->
        <div class="col-md-4 mb-3 mb-md-0">
          <label class="form-row mb-0" for="template1">
            <div class="col-4">
              <div class="custom-control custom-radio">
                <input
                  v-model="uiStyle"
                  type="radio"
                  id="template1"
                  name="template"
                  class="custom-control-input"
                  value="tem1"
                />
                <label class="custom-control-label" for="template1">風格一</label>
              </div>
            </div>
            <div class="col-8">
              <img alt="style Image" v-bind:src="imageStyle1" class="img-fluid" />
            </div>
          </label>
        </div>
        <!-- 風格二  -->
        <div class="col-md-4 mb-3 mb-md-0">
          <label class="form-row mb-0" for="template2">
            <div class="col-4">
              <div class="custom-control custom-radio">
                <input
                  v-model="uiStyle"
                  type="radio"
                  id="template2"
                  name="template"
                  class="custom-control-input"
                  value="tem2"
                />
                <label class="custom-control-label" for="template2">風格二</label>
              </div>
            </div>
            <div class="col-8">
              <img alt="style Image" v-bind:src="imageStyle2" class="img-fluid" />
            </div>
          </label>
        </div>
        <!-- 風格三 -->
        <div class="col-md-4">
          <label class="form-row mb-0" for="template3">
            <div class="col-4">
              <div class="custom-control custom-radio">
                <input
                  v-model="uiStyle"
                  type="radio"
                  id="template3"
                  name="template"
                  class="custom-control-input"
                  value="tem3"
                />
                <label class="custom-control-label" for="template3">風格三</label>
              </div>
            </div>
            <div class="col-8">
              <img alt="style Image" v-bind:src="imageStyle3" class="img-fluid" />
            </div>
          </label>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-center mt-3 pt-3 flex-column col-12">
        <button class="btn btn-primary btn-block" v-on:click="createTemplate" type="button" :disabled="isDisabled">
          產生EDM
        </button>
        <button class="btn btn-dark btn-block mt-3" type="button" @click="$router.go(-1)">返回</button>
      </div>
    </div>
  </section>
</template>

<script>
import NavBar3 from "@/components/NavBar3.vue";

export default {
  components: {
    NavBar3,
  },
  data() {
    return {
      basePath: process.env.VUE_APP_BASEPATH,
      
      uiStyle: "tem1",
      imageStyle1: "/imgs/edmPreview/style1_1.png",
      imageStyle2: "/imgs/edmPreview/style2_1.png",
      imageStyle3: "/imgs/edmPreview/style3_1.png",
      windowWidth: window.innerWidth,
      col4: "col-4",
      pr0: "pr-0",
      isDisabled: false
    };
  },
  watch: {
    uiStyle: function(newVal) {
      if(newVal.trim() != "") {
        this.isDisabled = false;
      }
    }
  },
  methods: {
    // 產生edm 將代碼存入後端方便取用
    // 通用代碼為 tem風格-物件數
    // EX. tem1-1 (風格一且一個物件)、tem2-6 (風格二且六個物件)

    createTemplate() {
      let token = sessionStorage.getItem("token");
      let items = sessionStorage.getItem("items");

      let url = `${this.basePath}/api/edm-generator`;

      let access_token = sessionStorage.getItem('access_token');
      let id_token = sessionStorage.getItem('id_token');

      url += `?access_token=${access_token}&id_token=${id_token}`;

      // 儲存 UI template
      let itemsArr = items.split(",");
      let itemsLength = itemsArr.length;
      let uiStyle = this.uiStyle;

      let tempalte = uiStyle + "-" + itemsLength;
      // 儲存到Storage使用
      sessionStorage.setItem("temStyle", tempalte);

      // 儲存ui風格到下一頁使用
      let newUiStyle = uiStyle;

      // 傳送物件相關資料
      fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + `${token}`,
        },
        body: JSON.stringify({
          item_ids: items,
          template: tempalte,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          // 將資料存在sessionStorage以利下個頁面使用
          // 存此風格與物件id
          sessionStorage.setItem("edmId", result.data.id);
          // 存此風格與物件專屬code
          sessionStorage.setItem("edmCode", result.data.code);

          // 轉跳下一頁
          window.location.href = "/edm-generator/preview";
        });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    isDesktop() {
      return this.windowWidth >= 576;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    // console.log('判定張數');
    // v-bind:src Image , if image > 2 display other images
    // 取得items長度,以此判定前一步驟選擇幾張圖片;
    // 用split將物件用
    let items = sessionStorage.getItem("items");
    let itemsArr = items.split(",");
    let itemsLength = itemsArr.length;
    let template = this.uiStyle;

    if (itemsLength == 2) {
      this.imageStyle1 = "/imgs/edmPreview/style1_2.png";
      this.imageStyle2 = "/imgs/edmPreview/style2_2.png";
      this.imageStyle3 = "/imgs/edmPreview/style3_2.png";
    } else if (itemsLength == 4) {
      this.imageStyle1 = "/imgs/edmPreview/style1_4.png";
      this.imageStyle2 = "/imgs/edmPreview/style2_4.png";
      this.imageStyle3 = "/imgs/edmPreview/style3_4.png";
    } else if (itemsLength == 6) {
      this.imageStyle1 = "/imgs/edmPreview/style1_6.png";
      this.imageStyle2 = "/imgs/edmPreview/style2_6.png";
      this.imageStyle3 = "/imgs/edmPreview/style3_6.png";
    } else if (itemsLength == 8) {
      this.imageStyle1 = "/imgs/edmPreview/style1_8.png";
      this.imageStyle2 = "/imgs/edmPreview/style2_8.png";
      this.imageStyle3 = "/imgs/edmPreview/style3_8.png";
    } else {
      this.imageStyle1 = "/imgs/edmPreview/style1_1.png";
      this.imageStyle2 = "/imgs/edmPreview/style2_1.png";
      this.imageStyle3 = "/imgs/edmPreview/style3_1.png";
    }
  },
};
</script>

<style>
.img-fluid {
  width: 80%;
}
</style>

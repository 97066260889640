<template>
  <NavBar2 />

  <div class="d-none">
    <!-- {{ Packages.items[0].image }}
    {{ Packages.items[0].id }}
    {{ Packages.items[0].name }} -->

    <!-- {{ Packages[0] }} -->

    <!-- <div v-for="items in Packages.items" > {{items.id}}</div> -->
  </div>

  <section class="section">
    <div class="container">
      <!-- <a href="{{ route('front.estate-competitor.filter') }}" class="btn btn-primary">重新篩選</a> -->
      <!-- <h5>同業物件</h5> -->

      <!--呈現的條件-->
      <small class="filter-text" v-if="queryObject">
        搜尋條件：
        <!-- {{ $d_param_key }}:{{ $d_param_value }}<br> -->
        <span v-if="queryObject?.keyword">{{ queryObject?.keyword }},</span>
        <span v-if="queryObject?.county">{{ queryObject?.county }},</span>
        <template v-if="queryObject?.district">
          <span v-if="typeof queryObject?.district == 'string'">{{ queryObject?.district }},</span>
          <span v-else v-for="item in queryObject?.district">{{ item }},</span>
        </template>
        <span v-if="queryObject?.floor_start">{{ queryObject?.floor_start }} 樓,</span>
        <span
          v-if="
            queryObject?.structure_room_start ||
            queryObject?.structure_hall_start ||
            queryObject?.structure_bath_start
          "
        >
          格局 {{ queryObject?.structure_room_start }} 房 {{ queryObject?.structure_hall_start }} 廳
          {{ queryObject?.structure_bath_start }} 衛,
        </span>
        <span v-if="queryObject?.age">屋齡 {{ queryObject?.age }} 年,</span>
        <span v-if="queryObject?.brand">{{ getBrand(queryObject?.brand) }},</span>
        <span v-if="queryObject?.min_floor_space"
          >主建物坪數 {{ queryObject?.min_floor_space }} ~
        </span>
        <span v-if="queryObject?.max_floor_space">{{ queryObject?.max_floor_space }} 坪,</span>
        <span v-if="queryObject?.min_price">總價 {{ Number(queryObject?.min_price).toLocaleString() }} ~ </span>
        <span v-if="queryObject?.max_price">{{ Number(queryObject?.max_price).toLocaleString() }} 萬,</span>
        <span v-if="queryObject?.min_total_floor_space"
          >總坪數 {{ queryObject?.min_total_floor_space }} ~
        </span>
        <span v-if="queryObject?.max_total_floor_space"
          >{{ queryObject?.max_total_floor_space }} 坪,
        </span>
        <span v-if="queryObject?.date_range">{{ getDate(queryObject?.date_range) }},</span>
        <span v-if="queryObject?.status">{{ queryObject?.status == 'Y' ? '上架' : '不限' }},</span>
        <template v-if="queryObject?.type">
          <span v-if="typeof queryObject?.type == 'string'">{{ queryObject?.type }},</span>
          <span v-else v-for="item in queryObject?.type">{{ item }},</span>
        </template>
      </small>
      <form id="Form" action="">
        <!--要帶入的條件參數-->
        <input type="hidden" name="" value="" />
        <div class="form-row mb-4">
          <div class="col-5">
            <!-- <label>排序方式</label> -->
            <select class="custom-select" @change="sortByOption($event)" name="sort" id="sort">
              <option value="">不限</option>
              <option value="hPrice_num-desc">總價高到低排序</option>
              <option value="hPrice_num-asc">總價低到高排序</option>
              <option value="hPin-desc">坪數大到小排序</option>
              <option value="hPin-asc">坪數小到大排序</option>
              <option value="hAge-desc">屋齡高到低排序</option>
              <option value="hAge-asc">屋齡低到高排序</option>
              <option value="post_at-desc" selected="selected">上架日期新到舊</option>
              <option value="post_at-asc">上架日期舊到新</option>
            </select>
          </div>
          <div class="col-6 d-flex">
            <small class="text-muted my-auto"
              >共<span class="text-danger"> {{ objectLength }} </span>筆資料</small
            >
          </div>
        </div>
      </form>

      <!-- loding..... -->
      <!-- <loading :active.sync="LoaderVisiable" :can-cancel="true"></loading> -->
      <div class="loding d-flex justify-content-center">
        <loading v-model:active="isLoading" :loader="'dots'" :can-cancel="true" />
      </div>

      <!-- results -->
      <ul class="estate-list" v-if="Packages.length > 0">
        <li v-for="(items, index) in Packages" class="estate-list-item">
          <div class="form-row">
            <div class="col-8">
              <div class="form-row top-row mb-2">
                <!-- 案名 -->
                <div class="col">
                  <div class="name font-weight-bold" @click="objectLink(items.hUrl)">
                    {{ items.name }}
                  </div>
                </div>
              </div>

              <!--地址、相關資訊 -->
              <div class="estate-item">
                <span class="address" :id="'getToChange' + `${index}`">{{ items.address }}</span
                ><br />
                <small
                  >坪數{{
                    Number(
                      items.type == "土地" ? items?.hLand_num : items.total_floor_space
                    ).toFixed(2)
                  }}｜{{ items.structure_room > 0 ? items.structure_room : '-' }}房{{ items.structure_hall > 0 ? items.structure_hall : '-' }}廳
                  {{ items.structure_bath > 0 ? items.structure_bath : '-' }}衛｜{{ items.age > 0 ? items.age : '-' }}年｜{{
                    getFloor(items.floor, items.total_floor)
                  }}｜{{ items.type == "" ? "無類型資訊" : items.type }}</small
                >
              </div>

              <!-- 案件來源、上架時間 -->
              <div class="ObjectInfo d-flex my-2">
                <div class="brand mr-5">
                  <small class="date">來源：591自售</small>
                </div>
                <div class="createTime ml-3">
                  <small class="date">上架時間：{{ dateFormat(items.post_at) }}</small>
                </div>
              </div>

              <button
                class="btn btn-primary btn-sm mr-2"
                v-on:click="
                  getOrigin(
                    `${index}`,
                    `${items.county}`,
                    `${items.district}`,
                    `${items.address}`,
                    `${items.total_floor_space}`,
                    `${items.floor_space}`,
                    `${items.floor}`,
                    `${items.total_floor}`,
                    `${items.age}`
                  )
                "
                type="button"
              >
                地址還原查詢
              </button>
              <button class="btn btn-primary btn-sm" type="button" @click="goObject(items)">
                分享案件
              </button>
            </div>

            <div class="col-4">
              <div class="image">
                <img :src="`${items.image}`" class="estate-item" data-id="" />
              </div>
              <div class="text-right mt-1">
                <span class="price">{{ items.price.toLocaleString() }}</span
                ><small>萬</small>
              </div>
              <div class="col-auto pr-0 d-block favorite-button">
                <button
                  v-if="isFavorite(items.source_number)"
                  v-on:click="changeFavorite(`${items.source_number}`)"
                  class="btn btn-sm favorite"
                  :id="'favorite' + `${items.source_number}`"
                  data-type="estate_fsbo"
                >
                  <i class="fa-solid fa-heart" style="color: #c0ac79"></i>
                </button>

                <button
                  v-else-if="!isFavorite(items.source_number)"
                  class="btn btn-sm favorite"
                  :id="'favorite' + `${items.source_number}`"
                  v-on:click="changeFavorite(`${items.source_number}`)"
                  data-type="estate_fsbo"
                >
                  <i class="fa-regular fa-heart" style="color: #c0ac79"></i>
                </button>
              </div>
            </div>
          </div>
        </li>
        <!-- address loading -->
        <div class="address-loader" v-show="addressLoading">
          <loading v-model:active="addressLoading" :loader="'dots'" :can-cancel="true" />
        </div>
      </ul>
      <div class="empty-list" v-if="Packages.length == 0 && isLoading == false">
        <span>目前沒有物件符合您的需求，請重新搜尋</span>
      </div>
    </div>
  </section>
  <!-- 頁數選單 -->
  <div v-bind:class="{ hiddenElement: isHidden }" class="d-flex justify-content-center mb-5">
    <paginate
      :page-count="`${MaxPage}`"
      :page-range="3"
      :margin-pages="2"
      :click-handler="clickCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'page-item'"
      v-model="PerPage"
    >
    </paginate>
  </div>
  <!-- {{ PerPage }} -->
</template>

<script>
import $ from "jquery";
import NavBar2 from "@/components/NavBar2.vue";
import { useRoute } from "vue-router";

// 頁面按鈕
import Paginate from "vuejs-paginate-next";

// 讀取圖示
import Loading from "vue-loading-overlay";
// import {useLoading} from 'vue-loading-overlay'

export default {
  name: "fsbo",
  components: {
    NavBar2,
    paginate: Paginate,

    // Loding
    Loading,
  },
  data() {
    return {
      basePath: process.env.VUE_APP_BASEPATH,
      isLoading: false,
      isHidden: true,

      page: "",
      Packages: [],
      PerPage: 1,
      PageSize: 50,
      objectLength: 0,

      // Loding....
      // LoaderVisiable: false
      loader: "dots",
      query: {},
      favoriteList: [],
      sort: ['post_at', 'desc'],
      queryObject: {},
      addressLoading: false,
    };
  },
  computed: {
    // 頁數
    MaxPage() {
      var MaxPage = Math.ceil(this.objectLength / this.PageSize);

      if (MaxPage == 0) {
        var MaxPage = 1;
      } else {
        var MaxPage = Math.ceil(this.objectLength / this.PageSize);
      }

      return MaxPage;
    },
    postAtStart(){
      return this.queryObject.date_range;
    },
  },
  methods: {
    sortByOption(event) {
      this.sort = event.target.value.split("-");
      this.page = 1;
      this.clickCallback(1);
    },
    // 頁面按鈕：更換頁面重新取得下一頁資料
    clickCallback(pageNum) {
      this.isLoading = true;

      // 第N頁載入第(N-1)*50筆到第N*50筆資料
      // 獲取資料
      let token = sessionStorage.getItem("token");

      let url = `${process.env.VUE_APP_WEBCRAWLER}/get_datas`;
      const username = `${process.env.VUE_APP_USERNAME}`;
      const password = `${process.env.VUE_APP_PASSWORD}`;
      const authHeader = 'Basic ' + btoa(username + ':' + password);

      // 擷取前頁面關鍵字
      const route = useRoute();
      let {
        keyword,
        address,
        store_name,
        county,
        district,
        floor_start,
        structure,
        age,
        min_floor_space,
        max_floor_space,
        min_total_floor_space,
        max_total_floor_space,
        min_price,
        max_price,
        date_range,
        type,
        status,
        brand,
        d_type,
        structure_room_start,
        structure_hall_start,
        structure_bath_start,
      } = this.query;

      let min_age;
      let max_age;

      if (age.includes("-")) {
        let ageArray = age.split("-");
        min_age = ageArray[0];
        max_age = ageArray[1];
      } else {
        min_age = 0;
        max_age = 999;
      }

      let post_at_start;
      let post_at_end;

      if(date_range == 'three-days'){
        post_at_start = this.getLastOrNextFewDateBy(-3);
        post_at_end = this.getLastOrNextFewDateBy(0);
      }else if(date_range == 'one-week'){
        post_at_start = this.getLastOrNextFewDateBy(-7);
        post_at_end = this.getLastOrNextFewDateBy(0);
      }else if(date_range == 'one-month'){
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = String(currentDate.getMonth() + 1).padStart(2, '0');
        let firstDayOfMonth = new Date(year, currentDate.getMonth(), 1);
        let lastDayOfMonth = new Date(year, currentDate.getMonth() + 1, 0);
        post_at_start = `${year}-${month}-01`;
        post_at_end = `${year}-${month}-${String(lastDayOfMonth.getDate()).padStart(2, '0')}`;
      }

      // Fetch取得資料
      fetch(url, {
        headers: {
          // token: token,
          // Authorization: `Bearer ${token}`,
          'Authorization': authHeader,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",

        // 搜尋資料請求
        body: JSON.stringify({
          "post_at_start": post_at_start,
          "post_at_end": post_at_end,
          "hAction":"sale",
          "hAge_start": Number(min_age),
          "hAge_end": Number(max_age),
          "h_Main_Pin_num_start": Number(min_floor_space),
          "h_Main_Pin_num_end": Number(max_floor_space) ? Number(max_floor_space) : 999,
          "hPin_num_start": Number(min_total_floor_space),
          "hPin_num_end": Number(max_total_floor_space) ? Number(max_total_floor_space) : 999,
          "hPrice_num_start": Number(min_price),
          "hPrice_num_end": Number(max_price) ? Number(max_price) : 9999999,
          "structure_room_start": Number(structure_room_start) > 0 ? Number(structure_room_start) : 0,
          "structure_room_end": Number(structure_room_start) > 0 ? Number(structure_room_start) : 100,
          "structure_hall_start": Number(structure_hall_start) > 0 ? Number(structure_hall_start) : 0,
          "structure_hall_end": Number(structure_hall_start) > 0 ? Number(structure_hall_start) : 100,
          "structure_bath_start": Number(structure_bath_start) > 0 ? Number(structure_bath_start) : 0,
          "structure_bath_end": Number(structure_bath_start) > 0 ? Number(structure_bath_start) : 100,
          "floor_start": Number(floor_start) > 0 ? Number(floor_start) : 0,
          "floor_end": Number(floor_start) > 0 ? Number(floor_start) : 999,
          "hCity": [county],
          "hNo": [],
          "hKind": typeof type == 'string' ? [type] : type,
          "hSeller": "",
          "hSource": typeof brand == 'string' ? [brand] : brand,
          "hArea": typeof district == 'string' ? [district] : district,
          "hZip": "",
          "hParking": "",
          "hStatus": status,
          "hSellerName": "",
          "hTitle": keyword,
          "hComm": "",
          "hAddress": address,
          "hPin_detail": "",
          "page_n": this.PerPage,
          "max_count": this.PageSize,
          "sort_field": this.sort[0],
          "sort_type": this.sort[1]
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((formData) => {
          this.isLoading = false;
          this.isHidden = false;

          // 先取50頁
          this.Packages = formData.results;
        });
    },

    getOrigin(
      id,
      city,
      area,
      road,
      min_total_ping,
      min_main_ping,
      min_level,
      max_total_level,
      min_age
    ) {
      this.addressLoading = true;
      // 將地址的區以後的資料取代成新的road
      let changeResult;
      let newResult;
      let oldRoad;
      let newRoad;
      if (road.includes("鄉")) {
        changeResult = road.replace("鄉", ",");
        newResult = changeResult.slice(",");
        oldRoad = road.split("鄉");
        newRoad = oldRoad[1];
      } else {
        changeResult = road.replace("區", ",");
        newResult = changeResult.slice(",");
        oldRoad = road.split("區");
        newRoad = oldRoad[1];
      }

      let url = `${process.env.VUE_APP_ADDRESS}?city=${city}&area=${area}&road=${newRoad}&min_total_ping=${Number(min_total_ping) > 0 ? min_total_ping : 1}&max_total_ping=${Number(min_total_ping) > 0 ? Number(min_total_ping) + 0.2 : 999}&min_main_ping=${Number(min_main_ping) > 0 ? min_main_ping : 1}&max_main_ping=${Number(min_main_ping) > 0 ? Number(min_main_ping) + 0.2 : 999}&min_level=${Number(min_level) > 0 ? min_level : 1}&max_level=${Number(min_level) > 0 ? Number(min_level) + 1 : 2}&min_total_level=${Number(max_total_level) > 0 ? max_total_level : 1}&max_total_level=${Number(max_total_level) > 0 ? Number(max_total_level) + 1 : 2}`;
      if(Number(min_age) > 0) {
        url += `&min_age=${min_age}&max_age=${Number(min_age) + 1.5}`;
      }
      // url範例
      // let url = `${process.env.VUE_APP_ADDRESS}?city=台北市&area=大同區&road=長安西路&min_total_ping=26&max_total_ping=26.2&min_main_ping=19.3&max_main_ping=19.5&min_level=10&max_total_level=11&max_age=37`;

      let token = sessionStorage.getItem("token");

      fetch(url, {
        headers: {
          "Cache-Control": "no-cache",
          "Ocp-Apim-Subscription-Key": "c7fcdc54069841ec81e359312e3025a4",
          // 'Accept': 'application/json',
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          // let obj = this.PerPage[id];
          // obj.newAddress = `${result.datas[0].city_name}` + `${result.datas[0].area_name}` + `${result.datas[0].dd_address}`;
          // 取得id fetch後隱藏
          if (result.status == "OK") {
            let elementID = document.getElementById("getToChange" + id);
            elementID.innerHTML = "";
            setTimeout(() => {
              let content = "";
              result.datas.map((d) => {
                let text = `${d.city_name}${d.area_name}${d.dd_address}<br/>`;
                content += text;
              });
              elementID.innerHTML = content;
              this.addressLoading = false;
            }, 300);
          } else {
            this.addressLoading = false;
            alert(result.msg);
          }
        })
        .catch(error => {
          this.addressLoading = false;
          alert(error);
        });
    },

    // 收藏按鈕功能
    changeFavorite(id) {
      let type = "estate_fsbo";

      let token = sessionStorage.getItem("token");
      let url = `${this.basePath}/api/favorite/toggle`;

      let access_token = sessionStorage.getItem("access_token");
      let id_token = sessionStorage.getItem("id_token");

      url += `?access_token=${access_token}&id_token=${id_token}`;

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          token: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "post",

        body: JSON.stringify({
          id: id,
          type: type,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          this.getFavoriteList();
          let favorite = this.isFavorite(id);
          let favoriteID = document.getElementById("favorite" + id);

          if (!favorite) {
            favoriteID.innerHTML = "";
            favoriteID.innerHTML += `<i class="fa-solid fa-heart" style="color:#C0AC79;"></i>`;
            alert("您已收藏此物件！");
          } else if (favorite) {
            favoriteID.innerHTML = "";
            favoriteID.innerHTML += ` <i class="fa-regular fa-heart" style="color:#C0AC79;"></i>`;
            alert("您已移除收藏此物件！");
          }
        });
    },

    dateFormat(data) {
      let date = new Date(data);
      let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      return `${date.getFullYear()}/${month}/${day}`;
    },

    objectLink(url) {
      window.open(url, "_black");
    },

    goObject(item) {
      const params = {
        name: item.name ?? "",
        type: item.type ?? "",
        structure_bath: item.structure_bath ?? 0,
        structure_hall: item.structure_hall ?? 0,
        structure_room: item.structure_room ?? 0,
        floor_space: item.total_floor_space ?? 0,
        price: item.price ?? 0,
        hUrl: item.hUrl ?? "",
        hSellerName: item.hSellerName ?? "",
        hSeller: item.hSeller ?? "",
        image: item.image ?? ""
      };
      this.$router.push({ path: "/estate-fsbo/object", query: params });
    },

    getFavoriteList() {
      // 獲取資料
      let token = sessionStorage.getItem("token");
      let url = `${this.basePath}/api/favorite/ids?type=estate_fsbo`;

      let access_token = sessionStorage.getItem("access_token");
      let id_token = sessionStorage.getItem("id_token");

      url += `&access_token=${access_token}&id_token=${id_token}`;

      // Fetch取得資料
      fetch(url, {
        headers: {
          // token: token,
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          // 先取50頁
          this.favoriteList = res.data.ids;
        });
    },

    isFavorite(id) {
      let idString = id.toString();
      return this.favoriteList.includes(idString);
    },

    getBrand(id) {
      let brandName;
      switch (id) {
        case "20":
          brandName = "591自售";
          break;
        default:
          break;
      }
      return brandName;
    },

    getDate(id) {
      let date;
      switch (id) {
        case "three-days":
          date = "三日內";
          break;
        case "one-week":
          date = "一週內";
          break;
        case "one-month":
          date = "本月";
          break;
        default:
          break;
      }
      return date;
    },

    saveFilter(data) {
      let body = JSON.stringify({
        min_age: data.min_age,
        max_age: data.max_age,
        min_floor_space: data.min_floor_space,
        max_floor_space: data.max_floor_space,
        min_total_floor_space: data.min_total_floor_space,
        max_total_floor_space: data.max_total_floor_space,
        min_price: data.min_price,
        max_price: data.max_price,
        floor_start: data.floor_start,
        county: data.county,
        type: data.type,
        brand: data.brand,
        district: data.district,
        status: data.status,
        keyword: data.keyword,
        address: data.address,
        structure_room_start: data.structure_room_start,
        structure_hall_start: data.structure_hall_start,
        structure_bath_start: data.structure_bath_start,
        age: data.age,
        date_range: data.date_range,
      });
      localStorage.setItem("estate_fsbo_filter", body);
    },

    getFloor(floor, total_floor) {
      return (floor == 0 && total_floor == 0) || !floor || !total_floor
        ? "未提供資訊"
        : floor == 0
        ? "整棟"
        : floor == -1
        ? "地下室"
        : `${floor}/${total_floor}樓`;
    },

    getLastOrNextFewDateBy(day){
      let today = new Date();
      let lastOrNextDate = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(lastOrNextDate);
      let year = today.getFullYear();
      let month = today.getMonth() + 1;
      let date = today.getDate();
      month = month < 10 ? '0' + month : month;
      date = date < 10 ? '0' + date : date;
      return year + '-' + month + '-' + date;
    },
  },
  mounted() {
    // jQuery
    $(document).ready(function () {
      // 按鈕文字顏色
      $(".pagination").attr("class", "pagination mb-0");
      $(".page-link").css("border", "transparent");
      $(".page-link").css("color", "black");
      $("li.page-item").css("color", "black");

      // 按下一頁後的樣式
      $(".page-item").css("background-color", "transparent");
    });
  },
  watch: {
    // 頁面返回
    PerPage: (OldVal, NewVal) => {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.getFavoriteList();
    // 獲取資料
    let token = sessionStorage.getItem("token");

    let url = `${process.env.VUE_APP_WEBCRAWLER}/get_datas`;
    const username = `${process.env.VUE_APP_USERNAME}`;
    const password = `${process.env.VUE_APP_PASSWORD}`;
    const authHeader = 'Basic ' + btoa(username + ':' + password);

    // 擷取前頁面關鍵字
    const route = useRoute();
    let {
      keyword,
      address,
      store_name,
      county,
      district,
      floor_start,
      structure,
      age,
      min_floor_space,
      max_floor_space,
      min_total_floor_space,
      max_total_floor_space,
      min_price,
      max_price,
      date_range,
      type,
      status,
      brand,
      d_type,
      structure_room_start,
      structure_hall_start,
      structure_bath_start,
    } = route.query;

    this.query = route.query;
    this.queryObject = JSON.parse(JSON.stringify(this.query));
    this.saveFilter(this.queryObject);

    this.isLoading = true;

    let min_age;
    let max_age;

    if (age.includes("-")) {
      let ageArray = age.split("-");
      min_age = ageArray[0];
      max_age = ageArray[1];
    } else {
      min_age = 0;
      max_age = 999;
    }

    let post_at_start;
    let post_at_end;

    if(date_range == 'three-days'){
      post_at_start = this.getLastOrNextFewDateBy(-3);
      post_at_end = this.getLastOrNextFewDateBy(0);
    }else if(date_range == 'one-week'){
      post_at_start = this.getLastOrNextFewDateBy(-7);
      post_at_end = this.getLastOrNextFewDateBy(0);
    }else if(date_range == 'one-month'){
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = String(currentDate.getMonth() + 1).padStart(2, '0');
      let firstDayOfMonth = new Date(year, currentDate.getMonth(), 1);
      let lastDayOfMonth = new Date(year, currentDate.getMonth() + 1, 0);
      post_at_start = `${year}-${month}-01`;
      post_at_end = `${year}-${month}-${String(lastDayOfMonth.getDate()).padStart(2, '0')}`;
    }

    // Fetch取得資料
    fetch(url, {
      headers: {
        // token: token,
        // Authorization: `Bearer ${token}`,
        'Authorization': authHeader,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",

      // 搜尋資料請求
      body: JSON.stringify({
        "post_at_start": post_at_start,
        "post_at_end": post_at_end,
        "hAction":"sale",
        "hAge_start": Number(min_age),
        "hAge_end": Number(max_age),
        "h_Main_Pin_num_start": Number(min_floor_space),
        "h_Main_Pin_num_end": Number(max_floor_space) ? Number(max_floor_space) : 999,
        "hPin_num_start": Number(min_total_floor_space),
        "hPin_num_end": Number(max_total_floor_space) ? Number(max_total_floor_space) : 999,
        "hPrice_num_start": Number(min_price),
        "hPrice_num_end": Number(max_price) ? Number(max_price) : 9999999,
        "structure_room_start": Number(structure_room_start) > 0 ? Number(structure_room_start) : 0,
        "structure_room_end": Number(structure_room_start) > 0 ? Number(structure_room_start) : 100,
        "structure_hall_start": Number(structure_hall_start) > 0 ? Number(structure_hall_start) : 0,
        "structure_hall_end": Number(structure_hall_start) > 0 ? Number(structure_hall_start) : 100,
        "structure_bath_start": Number(structure_bath_start) > 0 ? Number(structure_bath_start) : 0,
        "structure_bath_end": Number(structure_bath_start) > 0 ? Number(structure_bath_start) : 100,
        "floor_start": Number(floor_start) > 0 ? Number(floor_start) : 0,
        "floor_end": Number(floor_start) > 0 ? Number(floor_start) : 999,
        "hCity": [county],
        "hNo": [],
        "hKind": typeof type == 'string' ? [type] : type,
        "hSeller": "",
        "hSource": typeof brand == 'string' ? [brand] : brand,
        "hArea": typeof district == 'string' ? [district] : district,
        "hZip": "",
        "hParking": "",
        "hStatus": status,
        "hSellerName": "",
        "hTitle": keyword,
        "hComm": "",
        "hAddress": address,
        "hPin_detail": "",
        "page_n": this.PerPage,
        "max_count": this.PageSize,
        "sort_field": this.sort[0],
        "sort_type": this.sort[1]
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((formData) => {
        this.isLoading = false;
        this.isHidden = false;

        // 先取50頁
        this.Packages = formData.results;
        this.objectLength = formData.row_count;
      });
  },
};
</script>
<style lang="css">
.page-item {
  background-color: transparent;
  color: black;
}

.page-item.active .page-link {
  background-color: #c0ac79;
  color: black;
  border: 1px solid #c0ac79;
}

.page-item.active {
  background-color: #c0ac79;
  color: black;
}

.page-link {
  background-color: transparent;
  color: black;
  border: transparent;
}

.page-link:hover {
  background-color: transparent;
  color: black;
  border: transparent;
}

.hiddenElement {
  display: none !important;
}

.favorite-button {
  height: 20px;
}

.address-loader {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background-color: rgba(56, 55, 53, 0.7);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty-list {
  display: flex;
  justify-content: center;
}
</style>

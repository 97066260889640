<template>
    <img class="w-100" src="@/assets/imgs/edm/footer.jpg" alt="">
    <!-- <div class="title">席捲全球第一房仲品牌</div>
    <div class="count">
      <span class="number">86</span>
      <span class="text">國家</span>
    </div>
    <div class="count">
      <span class="number">16,000</span>
      <span class="text">營業據點</span>
    </div>
    <div class="count">
      <span class="number">169,000</span>
      <span class="text">經紀人</span>
    </div> -->
</template>

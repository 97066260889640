<template>
  <div class="edm-1-8 w-620 mx-auto" id="printHTML">
    <!-- EDM預覽 -->
    <section class="section py-0">
      <div class="container-fluid p-0">
        <!-- EDM風格一 -->
        <div class="edm-style-1-8">
          <!-- head -->
          <EdmHeader620 :user="user" />
          <div class="body default-bg">
            <div class="mainContent d-flex justify-content-between flex-wrap">
              <div v-for="(items, index) in list" class="objectCard_m mb-3 d-flex">
                <!-- 左半部 -->
                <div class="objectLeft pl-1">
                  <div class="image">
                    <img :src="`data:image/png;base64,${items.image}`" alt="object image" />
                  </div>
                  <div class="title py-1 d-flex align-items-center justify-content-center">
                    <span>
                      {{ items.name }}
                    </span>

                    <!-- 編號 -->
                    <div class="objectNo">
                      {{ index + 1 }}
                    </div>
                  </div>
                  <div
                    class="total-box py-1 box d-flex align-items-center justify-content-center flex-column"
                  >
                    <div class="total">
                      總價 $
                      <b class="text-danger">{{ items.price.toLocaleString() }}</b>
                      萬
                    </div>
                  </div>

                  <!-- 右半部 -->
                  <div class="objectRight p-0 m-0">
                    <div class="baseInfo box">
                      <ul class="list d-flex flex-column">
                        <div class="col-12 p-0 m-0">
                          <li style="height: calc(35px * 1.5)">
                            <div class="label">地址</div>
                            <div class="address-wrap">{{ items.county }}{{ items.district }}{{ items.address }}</div>
                          </li>
                        </div>
                        <div class="col-12 p-0 m-0 d-flex">
                          <div class="col-5 p-0 m-0">
                            <li>
                              <div class="label">總坪數</div>
                              <span class="text-danger">{{
                                Number(items.total_floor_space).toFixed(2)
                              }}</span>
                              坪
                            </li>

                            <li>
                              <div class="label">種類</div>
                              <div class="text">{{ items.type }}</div>
                            </li>
                          </div>
                          <div class="col-6 p-0 m-0">
                            <li>
                              <div class="label mb-1">格局</div>
                              <div class="text">{{
                                setStructure(
                                  Number(items.structure_room),
                                  Number(items.structure_hall),
                                  Number(items.structure_bath)
                                )
                              }}</div>
                            </li>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- footer -->
          <EdmFooter />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import EdmFooter from "@/components/EdmFooter.vue";
import EdmHeader620 from "@/components/EdmHeader620.vue";
import { useRoute } from "vue-router";
import imageToBase64 from "image-to-base64/browser";
import html2Canvas from "html2canvas";

export default {
  components: {
    EdmHeader620,
    EdmFooter,
  },
  data() {
    return {
      list: [],
    };
  },
  props: ["data", "user", "isPrint", "isDownload"],
  watch: {
    isPrint: function print(newVal) {
      if (newVal) {
        const canvas = document.getElementById("QRcode");
        const QRbase64 = canvas.toDataURL();
        this.$emit("printHTML", this.$el.innerHTML, QRbase64);
      }
    },
    isDownload: function print(newVal) {
      if (newVal) {
        // const canvas = document.getElementById('QRcode');
        // const QRbase64 = canvas.toDataURL();
        let filename = "";
        this.data.edm.items.map((item) => {
          filename += `${item.name}, `;
        });
        this.$emit("downloadHTML", filename, 8);
      }
    },
  },
  async mounted() {
    let array = this.data.edm.items;
    for (let i = 0; i < array.length; i++) {
      const image = await imageToBase64(array[i].image);
      this.list.push({ ...array[i], image: image });
    }
    setTimeout(() => {
      this.getHTML(document.getElementById("printHTML"));
    }, 1000);
  },
  methods: {
    async getHTML(data) {
      let pageData = await html2Canvas(data, {
        scale: 3,
        allowTaint: true,
      });
      let imgUrl = await pageData.toDataURL("image/jpeg");
      this.$emit("imgUrl", imgUrl);
    },
    setStructure(room, hall, bath) {
      const rooms = [];
      if (room > 0) {
        rooms.push(room + " 房");
      }
      if (hall > 0) {
        rooms.push(hall + " 廳");
      }
      if (bath > 0) {
        rooms.push(bath + " 衛");
      }
      return rooms.length > 0 ? rooms.join(" | ") : "-";
    },
  },
};
</script>

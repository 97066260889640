<template>
  <NavBar3 />
  <div class="container-fluid">
    <p class="text-muted my-2">EDM內容預覽</p>
    <div class="y-container mx-auto mb-3">
      <div class="y-content" v-show="show">
        <edm1_1 v-if="`${useEDM}` == 1 && `${tem}` == 'tem1-1'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm1_2 v-if="`${useEDM}` == 2 && `${tem}` == 'tem1-2'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm1_4 v-if="`${useEDM}` == 4 && `${tem}` == 'tem1-4'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm1_6 v-if="`${useEDM}` == 6 && `${tem}` == 'tem1-6'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm1_8 v-if="`${useEDM}` == 8 && `${tem}` == 'tem1-8'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm2_1 v-if="`${useEDM}` == 1 && `${tem}` == 'tem2-1'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm2_2 v-if="`${useEDM}` == 2 && `${tem}` == 'tem2-2'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm2_4 v-if="`${useEDM}` == 4 && `${tem}` == 'tem2-4'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm2_6 v-if="`${useEDM}` == 6 && `${tem}` == 'tem2-6'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm2_8 v-if="`${useEDM}` == 8 && `${tem}` == 'tem2-8'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm3_1 v-if="`${useEDM}` == 1 && `${tem}` == 'tem3-1'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm3_2 v-if="`${useEDM}` == 2 && `${tem}` == 'tem3-2'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm3_4 v-if="`${useEDM}` == 4 && `${tem}` == 'tem3-4'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm3_6 v-if="`${useEDM}` == 6 && `${tem}` == 'tem3-6'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
        <edm3_8 v-if="`${useEDM}` == 8 && `${tem}` == 'tem3-8'" :data="Packages" :user="user" @imgUrl="getImgUrl"/>
      </div>
      <div class="imgContent">
        <img class="imgHTML" :src="imgUrl" />
      </div>
    </div>
  </div>

  <div class="edm-box mx-auto mb-1 px-3">
    <p>EDM連結：</p>
    <div class="input-group mb-3">
      <input type="text" class="form-control" readonly id="txt_output" :value="`${edmUrl}`" />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" id="copy" v-on:click="copyurl">
          複製連結
        </button>
      </div>
    </div>
  </div>

  <div class="objectItmes mx-auto px-3">
    <div class="objectName">
      <p class="mb-1">物件名稱：</p>
      <input type="text" class="form-control mb-3" v-model="title" id="txt_output" />
    </div>
    <div class="objectContent"></div>
  </div>

  <!--發送內容-->
  <div id="send_section" class="mx-auto px-3">
    <div class="form-group">
      <label>內文 *</label>
      <textarea class="form-control" v-model="content" name="content" rows="10" required></textarea>
    </div>
    <p>業務人員：{{ user?.name }}</p>
    <p>業務人員聯絡方式：{{ user?.phone }}</p>
  </div>

  <div id="home_section" class="d-flex justify-content-center w-100 px-3 mb-3">
    <button type="button" class="btn btn-primary w-100" v-on:click="shareLine()" id="send">
      分享內文
    </button>
  </div>

  <div class="remark">
    本系統當竭力確保資訊正確，但物件內容項目等資訊，各加盟店或使用者仍需最後確認資訊之正確、即時，如有錯漏或疏忽，本系統及所屬人員並不負任何法律責任。
  </div>
</template>

<script>
import edm1_1 from "@/components/emdPages/style1-1.vue";
import edm1_2 from "@/components/emdPages/style1-2.vue";
import edm1_4 from "@/components/emdPages/style1-4.vue";
import edm1_6 from "@/components/emdPages/style1-6.vue";
import edm1_8 from "@/components/emdPages/style1-8.vue";
import edm2_1 from "@/components/emdPages/style2-1.vue";
import edm2_2 from "@/components/emdPages/style2-2.vue";
import edm2_4 from "@/components/emdPages/style2-4.vue";
import edm2_6 from "@/components/emdPages/style2-6.vue";
import edm2_8 from "@/components/emdPages/style2-8.vue";
import edm3_1 from "@/components/emdPages/style3-1.vue";
import edm3_2 from "@/components/emdPages/style3-2.vue";
import edm3_4 from "@/components/emdPages/style3-4.vue";
import edm3_6 from "@/components/emdPages/style3-6.vue";
import edm3_8 from "@/components/emdPages/style3-8.vue";

// Nav
import NavBar3 from "@/components/NavBar3.vue";

export default {
  components: {
    edm1_1,
    edm1_2,
    edm1_4,
    edm1_6,
    edm1_8,
    edm2_1,
    edm2_2,
    edm2_4,
    edm2_6,
    edm2_8,
    edm3_1,
    edm3_2,
    edm3_4,
    edm3_6,
    edm3_8,

    // Nav
    NavBar3,
  },
  data() {
    let domain = window.location.origin;
    let tem = sessionStorage.getItem("temStyle");
    let edmId = sessionStorage.getItem("edmId");
    let edmCode = sessionStorage.getItem("edmCode");
    let access_token = sessionStorage.getItem("access_token");
    let id_token = sessionStorage.getItem("id_token");
    
    return {
      basePath: process.env.VUE_APP_BASEPATH,
      
      edmUrl: domain + `/edm/${tem}` + `?id=${edmId}&c=${edmCode}&openExternalBrowser=1`,
      Packages: [],
      useEDM: 0,

      // 定義傳送物件
      title: "",
      content: "",
      user: {},
      edm: {},
      lineUrl: "",
      tem: tem,
      show: true,
      imgUrl: ""
    };
  },
  mounted() {
    const mobileDevice = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone']
    let isMobileDevice = mobileDevice.some(e => navigator.userAgent.match(e));
    this.lineUrl = isMobileDevice ? 'https://line.me/R/msg/text/?' : 'https://social-plugins.line.me/lineit/share?url=&text=';
  },
  methods: {
    getImgUrl(data) {
      this.imgUrl = data;
      this.show = false;
      this.isLoading = false;
    },
    setContent(data) {
      let objectList = "";
      data.items.map((item) => {
        let text = `
案名: ${item.name ?? ""}
總價: ${Number(item.price).toLocaleString() ?? ""} 萬
坪數: ${Number(item.total_floor_space).toFixed(2)} 坪
連結: ${this.getURL(item.hUrl, item.d_type)}
      `;
        objectList += text;
      });
      this.content = `EDM連結: ${this.edmUrl}
你的專屬經紀人: ${this.user?.name ?? ""}
電話:  ${this.user.phone.slice(0, 4) + "-" + this.user.phone.slice(4, 7) + "-" + this.user.phone.slice(7)}
      `;
    },
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : '';
    },
    getURL(url, type) {
      let urlArray = url.split("/");
      let id = urlArray[urlArray.length - 1];
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(this.user.employee_id)}/${
        type == "R" ? "rent" : "buy"
      }/${id}`;
    },
    shareLine() {
      //LINE
      let h1 = this.title; //物件名稱
      let quote = encodeURIComponent(this.content); //顯示的文字內容,修改這裡

      if (h1 != undefined && h1 != "") {
        h1 = "【" + h1 + "】";
      }
      if (quote != "") {
        quote = "%0D%0A" + quote; //LINE 換行需要使用 '%0D%0A'
      }
      window.open(
        `${this.lineUrl}` +
          `${encodeURIComponent("21世紀不動產" + h1) + "%0D%0A" + quote}`,
        "sharer",
        "toolbar=100,status=100,width=800,height=800"
      );
    },
    // 複製
    copyurl() {
      var copyText = document.getElementById("txt_output");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      alert("連結已複製");
      //$('#copy_text').text('連結已複製');
      setTimeout(function () {
        //  $('#copy_text').text('複製連結');
      }, 2000);
    },
    getUserInfo(access_token, id_token) {
      const url = `${this.basePath}/api/user/info?access_token=${access_token}&id_token=${id_token}`;
      fetch(url, {
        method: "POST"
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.user = data.data.user_info;
        this.setContent(this.edm);
      })
      .catch(error => {
        console.log(error);
      })
    },
  },
  created() {
    let access_token = sessionStorage.getItem('access_token');
    let id_token = sessionStorage.getItem('id_token');

    let edmId = sessionStorage.getItem("edmId");
    let edmCode = sessionStorage.getItem("edmCode");

    let url = `${this.basePath}/api/edm?id=${edmId}&c=${edmCode}`;
    url += `&access_token=${access_token}&id_token=${id_token}`;

    fetch(url, {
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        let edmLength = result.data.edm.items.length;

        this.useEDM = edmLength;

        this.Packages = result.data;
        this.edm = result.data.edm;
        this.title = result.data.edm.items.map(item => item.name).join(', ');
        this.getUserInfo(access_token, id_token);
        // this.edmUrl += `&image=${this.Packages.edm.items[0].image.split("?")[0]}`;
      });
  },
};
</script>

<style>
.remark {
  margin: 20px;
  font-size: 0.875rem;
  color: #383735;
}
</style>

<!-- jQuery -->
<!-- function copyurl() {
  //   複製
  //   var copyText = document.getElementById("txt_output");
  //   copyText.select();
  //   copyText.setSelectionRange(0, 99999)
  //   document.execCommand("copy");
  //   alert('連結已複製')
  //   //$('#copy_text').text('連結已複製');
  //   setTimeout(function () {
  //     //  $('#copy_text').text('複製連結');
  //   }, 2000)
  // }

  // //列印
  // function varitext() {
  //   var text = document
  //   print(text)
  // }

  // $(document).ready(function () {
  //   //點擊發送
  //   $("#send").click(function () {
  //     $('#home_section').hide();
  //     $('#send_section').show();
  //   });

  //   //點擊取消
  //   $("#cancel").click(function () {
  //     $('#home_section').show();
  //     $('#send_section').hide();
  //   });
  // }); -->

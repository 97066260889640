<template>
  <NavBar3 />

  <!-- EDM預覽 -->
  <section class="section">
    <div class="container-fluid">
      <!-- EDM風格一 -->
      <div class="d-flex" :class="[isDesktop ? '' : flexColumn]">
        <div class="y-container" :class="{ width: !isDesktop }">
          <div class="y-content" v-show="show">
            <edm1_1
              v-if="`${useEDM}` == 1 && `${tem}` == 'tem1-1'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm1_2
              v-if="`${useEDM}` == 2 && `${tem}` == 'tem1-2'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm1_4
              v-if="`${useEDM}` == 4 && `${tem}` == 'tem1-4'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm1_6
              v-if="`${useEDM}` == 6 && `${tem}` == 'tem1-6'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm1_8
              v-if="`${useEDM}` == 8 && `${tem}` == 'tem1-8'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm2_1
              v-if="`${useEDM}` == 1 && `${tem}` == 'tem2-1'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm2_2
              v-if="`${useEDM}` == 2 && `${tem}` == 'tem2-2'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm2_4
              v-if="`${useEDM}` == 4 && `${tem}` == 'tem2-4'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm2_6
              v-if="`${useEDM}` == 6 && `${tem}` == 'tem2-6'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm2_8
              v-if="`${useEDM}` == 8 && `${tem}` == 'tem2-8'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm3_1
              v-if="`${useEDM}` == 1 && `${tem}` == 'tem3-1'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm3_2
              v-if="`${useEDM}` == 2 && `${tem}` == 'tem3-2'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm3_4
              v-if="`${useEDM}` == 4 && `${tem}` == 'tem3-4'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm3_6
              v-if="`${useEDM}` == 6 && `${tem}` == 'tem3-6'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
            <edm3_8
              v-if="`${useEDM}` == 8 && `${tem}` == 'tem3-8'"
              @printHTML="getPrintHTML"
              :data="Packages"
              :user="user"
              :isPrint="isPrint"
              :isDownload="isDownload"
              @downloadHTML="getDownloadHTML"
              @imgUrl="getImgUrl"
            />
          </div>
          <div class="imgContent">
            <img class="imgHTML" :src="imgUrl" />
          </div>
        </div>
        <div class="w-100" :class="[isDesktop ? pl3 : '']">
          <div class="mt-2 mb-2">
            <p class="mb-1">EDM連結</p>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                readonly
                id="txt_output"
                :value="`${edmUrl}`"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  id="copy"
                  v-on:click="copyurl"
                >
                  複製連結
                </button>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-center mt-2 pt-2 flex-column">
            <a class="btn btn-primary btn-block" href="/edm-generator/result" type="button"
              >分享EDM</a
            >
            <!-- <button class="btn btn-primary btn-block" v-on:click="print" type="button" id="demo">
              EDM列印
            </button> -->
            <button class="btn btn-primary btn-block" v-on:click="download" type="button" :disabled="isLoading">
              下載PDF
            </button>
            <button class="btn btn-dark btn-block" v-on:click="reMake" type="button">
              重新製作
            </button>
            <div class="remark">
              本系統當竭力確保資訊正確，但物件內容項目等資訊，各加盟店或使用者仍需最後確認資訊之正確、即時，如有錯漏或疏忽，本系統及所屬人員並不負任何法律責任。
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- EDM loading -->
  <div class="EDM-loader" v-show="isLoading">
    <loading v-model:active="isLoading" :loader="'dots'" :can-cancel="true" />
  </div>

  <!-- 需要被列印的頁面 -->
  <!-- <div class="needPrint d-none">
    <printEDM1_1 v-if="`${printEDM}` == 1" />
    <printEDM1_2 v-if="`${printEDM}` == 2" />
    <printEDM1_4 v-if="`${printEDM}` == 4" />
    <printEDM1_6 v-if="`${printEDM}` == 6" />
    <printEDM1_8 v-if="`${printEDM}` == 8" />
  </div> -->
</template>

<script>
import $ from "jquery";
import edm1_1 from "@/components/emdPages/style1-1.vue";
import edm1_2 from "@/components/emdPages/style1-2.vue";
import edm1_4 from "@/components/emdPages/style1-4.vue";
import edm1_6 from "@/components/emdPages/style1-6.vue";
import edm1_8 from "@/components/emdPages/style1-8.vue";
import edm2_1 from "@/components/emdPages/style2-1.vue";
import edm2_2 from "@/components/emdPages/style2-2.vue";
import edm2_4 from "@/components/emdPages/style2-4.vue";
import edm2_6 from "@/components/emdPages/style2-6.vue";
import edm2_8 from "@/components/emdPages/style2-8.vue";
import edm3_1 from "@/components/emdPages/style3-1.vue";
import edm3_2 from "@/components/emdPages/style3-2.vue";
import edm3_4 from "@/components/emdPages/style3-4.vue";
import edm3_6 from "@/components/emdPages/style3-6.vue";
import edm3_8 from "@/components/emdPages/style3-8.vue";

// 引入列印頁面
// import printEDM1_1 from "@/components/printPages/style1-1.vue";
// import printEDM1_2 from "@/components/printPages/style1-2.vue";
// import printEDM1_4 from "@/components/printPages/style1-4.vue";
// import printEDM1_6 from "@/components/printPages/style1-6.vue";
// import printEDM1_8 from "@/components/printPages/style1-8.vue";

// Nav
import NavBar3 from "@/components/NavBar3.vue";

import html2pdf from "html2pdf.js";
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";

import Loading from "vue-loading-overlay";

export default {
  components: {
    edm1_1,
    edm1_2,
    edm1_4,
    edm1_6,
    edm1_8,
    edm2_1,
    edm2_2,
    edm2_4,
    edm2_6,
    edm2_8,
    edm3_1,
    edm3_2,
    edm3_4,
    edm3_6,
    edm3_8,

    // 需要列印的頁面
    // printEDM1_1,
    // printEDM1_2,
    // printEDM1_4,
    // printEDM1_6,
    // printEDM1_8,

    // Nav
    NavBar3,
    html2pdf,

    Loading,
  },
  data() {
    let domain = window.location.origin;
    let tem = sessionStorage.getItem("temStyle");
    let edmId = sessionStorage.getItem("edmId");
    let edmCode = sessionStorage.getItem("edmCode");
    let access_token = sessionStorage.getItem("access_token");
    let id_token = sessionStorage.getItem("id_token");

    return {
      basePath: process.env.VUE_APP_BASEPATH,

      edmUrl: domain + `/edm/${tem}` + `?id=${edmId}&c=${edmCode}&openExternalBrowser=1`,
      Packages: [],
      useEDM: 0,
      printEDM: 0,
      windowWidth: window.innerWidth,
      flexColumn: "flex-column",
      pl3: "pl-3",
      user: {},
      isPrint: false,
      isDownload: false,
      isLoading: true,
      zoom: tem == "tem1-2" ? 0.9 : tem == "tem1-1" ? 0.8 : tem == "tem1-8" ? 0.8 : 0.7,
      tem: tem,
      show: true,
      imgUrl: "",
    };
  },
  computed: {
    isDesktop() {
      return this.windowWidth >= 900;
    },
  },
  methods: {
    print() {
      this.isPrint = true;
    },

    // 複製URL
    copyurl() {
      var copyText = document.getElementById("txt_output");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      alert("連結已複製");
      //$('#copy_text').text('連結已複製');
      setTimeout(function () {
        //  $('#copy_text').text('複製連結');
      }, 2000);
    },

    // 列印
    getPrintHTML(data, QRcode) {
      let printHTML = QRcode
        ? data.replace(
            `<canvas id="QRcode" width="200" height="200" style="width: 100px; height: 100px;"></canvas>`,
            `<img src="${QRcode}" alt="" />`
          )
        : data;

      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=1240,height=986,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <style type="text/css">
              body {
                zoom: ${this.zoom};
              }
              .section {
                position: relative;
                padding: 1.5rem 0;
                display: block;
              }
              .container-fluid {
                width: 100%;
                margin-right: auto;
                margin-left: auto;
              }
              .edm-style-1 {
                font-weight: 500;
                background-color: #E3E3E3;
              }
              .edm-style-1 .image {
                position: relative;
                padding-bottom: 59.5%;
              }
              .edm-style-1 .image .cover {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
              }
              .edm-style-1 .image .logo {
                top: 13.6%;
                left: 0;
                position: absolute;
                width: 38.3%;
              }
              .edm-style-1 .image .logo .img-fluid {
                max-width: 100%;
                height: auto;
              }
              .edm-style-1 .header {
                position: relative;
                width: 80.8%;
                margin: -6% auto 2%;
              }
              .edm-style-1 .header .slogan {
                top: 0;
                left: 0;
                position: absolute;
                padding: 0.9% 3%;
                color: #fff;
                background-color: #C4B78A;
                transform: translate(13%, -50%);
                font-size: 17px !important;
              }
              .edm-style-1 .header .title {
                padding: 5%;
                color: #C4B78A;
                font-size: 1rem;
                font-weight: bold;
                background-color: rgba(37, 37, 39, 0.9);
                text-align: center;
              }
              .edm-style-1 .body {
                position: relative;
                display: flex;
                flex-wrap: nowrap;
                width: 80.8%;
                margin: 0 auto 3%;
                color: #5A5A5A;
              }
              .edm-style-1 .body .left {
                width: 53.5%;
                padding-top: 3.5%;
                padding-bottom: 3.5%;
                margin-right: 2.5%;
                background: #fff url(http://localhost:8080/img/bg.a7c7b071.png) bottom right no-repeat;
                background-size: contain !important;;
              }
              .edm-style-1 .body .box {
                padding: 5%;
              }
              .edm-style-1 .body .left .title {
                display: block;
                margin-bottom: 10%;
                padding-bottom: 4%;
                color: #5A5A5A;
                font-size: 0.55rem;
                border-bottom: 0.125rem solid #5A5A5A;
                text-align: center;
              }
              .edm-style-1 .body .left .list li {
                margin-bottom: 5%;
                font-size: 14px;
              }
              .edm-style-1 .body .left .list li .label {
                display: flex;
                align-items: center;
                margin-bottom: 1%;
                color: #C7BA8D;
                font-size: 0.75rem;
              }
              .edm-style-1 .body .right {
                width: 44%;
                background-color: #fff;
              }
              .edm-style-1 .body .right .total-box {
                background-color: #C4B78A;
                color: #252527;
                text-align: center;
              }
              .edm-style-1 .body .right .box {
                padding-left: 6%;
                padding-right: 6%;
              }
              .edm-style-1 .body .right .total-box .total {
                font-size: 0.5rem;
              }
              .edm-style-1 .body .right .total-box .total b {
                font-size: 1.5rem;
              }
              .edm-style-1 .body .right .space {
                height: 2.8%;
                background-color: #E3E3E3;
              }
              .edm-style-1 .body .right .sales-box {
                display: flex;
                align-items: center;
                padding-top: 15%;
                padding-bottom: 15%;
                color: #252527;
                position: relative;
              }
              .edm-style-1 .body .right .sales-box img {
                position: absolute;
                right: 8px;
                width: 65px !important;
                height: 65px !important;
                top: 8px;
              }
              .edm-style-1 .body .right .box {
                padding-left: 6%;
                padding-right: 6%;
              }
              .edm-style-1 .body .right .sales-box .avatar {
                position: relative;
                width: 4rem;
                padding-bottom: 120%;
              }
              .edm-style-1 .body .right .sales-box .avatar img {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
              }
              .edm-style-1 .body .right .sales-box .title {
                margin-left: 0.25rem;
              }
              .edm-style-1 .body .right .sales-box .title .job-title {
                font-size: 0.15rem;
                color: #C7BB93;
              }
              .edm-style-1 .body .right .sales-box .title .name {
                font-size: 14px;
              }
              .edm-style-1 .body .right .store-title {
                padding: 5%;
                font-size: 0.75rem;
                text-align: center;
                background-color: #E9E4D3;
              }
              .edm-style-1 .body .right .store-box {
                padding-top: 8%;
                padding-bottom: 8%;
              }
              .edm-style-1 .body .right .box {
                padding-left: 6%;
                padding-right: 6%;
              }
              .edm-style-1 .body .right .store-box .list li {
                font-size: 0.75rem;
              }
              .edm-style-1 .body .right .store-box .list li .label {
                display: flex;
                align-items: center;
                margin-bottom: 1%;
                color: #C7BA8D;
                font-size: 0.75rem;
              }
              .headSection620 {
                background-color: #E3E3E3;
                padding-top: 48px;
              }
              .headSection620 .left-box {
                min-width: 240px;
                margin-right: 10px;
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
              }
              .headSection620 .left-box .logoImage img {
                width: 100%;
              }
              .headSection620 .left-box .storeName {
                margin-top: 12.5px;
              }
              .headSection620 .left-box .storeName .box-content {
                background-color: #E9E4D3;
                color: #5A5A5A;
                font-size: 12px;
                line-height: 17px;
                font-weight: 900;
                padding: 8px 11px;
              }
              .headSection620 .left-box .storeName .box-content span:nth-child(1) {
                margin-right: 8px;
              }
              .headSection620 .right-box {
                flex: 0 0 58.333333%;
                max-width: 58.333333%;
              }
              .headSection620 .right-box .sellerInfo {
                background-color: rgba(255, 255, 255, 0.8);
                padding: 19.5px 0px 11.5px 17.5px;
              }
              .headSection620 .right-box .sellerInfo img {
                width: 65px !important;
                height: 65px !important;
                margin: 0 10px;
              }
              .headSection620 .right-box .sellerInfo .leftSection .sellerImage {
                max-width: 60.25px;
              }
              .headSection620 .right-box .sellerInfo .middleSection {
                margin-left: 12.5px;
                height: auto;
              }
              .headSection620 .right-box .sellerInfo .middleSection .sellerTitle span {
                color: #C7BB93;
              }
              .headSection620 .right-box .sellerInfo .middleSection .name span,
              .headSection620 .right-box .sellerInfo .middleSection .phone span,
               {
                color: #000000;
                font-size: 12px;
              }
              .headSection620 .right-box .sellerInfo .rightSection {
                margin-left: 23.5px;
              }
              .headSection620 .right-box .sellerInfo .rightSection .componyName {
                margin-bottom: 5px;
              }
              .headSection620 .right-box .sellerInfo .rightSection .componyName p:nth-child(1) {
                margin-bottom: 6px;
                color: #C7BA8D;
              }
              .headSection620 .right-box .sellerInfo .rightSection .componyName p {
                color: #5A5A5A;
                line-height: 14px;
              }
              .headSection620 .right-box .sellerInfo .rightSection .componyAddress p:nth-child(1) {
                margin-bottom: 6px;
                color: #C7BA8D;
              }
              .headSection620 .right-box .sellerInfo .rightSection .componyAddress p {
                color: #5A5A5A;
                line-height: 14px;
              }
              .edm-style-1-2 .body {
                padding: 0 40px;
                padding-bottom: 1.5rem!important;
                padding-top: 1.5rem!important;
              }
              .edm-style-1-2 .body .mainContent {
                padding-top: 15px;
              }
              .edm-style-1-2 .objectCard_m {
                max-width: 300px;
                min-width: 300px;
              }
              .edm-style-1-2 .objectCard_m .image {
                width: 100%;
                position: relative;
              }
              .edm-style-1-2 .objectCard_m .image img {
                width: 100%;
                height: 100%;
                max-height: 210px;
                min-height: 210px;
                object-fit: cover;
              }
              .edm-style-1-2 .objectCard_m .image .slogan {
                position: absolute;
                transform: translate(0, -100%);
                font-size: 8px !important;
                background-color: rgba(196, 183, 138, 0.89);
                color: #fff;
                padding-bottom: 0.5rem!important;
                padding-top: 0.5rem!important;
              }
              .edm-style-1-2 .objectCard_m .title {
                min-height: 71px;
                color: #C4B78A;
                font-weight: bold;
                background-color: rgba(37, 37, 39, 0.9);
                text-align: center;
              }
              .edm-style-1-2 .objectCard_m .title span {
                font-size: 14px;
              }
              .edm-style-1-2 .objectCard_m .total-box .total {
                font-size: 8px;
              }
              .edm-style-1-2 .objectCard_m .baseInfo {
                padding: 10px 10px 12px;
                background-size: 70% !important;;
                background: #fff url(http://localhost:8080/img/bg.a7c7b071.png) bottom right no-repeat;
              }
              .edm-style-1-2 .objectCard_m .baseInfo .title {
                min-height: unset;
                font-size: 16px;
                display: block;
                color: #5A5A5A;
                border-bottom: 4px solid #5A5A5A;
                text-align: center;
                background-color: #fff;
                margin-bottom: 1rem!important;
              }
              .edm-style-1-2 .objectCard_m .list li {
                font-size: 16px;
                font-weight: 800;
                color: #5A5A5A;
              }
              .edm-style-1-2 .objectCard_m .list li .label {
                margin-top: 6px;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                color: #C7BA8D;
              }
              .edm-style-1-4 .mainContent {
                padding-top: 30.25px;
              }
              .edm-style-1-4 .mainContent .objectCard_m {
                margin-bottom: 0.5rem!important;
              }
              .edm-style-1-4 .mainContent .objectCard_m .objectLeft {
                max-width: 40%;
                min-width: 40%;
                height: 100%;
                position: relative;
                max-height: 150px;
                min-height: 150px;
              }
              .edm-style-1-4 .mainContent .objectCard_m .objectLeft .image {
                width: 100%;
                max-height: 150px;
                min-height: 150px;
              }
              .edm-style-1-4 .mainContent .objectCard_m .objectLeft .image img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                max-height: 150px;
                min-height: 150px;
              }
              .edm-style-1-4 .mainContent .objectCard_m .objectLeft .title {
                width: 100%;
                height: 20%;
                min-height: 60px;
                position: relative;
                color: #C4B78A;
                font-weight: bold;
                background-color: rgba(37, 37, 39, 0.9);
                text-align: center;
              } 
              .edm-style-1-4 .mainContent .objectCard_m .objectLeft .title span {
                font-size: 16px;
              }
              .edm-style-1-4 .mainContent .objectCard_m .objectLeft .title .objectNo {
                top: -25px;
                left: 0px;
                position: absolute;
                height: 25px;
                width: 25px;
                font-size: 16px;
                font-weight: 500;
                background-color: #5A5A5A;
              }
              .edm-style-1-4 .mainContent .objectCard_m .objectRight {
                max-width: 60%;
                min-width: 60%;
              } 
              .edm-style-1-4 .mainContent .objectCard_m .objectRight .baseInfo {
                padding: 15px 0px 0px 15px;
                height: 80%;
                background-size: 40% !important;;
                max-height: 150px;
                min-height: 150px;
                margin-bottom: -15px;
                background: #fff url(http://localhost:8080/img/bg.a7c7b071.png) bottom right no-repeat;
              }
              .edm-style-1-4 .mainContent .objectCard_m .objectRight .baseInfo .list div li .label {
                margin-top: 6px;
                font-size: 9px;
                font-weight: 600;
              }
              .edm-style-1-4 .mainContent .objectCard_m .objectRight .total-box {
                height: 20%;
                min-height: 60px;
              }
              .edm-style-1-4 .mainContent .objectCard_m .objectRight .total-box .total {
                font-size: 14px;
              }
              .edm-style-1-6 .mainContent {
                padding-top: 30.25px;
              }
              .edm-style-1-6 .mainContent .objectCard {
                height: 100%;
                margin-bottom: 1.5rem!important;
              }
              .edm-style-1-6 .mainContent .objectCard .image {
                min-width: 100%;
                position: relative;
                height: 35%;
                max-height: 125px;
                min-height: 125px;
              }
              .edm-style-1-6 .mainContent .objectCard .image img {
                max-height: 125px;
                min-height: 125px;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .edm-style-1-6 .mainContent .objectCard .title {
                min-height: 64px;
                position: relative;
                color: #C4B78A;
                font-weight: bold;
                background-color: rgba(37, 37, 39, 0.9);
                text-align: center;
              }
              .edm-style-1-6 .mainContent .objectCard .title span {
                font-size: 15px;
              }
              .edm-style-1-6 .mainContent .objectCard .title .objectNo {
                top: -25px;
                left: 0px;
                position: absolute;
                height: 25px;
                width: 25px;
                font-size: 16px;
                font-weight: 500;
                background-color: #5A5A5A;
              }
              .edm-style-1-6 .mainContent .objectCard .total-box {
                background-color: #C4B78A;
                color: #252527;
                text-align: center;
                padding-bottom: 0.5rem!important;
                padding-top: 0.5rem!important;
              }
              .edm-style-1-6 .mainContent .objectCard .total-box .total {
                font-size: 10px;
              }
              .edm-style-1-6 .mainContent .objectCard .total-box .total b {
                font-size: 18px;
              }
              .edm-style-1-6 .mainContent .objectCard .baseInfo {
                padding: 15px 8px 15px;
                background-size: 50% !important;;
                min-height: 220px;
                background: #fff url(http://localhost:8080/img/bg.a7c7b071.png) bottom right no-repeat;
              }
              .edm-style-1-6 .mainContent .objectCard .baseInfo .list div li {
                font-size: 9px !important;
                font-weight: 800;
                color: #5A5A5A;
              }
              .edm-style-1-6 .mainContent .objectCard .baseInfo .list div li .label {
                margin-top: 6px;
                font-size: 8px;
                font-weight: 600;
              }
              .edm-style-1-8 .body {
                padding: 0 15px;
              }
              .edm-style-1-8 .mainContent {
                padding: 30.25px 0px 27.25px;
              }
              .edm-style-1-8 .mainContent .objectCard_m {
                width: 50%;
                height: 100%;
                padding-bottom: 20px;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectLeft {
                padding-left: 0.25rem!important;
                max-width: 50%;
                min-width: 50%;
                position: relative;
                min-height: 200px;
                max-height: 200px;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectLeft .image {
                width: 100%;
                max-height: 120px;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectLeft .image img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                min-height: 104px;
                max-height: 104px;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectLeft .title {
                width: 100%;
                height: auto;
                position: relative;
                min-height: 65px;
                max-height: 65px;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectLeft .title span {
                font-size: 8px;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectLeft .title .objectNo {
                top: -25px;
                left: 0px;
                position: absolute;
                height: 25px;
                width: 25px;
                font-size: 16px;
                font-weight: 500;
                background-color: #5A5A5A;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectLeft .total-box {
                min-height: 31px;
                max-height: 31px;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectLeft .total-box .total {
                font-size: 8px;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectRight {
                width: 100%;
                height: 100%;
                top: 0px;
                transform: translateX(100%);
                position: absolute;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectRight .baseInfo {
                width: 100%;
                padding: 15px 0px 0px;
                background-size: 45% !important;
                height: 100%;
                margin-left: -7px;
                min-height: 185px;
                max-height: 185px;
                background: #fff url(http://localhost:8080/img/bg.a7c7b071.png) bottom right no-repeat;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectRight .baseInfo .list div li {
                font-size: 10px;
                padding-left: 5px;
              }
              .edm-style-1-8 .mainContent .objectCard_m .objectRight .baseInfo .list div li .label {
                margin-top: 6px;
                font-size: 8px;
                font-weight: 600;
              }
              .objectCard_m .title {
                color: #C4B78A;
                font-weight: bold;
                background-color: rgba(37, 37, 39, 0.9);
                text-align: center;
              }
              .objectCard_m .total-box {
                background-color: #C4B78A;
                color: #252527;
                text-align: center;
              }
              .list {
                list-style: none;
                margin: 0;
                padding: 0;
              }
              .w-100 {
                width: 100%!important;
              }
              img {
                vertical-align: middle;
                border-style: none;
              }
              .d-flex {
                display: flex!important;
              }
              .col-4 {
                flex: 0 0 30%;
                max-width: 30%;
              }
              .col-5 {
                flex: 0 0 41.666667%;
                max-width: 41.666667%;
              }
              .col-6 {
                flex: 0 0 50%;
                max-width: 50%;
              }
              .col-12 {
                flex: 0 0 100%;
                max-width: 100%;
              }
              .align-items-center {
                align-items: center!important;
              }
              .align-items-start {
                align-items: flex-start!important;
              }
              .p-0 {
                padding: 0!important;
              }
              .m-0 {
                margin: 0!important;
              }
              .px-0 {
                padding-left: 0!important;
                padding-right: 0!important;
              }
              .py-0 {
                padding-bottom: 0!important;
                padding-top: 0!important;
              }
              .px-1 {
                padding-left: 0.25rem!important;
                padding-right: 0.25rem!important;

              }
              .px-4 {
                padding-left: 1.5rem!important;
                padding-right: 1.5rem!important;
              }
              .py-3 {
                padding-bottom: 1rem!important;
                padding-top: 1rem!important;
              }
              .py-4 {
                padding-bottom: 1.5rem!important;
                padding-top: 1.5rem!important;
              }
              .px-30 {
                padding: 0px 30px;
              }
              .justify-content-center {
                justify-content: center!important;
              }
              .justify-content-end {
                justify-content: flex-end!important;
              }
              .justify-content-between {
                justify-content: space-between!important;
              }
              .flex-column {
                flex-direction: column!important;
              }
              .default-bg {
                background-color: #E3E3E3;
              }
              .text-danger {
                color: #F24E4E !important;
              }
              .flex-wrap {
                flex-wrap: wrap!important;
              }
            </style>
          </head>
          <body>
             ${printHTML}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
      }, 2000);

      // WinPrint.close();
      this.isPrint = false;
    },
    // 重新製作
    reMake() {
      let object = JSON.parse(localStorage.getItem("edm_generator_filter"));
      const params = {
        keyword: object.keyword,
        address: object.address,
        store_name: object.store_name,
        county: object.county,
        district: object.district,
        floor: object.floor,
        total_floor: object.total_floor,
        age: object.age,
        min_floor_space: object.min_floor_space,
        max_floor_space: object.max_floor_space,
        min_total_floor_space: object.min_total_floor_space,
        max_total_floor_space: object.max_total_floor_space,
        min_price: object.min_price,
        max_price: object.max_price,
        date_range: object.date_range,
        type: object.type,
        status: object.status,
        d_type: object.d_type,
        min_structure_room: object.min_structure_room,
        min_structure_hall: object.min_structure_hall,
        min_structure_bath: object.min_structure_bath,
        hJointSale: object.hJointSale,
      };
      this.$router.push({ path: "/edm-generator/index", query: params });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getUserInfo(access_token, id_token) {
      this.isLoading = true;
      const url = `${this.basePath}/api/user/info?access_token=${access_token}&id_token=${id_token}`;
      fetch(url, {
        method: "POST",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          this.user = data?.data?.user_info;
        })
        .catch((error) => {
        });
    },
    download() {
      this.isDownload = true;
    },
    getDownloadHTML(filename, edm) {
      let today = new Date();
      let month = (today.getMonth() + 1).toString().padStart(2, '0'); // 取得當前月份，並確保為兩位數格式
      let day = today.getDate().toString().padStart(2, '0'); // 取得當前日期，並確保為兩位數格式
      let formattedDate = month + day; // 格式化後的日期，例如：0705

      let PDF = new JsPDF("p", "mm", "a4", false);
      PDF.addImage(this.imgUrl, "JPEG", 0, 0, 210, 297);
      PDF.save(`${filename}${formattedDate}.pdf`);
      this.isDownload = false;
    },
    getImgUrl(data) {
      this.imgUrl = data;
      this.show = false;
      this.isLoading = false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    this.isLoading = true;
    let access_token = sessionStorage.getItem("access_token");
    let id_token = sessionStorage.getItem("id_token");
    this.getUserInfo(access_token, id_token);
    // // 頁面預覽獲取資料
    let edmId = sessionStorage.getItem("edmId");
    let edmCode = sessionStorage.getItem("edmCode");

    let url = `${this.basePath}/api/edm?id=${edmId}&c=${edmCode}`;
    url += `&access_token=${access_token}&id_token=${id_token}`;

    fetch(url, {
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then((formData) => {
        let edmLength = formData.data.edm.items.length;

        this.useEDM = edmLength;
        this.printEDM = edmLength;

        this.Packages = formData.data;
        // this.edmUrl += `&image=${this.Packages.edm.items[0].image.split("?")[0]}`;
      });
  },
};
</script>
<style lang="scss">
.width {
  width: 100%;
}
.edm1240 .edm-style-1 .body .right .sales-box canvas {
  width: 100px !important;
  height: 100px !important;
  right: 40px !important;
  top: 40px !important;
}
.EDM-loader {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background-color: rgba(56, 55, 53, 0.7);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remark {
  margin-top: 20px;
  font-size: 0.875rem;
  color: #383735;
}
@media (max-width:900px) {
  .imgContent {
    width: 100% !important;
  }
}
.imgContent {
  width: 620px;
}
.imgHTML {
  width: 100%;
}
</style>

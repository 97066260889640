<template>
  <div class="contact-wrap">
      <div class="info-wrap">
          <div class="img-wrap">
              <img :src="user.picture_base64 ? user.picture_base64 : userImage" alt="專業經理人">
          </div>
          <div class="text-wrap">
              <span class="info-title">專業經理人</span>
              <p class="info-name">{{ user.name }}</p>
              <p class="info-tel">{{ user.phone }}</p>
          </div>
      </div>
      <div class="info-wrap">
          <div class="img-wrap">
              <qrcode-vue id="QRcode" :value="getQRcode(user.employee_id)" style="width: 95px; height: 95px;"></qrcode-vue>
          </div>
          <div class="text-wrap">
              <span class="info-title">{{ user.company_name }}</span>
              <span class="info-title">地址</span>
              <p class="info-address">{{ user.store_address }}</p>
          </div>
      </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import QrcodeVue from 'qrcode.vue';
import imageToBase64 from "image-to-base64/browser";

export default {
  components: {
    QrcodeVue
  },
  props: ['user'],
  data() {
    return {
      basePath: process.env.VUE_APP_BASEPATH,
      Packages: [],
    }
  },
  async mounted() {
    this.userImage = await imageToBase64("https://www.century21.com.tw/store/images/jobnewsman.png");
  },
  methods: {
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : '';
    },
    getQRcode(employee_id) {
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(employee_id)}`;
    },
  }
}
</script>


<template>
  <NavBar2 type="member" />

  <div class="d-none">
    <!-- {{ Packages.items[0].image }}
    {{ Packages.items[0].id }}
    {{ Packages.items[0].name }} -->

    <!-- {{ Packages[0] }} -->

    <!-- <div v-for="items in Packages.items" > {{items.id}}</div> -->
  </div>

  <section class="section">
    <div class="container">
      <!-- <a href="{{ route('front.estate-competitor.filter') }}" class="btn btn-primary">重新篩選</a> -->
      <!-- <h5>同業物件</h5> -->

      <!--呈現的條件-->
      <small class="filter-text d-none">
        <!-- {{ $d_param_key }}:{{ $d_param_value }}<br>
        <span v-if="keyword">{{ keyword }}</span>
        <span v-if="county">{{ county }}</span>
        <span v-if="district">{{ district }}</span>
        <span v-if="floor">{{ floor }}</span>
        <span v-if="structure">{{ structure }}</span>
        <span v-if="age">{{ age }}</span>
        <span v-if="brand">{{ brand }}</span>
        <span v-if="max_floor_space">{{ max_floor_space }}</span>
        <span v-if="max_price">{{ max_price }}</span>
        <span v-if="max_total_floor_space">{{ max_total_floor_space }}</span>
        <span v-if="min_floor_space">{{ min_floor_space }}</span>
        <span v-if="min_price">{{ min_price }}</span>
        <span v-if="min_total_floor_space">{{ min_total_floor_space }}</span>
        <span v-if="date_range">{{ date_range }}</span>
        <span v-if="status">{{ status }}</span>
        <span v-if="type">{{ type }}</span> -->
      </small>
      <form id="Form" action="">
        <!--要帶入的條件參數-->
        <input type="hidden" name="" value="" />
        <div class="form-row mb-4">
          <div class="col-5">
            <!-- <label>排序方式</label> -->
            <select class="custom-select" @change="sortByOption($event)" name="sort" id="sort">
              <option value="">不限</option>
              <option value="hPrice_num-desc">總價高到低排序</option>
              <option value="hPrice_num-asc">總價低到高排序</option>
              <option value="hPin-desc">坪數大到小排序</option>
              <option value="hPin-asc">坪數小到大排序</option>
              <option value="hAge-desc">屋齡高到低排序</option>
              <option value="hAge-asc">屋齡低到高排序</option>
              <option value="post_at-desc" selected="selected">上架日期新到舊</option>
              <option value="post_at-asc">上架日期舊到新</option>
            </select>
          </div>
          <div class="col-6 d-flex">
            <small class="text-muted my-auto"
              >共<span class="text-danger"> {{ objectLength }} </span>筆資料</small
            >
          </div>
        </div>
      </form>

      <!-- loding..... -->
      <!-- <loading :active.sync="LoaderVisiable" :can-cancel="true"></loading> -->
      <div class="loding d-flex justify-content-center">
        <loading v-model:active="isLoading" :loader="'dots'" :can-cancel="true" />
      </div>

      <div class="empty-list" v-if="Packages?.length == 0 && isLoading == false">
        <span>目前沒有物件符合您的需求，請重新搜尋</span>
      </div>

      <!-- results -->
      <ul class="estate-list" v-if="Packages.length > 0">
        <li v-for="(items, index) in Packages" class="estate-list-item">
          <div class="form-row">
            <div class="col-8">
              <div class="form-row top-row mb-2">
                <!-- 案名 -->
                <div class="col">
                  <div class="name font-weight-bold" @click="objectLink(items.hUrl)">
                    {{ items.name }}
                  </div>
                </div>
              </div>

              <!--地址、相關資訊 -->
              <div class="estate-item">
                <span class="address" :id="'getToChange' + `${index}`">
                  {{ items.county }}{{ items.district }}{{ items.address }}
                </span><br />
                <!-- <span class="address" v-if="(`${items.newAddress}`) != ''"> -->
                <!-- {{ items.newAddress }} -->
                <!-- </span> -->
                <small
                  >坪數{{
                    Number(
                      items.type == "土地" ? items?.hLand_num : items.total_floor_space
                    ).toFixed(2)
                  }}｜{{ items.structure_room > 0 ? items.structure_room : '-' }}房{{ items.structure_hall > 0 ? items.structure_hall : '-' }}廳
                  {{ items.structure_bath > 0 ? items.structure_bath : '-' }}衛｜{{ items.age > 0 ? items.age : '-' }}年｜{{
                    getFloor(items.floor, items.total_floor)
                  }}｜{{ items.type == "" ? "無類型資訊" : items.type }}</small
                >
              </div>

              <!-- 案件來源、上架時間 -->
              <div class="ObjectInfo d-flex my-2">
                <div class="brand">
                  <small class="date">來源：{{ items.brand }}</small>
                </div>
                <div class="createTime ml-3">
                  <small class="date">上架時間：{{ dateFormat(items.post_at) }}</small>
                </div>
              </div>
              <button class="btn btn-primary btn-sm" type="button" @click="goObject(items)">
                分享案件
              </button>
            </div>

            <div class="col-4">
              <div class="image">
                <img :src="`${items.image}`" class="estate-item" data-id="" />
              </div>
              <div class="text-right mt-1">
                <span class="price">{{ items.price.toLocaleString() }}</span
                ><small>萬</small>
              </div>
              <div class="col-auto pr-0 d-block favorite-button">
                <button
                  v-if="isFavorite(items.source_number)"
                  v-on:click="changeFavorite(`${items.source_number}`)"
                  class="btn btn-sm favorite"
                  :id="'favorite' + `${items.source_number}`"
                  data-type="estate_competitor"
                >
                  <i class="fa-solid fa-heart" style="color: #c0ac79"></i>
                </button>

                <button
                  v-else-if="!isFavorite(items.source_number)"
                  class="btn btn-sm favorite"
                  :id="'favorite' + `${items.source_number}`"
                  v-on:click="changeFavorite(`${items.source_number}`)"
                  data-type="estate_competitor"
                >
                  <i class="fa-regular fa-heart" style="color: #c0ac79"></i>
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
  <!-- 頁數選單 -->
  <div v-bind:class="{ hiddenElement: isHidden }" class="d-flex justify-content-center mb-5">
    <paginate
      :page-count="`${MaxPage}`"
      :page-range="3"
      :margin-pages="2"
      :click-handler="clickCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'page-item'"
      v-model="page"
    >
    </paginate>
  </div>
  <!-- {{ PerPage }} -->
</template>

<script>
import $ from "jquery";
import NavBar2 from "@/components/NavBar2.vue";
import { useRoute } from "vue-router";

// 頁面按鈕
import Paginate from "vuejs-paginate-next";

// 讀取圖示
import Loading from "vue-loading-overlay";
// import {useLoading} from 'vue-loading-overlay'

export default {
  name: "member-conpetitor",
  components: {
    NavBar2,
    paginate: Paginate,

    // Loding
    Loading,
  },
  data() {
    return {
      isHidden: true,

      page: "",
      Packages: [],
      PerPage: 1,
      PageSize: 50,
      objectLength: 0,
      // newAddress: '',

      // Loding....
      // LoaderVisiable: false
      loader: "dots",
      query: {},
      favoriteList: [],
      sort: ['post_at', 'desc'],
      basePath: process.env.VUE_APP_BASEPATH
    };
  },
  computed: {
    // 頁數
    MaxPage() {
      var MaxPage = Math.ceil(this.objectLength / this.PageSize);

      if (MaxPage == 0) {
        var MaxPage = 1;
      } else {
        var MaxPage = Math.ceil(this.objectLength / this.PageSize);
      }

      return MaxPage;
    },
  },
  methods: {
    sortByOption(event) {
      this.sort = event.target.value.split("-");
      this.page = 1;
      this.clickCallback(1);
    },

    // 頁面按鈕：更換頁面重新取得下一頁資料
    clickCallback(pageNum) {

      if (this.favoriteList.length > 0) {
        // 獲取資料
        let token = sessionStorage.getItem("token");
        let url = `${process.env.VUE_APP_WEBCRAWLER}/get_datas`;

        this.isLoading = true;

        // Fetch取得資料
        fetch(url, {
          headers: {
            // token: token,
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",

          // 搜尋資料請求
          body: JSON.stringify({
            max_count: 50,
            page_n: 1,
            hNo: this.favoriteList,
            sort_field: this.sort[0],
            sort_type: this.sort[1],
          }),
        })
          .then((res) => {
            this.isLoading = false;
            return res.json();
          })
          .then((formData) => {
            this.isHidden = false;

            this.Packages = formData.results;
          });
      }
    },

    // 收藏按鈕功能
    changeFavorite(id) {
      let type = "estate_competitor";

      let token = sessionStorage.getItem("token");
      let url = `${this.basePath}/api/favorite/toggle`;

      let access_token = sessionStorage.getItem("access_token");
      let id_token = sessionStorage.getItem("id_token");

      url += `?access_token=${access_token}&id_token=${id_token}`;

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          token: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "post",

        body: JSON.stringify({
          id: id,
          type: type,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          this.getFavoriteList();
          let favorite = this.isFavorite(id);
          let favoriteID = document.getElementById("favorite" + id);

          if (!favorite) {
            favoriteID.innerHTML = "";
            favoriteID.innerHTML += `<i class="fa-solid fa-heart" style="color:#C0AC79;"></i>`;
            alert("您已收藏此物件！");
          } else if (favorite) {
            favoriteID.innerHTML = "";
            favoriteID.innerHTML += ` <i class="fa-regular fa-heart" style="color:#C0AC79;"></i>`;
            alert("您已移除收藏此物件！");
          }
        });
    },

    dateFormat(data) {
      let date = new Date(data);
      let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      return `${date.getFullYear()}/${month}/${day}`;
    },

    objectLink(url) {
      window.open(url, "_black");
    },

    goObject(item) {
      const params = {
        name: item.name ?? "",
        type: item.type ?? "",
        structure_bath: item.structure_bath ?? 0,
        structure_hall: item.structure_hall ?? 0,
        structure_room: item.structure_room ?? 0,
        floor_space: item.total_floor_space ?? 0,
        price: item.price ?? 0,
        hUrl: item.hUrl ?? "",
        hSellerName: item.hSellerName ?? "",
        hSeller: item.hSeller ?? "",
        image: item.image ?? ""
      };
      this.$router.push({ path: "/member/estate-competitor/object", query: params });
    },

    getFavoriteList() {
      // 獲取資料
      let token = sessionStorage.getItem("token");
      let url = `${this.basePath}/api/favorite/ids?type=estate_competitor`;

      let access_token = sessionStorage.getItem("access_token");
      let id_token = sessionStorage.getItem("id_token");

      url += `&access_token=${access_token}&id_token=${id_token}`;

      this.isLoading = true;
      // Fetch取得資料
      fetch(url, {
        headers: {
          // token: token,
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          // 先取50頁
          this.favoriteList = res.data.ids;

          if (this.favoriteList.length > 0) {
            let url = `${process.env.VUE_APP_WEBCRAWLER}/get_datas`;

            // Fetch取得資料
            fetch(url, {
              headers: {
                // token: token,
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              method: "POST",

              // 搜尋資料請求
              body: JSON.stringify({
                max_count: this.favoriteList.length,
                page_n: 1,
                hNo: this.favoriteList,
                sort_field: this.sort[0],
                sort_type: this.sort[1],
              }),
            })
              .then((res) => {
                this.isLoading = false;
                return res.json();
              })
              .then((formData) => {
                this.isHidden = false;

                this.Packages = formData.results;
                this.objectLength = this.favoriteList.length;
              })
              .catch(error => {
                console.log(error);
                this.isLoading = false;
                this.isHidden = false;
              })
          } else {
            this.isLoading = false;
            this.isHidden = false;

            this.Packages = [];
            this.objectLength = 0;
          }
        }).catch(error => {
          console.log(error);
          this.isLoading = false;
          this.isHidden = false;
        })
    },

    isFavorite(id) {
      let idString = id.toString();
      return this.favoriteList.includes(idString);
    },

    getFloor(floor, total_floor) {
      return (floor == 0 && total_floor == 0) || !floor || !total_floor ? "未提供資訊" : 
      floor == 0 ? "整棟" : floor == -1 ? "地下室" : `${floor}/${total_floor}樓`;
    }
  },
  mounted() {
    // jQuery
    $(document).ready(function () {
      // 按鈕文字顏色
      $(".pagination").attr("class", "pagination mb-0");
      $(".page-link").css("border", "transparent");
      $(".page-link").css("color", "black");
      $("li.page-item").css("color", "black");

      // 按下一頁後的樣式
      $(".page-item").css("background-color", "transparent");
    });
  },
  watch: {
    // 頁面返回
    page: (OldVal, NewVal) => {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.getFavoriteList();
  },
};
</script>
<style lang="css">
.page-item {
  background-color: transparent;
  color: black;
}

.page-item.active .page-link {
  background-color: #c0ac79;
  color: black;
  border: 1px solid #c0ac79;
}

.page-item.active {
  background-color: #c0ac79;
  color: black;
}

.page-link {
  background-color: transparent;
  color: black;
  border: transparent;
}

.page-link:hover {
  background-color: transparent;
  color: black;
  border: transparent;
}

.hiddenElement {
  display: none !important;
}

.favorite-button {
  height: 20px;
}
</style>

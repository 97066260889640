<template>
  <template v-if="showMenu">
    <div class="header sticky-top">
      <nav class="navbar">
        <ul class="navbar-nav mr-auto" v-if="type == 'member'">
          <li class="nav-item">
            <span id="back_href" class="nav-link">
              <i data-eva="arrow-ios-back"></i>
              <span v-on:click="goMenu" id="back_text">回到選單</span>
            </span>
          </li>
        </ul>

        <span class="navbar-brand mx-auto">
          <img alt="Logo" v-bind:src="LogoImage" />
        </span>

        <button class="navbar-brand ml-auto home-icon" v-if="type == 'member'">
          <img v-on:click="goMenu" alt="Logo" v-bind:src="homeIcon" />
        </button>

        <!-- <div class="ml-auto d-flex" style="width: 92px;">
        <button class="navbar-toggler" type="button">
          <i data-eva="search"></i>
        </button>

        <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbar"
          aria-expanded="false">
          <i data-eva="menu"></i>
          <i data-eva="close"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbar">
        <ul class="navbar-nav ml-auto">


          <li class="nav-item">
            <router-link class="nav-link" to="/">
              登出
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" to="/">
              登入
            </router-link>
          </li>


        </ul>
      </div> -->
      </nav>
    </div>
    <div>
      <div class="image">
        <img class="w-100" v-bind:src="Bgimage" alt="Backgorund">
      </div>
    </div>
    <div class="wrapper">
      <main class="main">
        <div class="loding d-flex justify-content-center">
          <loading v-model:active="isLoading" :loader="'dots'" :can-cancel="true" />
        </div>
        <!-- 內容 -->
        <div class="home">
          <section class="section">
            <div class="container">
              <div class="form-row" v-bind:class="{ DontShow: type != 'member' }">
                <div class="col-6 icon-text">
                  <img
                    alt="我的物件"
                    src="/imgs/icon_01.png"
                    @click="redirect('/member/estate-century')"
                  /><br />
                  <span @click="redirect('/member/estate-century')">我的物件</span>
                </div>
                <div class="col-6 icon-text">
                  <img
                    alt="同業物件訂閱"
                    src="/imgs/icon_02.png"
                    @click="redirect('/member/estate-competitor')"
                  /><br />
                  <span @click="redirect('/member/estate-competitor')">同業物件訂閱</span>
                </div>
              </div>

              <div class="form-row HomeCaseButton mb-5" v-if="type == 'home'">
                <div class="col-4 icon-text">
                  <img
                    alt="21物件icon"
                    src="/imgs/icon_03.png"
                    @click="redirect('/estate-century/filter')"
                  /><br />
                  <span @click="redirect('/estate-century/filter')">C21物件/<br />21物件聯賣</span>
                </div>
                <div class="col-4 icon-text">
                  <img
                    alt="同業物件"
                    src="/imgs/icon_01.png"
                    @click="redirect('/estate-competitor/filter')"
                  /><br />
                  <span @click="redirect('/estate-competitor/filter')"
                    >全市場物件/<br />同業物件</span
                  >
                </div>
                <div class="col-4 icon-text">
                  <img
                    alt="屋主自售"
                    src="/imgs/icon_02.png"
                    @click="redirect('/estate-fsbo/filter')"
                  /><br />
                  <span @click="redirect('/estate-fsbo/filter')">全市場物件/<br />屋主自售</span>
                </div>
              </div>

              <div class="form-row HomeCaseButton" v-if="type == 'home'">
                <div class="col-4 icon-text">
                  <img alt="會員中心" src="/imgs/icon_0.png" @click="changeType('member')" /><br />
                  <span style="color: #c0ac79" @click="changeType('member')">會員中心</span>
                </div>
                <div class="col-4 icon-text">
                  <img
                    alt="製作EDM"
                    src="/imgs/icon_04.png"
                    @click="redirect('/edm-generator/filter')"
                  /><br />
                  <span @click="redirect('/edm-generator/filter')">製作EDM</span>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  </template>
  <!-- Nav Bar -->
</template>

<script>
import HomeOption from "@/components/HomeOption.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  components: {
    HomeOption,
  },
  data() {
    return {
      Bgimage: '/imgs/longCover.png',
      showMenu: false,
      basePath: process.env.VUE_APP_BASEPATH,
      auth0URL: process.env.VUE_APP_LOGIN,
      devURL: process.env.VUE_APP_WEBSITEDEV,
      NewList: true,
      LogoImage: "/imgs/logo.png",
      homeIcon: "/imgs/round_home_white_24dp.png",
      target: this.$route.query.target ?? "6",
      code: this.$route.query.code,
      type: "home",
    };
  },
  methods: {
    clearCache() {
      localStorage.clear();
      sessionStorage.clear();
      const cookies = document.cookie.split(";");
      let names = [];
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        names.push(name);
      }
      if(names.length > 0) {
        names.map(name => {
          document.cookie = name + "=;expires=" + new Date().toUTCString();
          console.log(document.cookie)
        });
      }
    },
    goMenu() {
      location.href = process.env.VUE_APP_C21;
    },
    getType() {
      if (this.target == "1") {
        this.redirect("/estate-century/filter");
      } else if (this.target == "2") {
        this.redirect("/estate-competitor/filter");
      } else if (this.target == "3") {
        this.redirect("/estate-fsbo/filter");
      } else if (this.target == "4") {
        this.$router.push({ path: "/edm-generator/filter" });
      } else if (this.target == "5") {
        this.type = "member";
      } else if (this.target == "6") {
        this.$router.push({ path: "/edm-generator/store" });
      } else if (this.target == "7") {
        this.redirect("/estate-century/filter-store");
      } else if (this.target == "8") {
        this.$router.push({ path: "/edm-generator/filter-store" });
      } else {
        this.type = "home";
      }
    },
    getToken() {
      const token = sessionStorage.getItem("token");
      const permission = sessionStorage.getItem("permission");
      const id_token = sessionStorage.getItem("id_token");
      const access_token = sessionStorage.getItem("access_token");
      if (!token || permission == null || permission == "N" || !id_token || !access_token) {
        if (this.code && this.target) {
          const url = `${this.basePath}/api/sso-auth/get_token?code=${this.code}&redirect_uri=${this.devURL}&target=${this.target}`;
          this.isLoading = true;
          fetch(url, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
          })
            .then((res) => {
              this.showMenu = true;
              return res.json();
            })
            .then((data) => {
              if (data?.error_code == 0 && data?.data?.access_token && data?.data?.id_token) {
                sessionStorage.setItem("token", data?.data?.access_token);
                sessionStorage.setItem("access_token", data?.data?.access_token);
                sessionStorage.setItem("id_token", data?.data?.id_token);
                sessionStorage.setItem("permission", data?.data?.access_token ? "Y" : "N");
                this.getType();
                this.isLoading = false;
              } else {
                this.clearCache();
                alert("登入時間已到，請重新登入");
                window.location.href = `${this.auth0URL}?scope=openid%20profile%20email&response_type=code&client_id=MOLIJlJ4xtGxUP0f4mgYqIzUqTVOYp4u&redirect_uri=${this.devURL}?target=${this.target}`;
                this.isLoading = false;
              }
            })
            .catch((error) => {
              console.log(error);
              // api修復後，可恢復註解
              this.clearCache();
              alert("無法取得token，請重新登入");
              window.location.href = `${this.auth0URL}?scope=openid%20profile%20email&response_type=code&client_id=MOLIJlJ4xtGxUP0f4mgYqIzUqTVOYp4u&redirect_uri=${this.devURL}?target=${this.target}`;
              this.isLoading = false;
            });
        } else {
          window.location.href = `${this.auth0URL}?scope=openid%20profile%20email&response_type=code&client_id=MOLIJlJ4xtGxUP0f4mgYqIzUqTVOYp4u&redirect_uri=${this.devURL}?target=${this.target}`;
        }
      }
    },
    changeType(type) {
      this.type = type;
    },
    redirect(url) {
      this.$router.push({ path: url });
    },
  },
  created() {
    const access_token = sessionStorage.getItem("access_token");
    const id_token = sessionStorage.getItem("id_token");
    if (!access_token || !id_token) {
      this.getToken();
    } else {
      this.showMenu = true;
      this.getType();
    }
  },
};
</script>

<style>
.hiddenClass {
  display: none;
}

.DontShow {
  display: none;
}

.HomeCaseButton:hover {
  cursor: pointer;
}

.icon-text {
  cursor: auto !important;
}

.icon-text img {
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.icon-text span {
  display: contents !important;
  color: rgb(192, 172, 121);
  margin-top: 0.5rem;
  cursor: pointer;
}

.home-icon img {
  width: 30px;
}
</style>

<template>
  <header class="page-header">
      <div class="logo-outer-wrap">
          <div class="logo-wrap">
              <img src="@/assets/imgs/edm/logo-white.png" alt="logo">
          </div>
      </div>
      <div class="branch-name">
          <span>{{ user.store_name }}</span>    
      </div>
  </header>
</template>

<script>
import { useRoute } from 'vue-router';
import QrcodeVue from 'qrcode.vue';
import imageToBase64 from "image-to-base64/browser";

export default {
  components: {
    QrcodeVue
  },
  data() {
    return {
      Packages: [],
      userImage: ""
    }
  }, 
  props: ['user'],
  async mounted() {
    this.userImage = await imageToBase64("https://www.century21.com.tw/store/images/jobnewsman.png");
  },
  methods: {
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : '';
    },
    getQRcode(employee_id) {
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(employee_id)}`;
    },
  },
}
</script>

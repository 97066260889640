import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// 首頁
import HomePage from '../components/HomePage.vue';

// 四個選項頁面
import CompetitorView from '../views/pages/estate-competitor/filter.vue';
import FsboView from '../views/pages/estate-fsbo/filter.vue';
import CenturyView from '../views/pages/estate-century/filter.vue';
import CenturyStoreView from '../views/pages/estate-century/filter-store.vue';
import GeneratorView from '../views/pages/edm-generator/filter.vue';
import GeneratorStoreView from '../views/pages/edm-generator/filter-store.vue';

// 四個首頁頁面
import CompetitorIndex from '../views/pages/estate-competitor/index.vue';
import FsboIndex from '../views/pages/estate-fsbo/index.vue';
import CenturyIndex from '../views/pages/estate-century/index.vue';
import CenturyStore from '../views/pages/estate-century/store.vue';
import GeneratorIndex from '../views/pages/edm-generator/index.vue';
import GeneratorStore from '../views/pages/edm-generator/store.vue';

//物件資訊
import CompetitorObject from '../views/pages/estate-competitor/object.vue';
import FsboObject from '../views/pages/estate-fsbo/object.vue';
import CenturyObject from '../views/pages/estate-century/object.vue'
import GeneratorObject from '../views/pages/edm-generator/object.vue'

// EDM業務頁面
import SelectStyle from '../views/pages/edm-generator/uiSelect.vue';
import EdmPreview from '../views/pages/edm-generator/preview.vue';
import EdmResult from '../views/pages/edm-generator/result.vue';

// EDM公開頁面 style-1
import Edms1_1 from '../views/pages/OpenEdm/style1-1.vue';
import Edms1_2 from '../views/pages/OpenEdm/style1-2.vue';
import Edms1_4 from '../views/pages/OpenEdm/style1-4.vue';
import Edms1_6 from '../views/pages/OpenEdm/style1-6.vue';
import Edms1_8 from '../views/pages/OpenEdm/style1-8.vue';
import Edms2_1 from '../views/pages/OpenEdm/style2-1.vue';
import Edms2_2 from '../views/pages/OpenEdm/style2-2.vue';
import Edms2_4 from '../views/pages/OpenEdm/style2-4.vue';
import Edms2_6 from '../views/pages/OpenEdm/style2-6.vue';
import Edms2_8 from '../views/pages/OpenEdm/style2-8.vue';
import Edms3_1 from '../views/pages/OpenEdm/style3-1.vue';
import Edms3_2 from '../views/pages/OpenEdm/style3-2.vue';
import Edms3_4 from '../views/pages/OpenEdm/style3-4.vue';
import Edms3_6 from '../views/pages/OpenEdm/style3-6.vue';
import Edms3_8 from '../views/pages/OpenEdm/style3-8.vue';

//會員中心
import MemberCentury from '../views/pages/member-estate-century/index.vue';
import MemberCompetitor from '../views/pages/member-estate-competitor/index.vue';
import MemberCenturyObject from '../views/pages/member-estate-century/object.vue';
import MemberCompetitorObject from '../views/pages/member-estate-competitor/object.vue';


//登入
import Login from '../components/Login.vue';

function addOpenExternalBrowser(to: any) {
  if(!Object.keys(to.query).includes('openExternalBrowser')) {
    return { path: to.path, query: {...to.query, openExternalBrowser: 1} }
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    beforeEnter: [addOpenExternalBrowser]
  },

  // 搜尋頁面
  {
    path: '/estate-competitor/filter',
    name: 'Competitor',
    component: CompetitorView,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/estate-fsbo/filter',
    name: 'Fsbo',
    component: FsboView,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/estate-century/filter',
    name: 'Century',
    component: CenturyView,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/estate-century/filter-store',
    name: 'CenturyStoreFilter',
    component: CenturyStoreView,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/edm-generator/filter',
    name: 'Generator',
    component: GeneratorView,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/edm-generator/filter-store',
    name: 'GeneratorStoreFilter',
    component: GeneratorStoreView,
    beforeEnter: [addOpenExternalBrowser]
  },

  // 搜尋結果頁面
  {
    path: '/estate-competitor/index',
    name: 'CompetitorIndex',
    component: CompetitorIndex,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/estate-fsbo/index',
    name: 'FsboIndex',
    component: FsboIndex,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/estate-century/index',
    name: 'CenturyIndex',
    component: CenturyIndex,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/estate-century/store',
    name: 'CenturyStore',
    component: CenturyStore,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/edm-generator/index',
    name: 'GeneratorIndex',
    component: GeneratorIndex,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/edm-generator/store',
    name: 'GeneratorStore',
    component: GeneratorStore,
    beforeEnter: [addOpenExternalBrowser]
  },

  // 發送物件資訊
  {
    path: '/estate-competitor/object',
    name: 'CompetitorObject',
    component: CompetitorObject,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/estate-fsbo/object',
    name: 'FsboObject',
    component: FsboObject,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/estate-century/object',
    name: 'CenturyObject',
    component: CenturyObject,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/edm-generator/object',
    name: 'GeneratorObject',
    component: GeneratorObject,
    beforeEnter: [addOpenExternalBrowser]
  },

  // 製作EDM 選風格 預覽 發送
  {
    // 風格
    path: '/edm-generator/uiSelect',
    name: 'SelectStyle',
    component: SelectStyle,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    // 預覽
    path: '/edm-generator/preview',
    name: 'EdmPreview',
    component: EdmPreview,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    // 發送
    path: '/edm-generator/result',
    name: 'EdmResult',
    component: EdmResult,
    beforeEnter: [addOpenExternalBrowser]
  },
  //EDM頁面
  {
    path: '/edm/tem1-1',
    name: 'edm1-1',
    component: Edms1_1,
    beforeEnter: [addOpenExternalBrowser]
  }
  , {
    path: '/edm/tem1-2',
    name: 'edm1-2',
    component: Edms1_2,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/edm/tem1-4',
    name: 'edm1-4',
    component: Edms1_4,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/edm/tem1-6',
    name: 'edm1-6',
    component: Edms1_6,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/edm/tem1-8',
    name: 'edm1-8',
    component: Edms1_8,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/edm/tem2-1',
    name: 'edm2-1',
    component: Edms2_1,
    beforeEnter: [addOpenExternalBrowser]
  }
  , {
    path: '/edm/tem2-2',
    name: 'edm2-2',
    component: Edms2_2,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/edm/tem2-4',
    name: 'edm2-4',
    component: Edms2_4,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/edm/tem2-6',
    name: 'edm2-6',
    component: Edms2_6,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/edm/tem2-8',
    name: 'edm2-8',
    component: Edms2_8,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/edm/tem3-1',
    name: 'edm3-1',
    component: Edms3_1,
    beforeEnter: [addOpenExternalBrowser]
  }
  , {
    path: '/edm/tem3-2',
    name: 'edm3-2',
    component: Edms3_2,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/edm/tem3-4',
    name: 'edm3-4',
    component: Edms3_4,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/edm/tem3-6',
    name: 'edm3-6',
    component: Edms3_6,
    beforeEnter: [addOpenExternalBrowser]
  }, {
    path: '/edm/tem3-8',
    name: 'edm3-8',
    component: Edms3_8,
    beforeEnter: [addOpenExternalBrowser]
  },

  //會員中心
  {
    path: '/member/estate-century',
    name: 'member-estate-century',
    component: MemberCentury,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/member/estate-competitor',
    name: 'member-estate-competitor',
    component: MemberCompetitor,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/member/estate-century/object',
    name: 'member-estate-century-object',
    component: MemberCenturyObject,
    beforeEnter: [addOpenExternalBrowser]
  },
  {
    path: '/member/estate-competitor/object',
    name: 'member-estate-competitor-object',
    component: MemberCompetitorObject,
    beforeEnter: [addOpenExternalBrowser]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// // 設定路由MiddleWare
router.beforeEach(to => {  
  const token = sessionStorage.getItem('token');
  const access_token = sessionStorage.getItem('access_token');
  const permission = sessionStorage.getItem('permission');
  const id_token = sessionStorage.getItem("id_token");

  if(to.name === 'edm1-1' || to.name === 'edm1-2' || to.name === 'edm1-4' || to.name === 'edm1-6' || to.name === 'edm1-8') {
    return;
  }

  if(to.name === 'Home') {
    // window.location.href = process.env.VUE_APP_C21;
    return;
  }

  // 登入頁（首頁）不用驗證
  if (to.name === 'Login') return;

  // 公開emd不用驗證
  for (let i = 1; i < 4; i++) {
    if (to.name == `edm${i}-1`) return;
    for (let j = 2; j < 10; j += 2) {
      if (to.name == `edm${i}-${j}`) return;
    }
  }
  let target = to.path.includes('estate-century/filter-store') ? '7' : to.path.includes('estate-century') ? '1' : 
  to.path.includes('/edm-generator/store') ? '6' :
  to.path.includes('/edm-generator/filter-store') ? '8' :
  to.path.includes('estate-competitor') ? '2' : 
  to.path.includes('estate-fsbo') ? '3' : 
  to.path.includes('edm-generator') ? '4' : 
  to.path.includes('member') ? '5' : '1';

  if (!token || permission == null || permission == 'N' || !id_token || !access_token) {
    window.location.href = `${process.env.VUE_APP_LOGIN}?scope=openid%20profile%20email&response_type=code&client_id=MOLIJlJ4xtGxUP0f4mgYqIzUqTVOYp4u&redirect_uri=${process.env.VUE_APP_WEBSITEDEV}?target=${target}`;
    return;
  }

  // 所有頁面Token過期重新登入
  let url = `${process.env.VUE_APP_BASEPATH}/api/estate-century/21century`;
  url += `?access_token=${access_token}&id_token=${id_token}`;

  fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      token: token,
    },
    method: "POST"
  }).then(res => {
    return res.json();
  }).then(formData => {
    // 過期重新登入
    if (formData.status == false || formData.error_code == 3) {
      let tokenConfirm = confirm('請重新登入');
      localStorage.clear();
      sessionStorage.clear();
      const cookies = document.cookie.split(";");
      let names = [];
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        names.push(name);
      }
      if (names.length > 0) {
        names.map(name => {
          document.cookie = name + "=;expires=" + new Date().toUTCString();
          console.log(document.cookie)
        });
      }
      if (tokenConfirm) {
        window.location.href = `${process.env.VUE_APP_LOGIN}?scope=openid%20profile%20email&response_type=code&client_id=MOLIJlJ4xtGxUP0f4mgYqIzUqTVOYp4u&redirect_uri=${process.env.VUE_APP_WEBSITEDEV}?target=${target}`
      } else {
        alert('登入時間已到，請重新登入');
        window.location.reload();
      }
    } else {
      console.log('正常運行中......');
    }
  }
  ).catch((err) => {
    console.log('===Error===');
    console.log(err);
  })
});


export default router;

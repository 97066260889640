<template>
  <div class="edm-1-8 w-1240 mx-auto">
    <!-- EDM預覽 -->
    <section class="section py-0">
      <div class="container-fluid p-0">
        <!-- EDM風格一 -->
        <div class="edm-style-1-8">
          <!-- head -->
          <EdmHeader :user="user" />
          <div class="body default-bg">
            <div class="mainContent d-flex justify-content-between flex-wrap openEdm">
              <div
                v-for="(items, index) in Packages.items"
                class="objectCard mb-3 d-flex"
                @click="goObject(items.hUrl, items.d_type)"
              >
                <!-- 左半部 -->
                <div class="objectLeft">
                  <div class="image">
                    <img :src="`${items.image}`" alt="object image" />
                  </div>
                  <div class="title py-1 px-4 d-flex align-items-center justify-content-center">
                    <span>
                      {{ items.name }}
                    </span>

                    <!-- 編號 -->
                    <div class="objectNo">
                      {{ index + 1 }}
                    </div>
                  </div>
                  <div
                    class="total-box py-1 box d-flex align-items-center justify-content-center flex-column"
                  >
                    <div class="total">
                      總價 $
                      <b class="text-danger">{{ items.price.toLocaleString() }}</b>
                      萬
                    </div>
                  </div>

                  <!-- 右半部 -->
                  <div class="objectRight p-0 m-0">
                    <div class="baseInfo box">
                      <ul class="list d-flex flex-column">
                        <div class="col-12 p-0 m-0">
                          <li style="height: calc(54px * 1.5)">
                            <div class="label">地址</div>
                            <div class="address-wrap">{{ items.county }}{{ items.district }}{{ items.address }}</div>
                          </li>
                        </div>
                        <div class="col-12 p-0 m-0 d-flex">
                          <div class="col-5 p-0 m-0 mr-2">
                            <li>
                              <div class="label">總坪數</div>
                              <span class="text-danger">{{
                                Number(items.total_floor_space).toFixed(2)
                              }}</span>
                              坪
                            </li>

                            <li>
                              <div class="label">種類</div>
                              <div class="text">{{ items.type }}</div>
                            </li>
                          </div>
                          <div class="col-6 p-0 m-0">
                            <li>
                              <div class="label mb-1">格局</div>
                              <div class="text">{{
                                setStructure(
                                  Number(items.structure_room),
                                  Number(items.structure_hall),
                                  Number(items.structure_bath)
                                )
                              }}</div>
                            </li>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- footer -->
          <EdmFooter />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import EdmFooter from "@/components/EdmFooter.vue";
import EdmHeader from "@/components/EdmHeader.vue";
import { useRoute } from "vue-router";

export default {
  components: {
    EdmHeader,
    EdmFooter,
  },
  data() {
    return {
      Packages: [],
      basePath: process.env.VUE_APP_BASEPATH,
      user: {},
    };
  },
  created() {
    $("head").append(
      '<meta name="viewport" content="width=device-width,initial-scale=0.3,user-scalable=yes">'
    );

    // // 取得物件數量判定是符合此模板
    // let itemsArr = items.split(',')

    // 取得物件與列印
    const route = useRoute();
    let { id, c } = route.query;
    let url = `${this.basePath}/api/edm?id=${id}&c=${c}`;

    fetch(url, {})
      .then((res) => {
        return res.json();
      })
      .then((formData) => {
        let edmLength = formData.data.edm.items.length;
        // 若物件不符合模板轉跳回首頁
        if (edmLength != 8) {
          alert("物件數量不符合此風格！即將轉跳回首頁");
          window.location.href = "/";
        } else {
          this.Packages = formData.data.edm;
          this.user = this.Packages.user.user_info;
        }
      });
  },
  methods: {
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : "";
    },
    getURL(url, type) {
      let urlArray = url.split("/");
      let id = urlArray[urlArray.length - 1];
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(this.user.employee_id)}/${
        type == "R" ? "rent" : "buy"
      }/${id}`;
    },
    goObject(url, type) {
      window.open(this.getURL(url, type));
    },
    setStructure(room, hall, bath) {
      const rooms = [];
      if (room > 0) {
        rooms.push(room + " 房");
      }
      if (hall > 0) {
        rooms.push(hall + " 廳");
      }
      if (bath > 0) {
        rooms.push(bath + " 衛");
      }
      return rooms.length > 0 ? rooms.join(" | ") : "-";
    },
  },
};
</script>
<style>
.objectCard {
  cursor: pointer;
}
</style>

<template>
  <NavBar2 />

  <div>
    <!-- {{ Packages.items[0].image }}
    {{ Packages.items[0].id }}
    {{ Packages.items[0].name }} -->

    <!-- {{ Packages.items[12] }} -->

    <!-- <div v-for="items in Packages.items" > {{items.id}}</div> -->

  </div>

  <section class="section">
    <div class="container">

      <!-- <a href="{{ route('front.estate-century.filter') }}" class="btn btn-primary">重新篩選</a> -->
      <!-- <h5>21物件!!</h5>  -->

      <!--呈現的條件-->
      <small class="filter-text">
        搜尋條件：
        <span v-if="keyword">{{ keyword }},</span>
        <span v-if="address">{{ address }},</span>
        <span v-if="store_name">{{ store_name }},</span>
        <span v-if="county">{{ county }},</span>
        <template v-if="district">
          <span v-if="typeof district == 'string'">{{ district }},</span>
          <span v-else v-for="item in district">{{ item }},</span>
        </template>
        <span v-if="total_floor">總樓層 {{ total_floor }},</span>
        <span v-if="floor">所在樓層 {{ floor == '-1' ? 'B1' : floor }},</span>
        <span v-if="structure">格局 {{ structure }},</span>
        <span v-if="min_structure_room || min_structure_hall || min_structure_bath">格局 {{ min_structure_room }} 房 {{ min_structure_hall }} 廳 {{ min_structure_bath }} 衛,</span>
        <span v-if="age">屋齡 {{ age }} 年,</span>
        <span v-if="min_floor_space">主建物坪數 {{ min_floor_space }} ~ </span>
        <span v-if="max_floor_space">{{ max_floor_space }} 坪,</span>
        <span v-if="min_total_floor_space">總坪數 {{ min_total_floor_space }} ~ </span>
        <span v-if="max_total_floor_space">{{ max_total_floor_space }} 坪,</span>
        <span v-if="min_price">總價 {{ Number(min_price).toLocaleString() }} ~ </span>
        <span v-if="max_price">{{ Number(max_price).toLocaleString() }} 萬,</span>
        <span v-if="date_range">{{ getDate(date_range) }},</span>
        <template v-if="type">
          <span v-if="typeof type == 'string'">{{ type }},</span>
          <span v-else v-for="item in type">{{ item }},</span>
        </template>
        <span v-if="status">{{ status }},</span>
        <span v-if="d_type">{{ d_type == 'S' ? "售屋" : "租屋" }},</span>
        <!-- {{ $d_param_key }}: --><br>
      </small>
      <form id="Form" action="">

        <!--要帶入的條件參數-->
        <input type="hidden" name="" value="">
        <div class="form-row mb-4">
          <div class="col-5">
            <!-- <label>排序方式</label> -->
            <select class="custom-select" @change="sortByOption($event)" name="sort" id="sort">
              <option value="">不限</option>
              <option value="price-desc">總價高到低排序</option>
              <option value="price-asc">總價低到高排序</option>
              <option value="total_floor_space-desc">坪數大到小排序</option>
              <option value="total_floor_space-asc">坪數小到大排序</option>
              <option value="age-desc">屋齡高到低排序</option>
              <option value="age-asc">屋齡低到高排序</option>
              <option value="post_at-desc" selected="selected">上架日期新到舊</option>
              <option value="post_at-asc">上架日期舊到新</option>
            </select>
          </div>
          <div class="col-6 d-flex">
            <small class="text-muted my-auto">共<span class="text-danger">{{ Packages?.pagination?.total }}
              </span>筆資料</small>
          </div>
        </div>
      </form>

      <!-- loding..... -->
      <!-- <loading :active.sync="LoaderVisiable" :can-cancel="true"></loading> -->
      <div class="loding d-flex justify-content-center">
        <loading v-model:active="isLoading" :loader="'dots'" :can-cancel="true" />
      </div>

      <div class="empty-list" v-if="Packages?.items?.length == 0 && isLoading == false">
        <span>目前沒有物件符合您的需求，請重新搜尋</span>
      </div>

      <ul class="estate-list" v-else>
        <li v-for="items in Packages.items" class="estate-list-item">
          <div class="form-row">
            <div class="col-8">
              <div class="form-row top-row mb-2">
                <div class="col">
                  <a class="name font-weight-bold" @click="objectLink(items.d_type, items.hUrl)">
                    {{ items.name }}
                  </a>
                </div>

              </div>
              <div class="estate-item">
                {{ items.county }}{{ items.district }}{{ items.address }}
                <span class="name">
                </span><br>
                <small>坪數{{ Number(items.total_floor_space).toFixed(2) }}｜{{ items.structure ? items.structure.replaceAll('.0', '') : '-' }}｜{{ Number(items.age) > 0 ? items.age + '年' : '-' }}
                  {{ '｜' + items.floorDescription }}{{ items.type ? '｜' + items.type : '' }}</small>
              </div>

              <!-- 案件來源、上架時間 -->
              <div class="ObjectInfo d-flex my-2">
                <div class="brand">
                  <small class="date">來源：{{ items.store_name }}</small>
                </div>
                <div class="createTime ml-3">
                  <small class="date">更新日期：{{ dateFormat(items.hModifyDate) }}</small>
                </div>
              </div>

              <button class="btn btn-primary btn-sm" type="button" @click="goObject(items)">分享案件</button>
            </div>

            <div class="col-4">
              <div class="image">
                <img :src="`${items.image}`" class="estate-item" data-id="">
              </div>
              <div class="text-right mt-1">
                <span class="price">{{ items.price.toLocaleString() }}</span><small>萬</small>
              </div>
              <div class="col-auto pr-0 d-block favorite-button">
                <button v-if="`${items.is_favorite}` == 1" v-on:click="changeFavorite(`${items.id}`)"
                  class="btn btn-sm favorite " :id="'favorite' + `${items.id}`" data-type="estate_century">
                  <i class="fa-solid fa-heart" style="color:#C0AC79;"></i>
                </button>

                <button v-else-if="`${items.is_favorite}` == 0" class="btn btn-sm favorite"
                  :id="'favorite' + `${items.id}`" v-on:click="changeFavorite(`${items.id}`)"
                  data-type="estate_century">
                  <i class="fa-regular fa-heart" style="color:#C0AC79;"></i>
                </button>
              </div>
            </div>

          </div>

        </li>

      </ul>
    </div>
  </section>

  <!-- 頁數選單 -->
  <div class="d-flex justify-content-center mb-5" v-if="Packages?.items?.length > 0">
    <paginate :page-count="`${Packages.pagination.total_pages}`" :page-range="3" :margin-pages="2"
      :click-handler="clickCallback" :prev-text="'<'" :next-text="'>'" :container-class="'pagination'"
      :page-class="'page-item'" v-model="page">
    </paginate>
  </div>

</template>

<script>
import $ from 'jquery';
import NavBar2 from '@/components/NavBar2.vue';
import { useRoute } from 'vue-router';

// 頁面按鈕
import Paginate from "vuejs-paginate-next";

// 排序引用
import dictionary from '@/assets/data/dictionary.json'

import Loading from "vue-loading-overlay";


export default {
  name: 'century',
  components: {
    NavBar2,
    paginate: Paginate,
    Loading
  },
  data() {
    const route = useRoute();

    let { keyword, address, store_name, county, district,
      total_floor, structure, age, min_floor_space, max_floor_space, min_total_floor_space, max_total_floor_space, min_price, max_price, date_range, type, status, d_type,
      min_structure_room, min_structure_hall, min_structure_bath, floor
    } = route.query;

    return {
      basePath: process.env.VUE_APP_BASEPATH,

      keyword: keyword,
      address: address,
      store_name: store_name,
      county: county,
      district: district,
      floor: floor,
      structure: structure,
      age: age,
      min_floor_space: min_floor_space,
      max_floor_space: max_floor_space,
      min_total_floor_space: min_total_floor_space,
      max_total_floor_space: max_total_floor_space,
      min_price: min_price,
      max_price: max_price,
      date_range: date_range,
      type: type,
      status: status,
      d_type: d_type,
      min_structure_room: min_structure_room,
      min_structure_hall: min_structure_hall,
      min_structure_bath: min_structure_bath,
      total_floor: total_floor,

      page: '',

      Packages: [],

      // 點擊收藏功能
      isFavorite: true,
      sort: ['post_at', 'desc'],

      isLoading: false
    }

  }, mounted() {
    // 這裡寫jQuery
    $(document).ready(function () {
      // 按鈕文字顏色
      $('.pagination').attr('class', 'pagination mb-0');
      $('.page-link').css('border', 'transparent');
      $('.page-link').css('color', 'black');
      $('li.page-item').css('color', 'black');
      // $('.page-item.active .page-link').css('border', 'transparent')

      // 按下一頁後的樣式
      $('.page-item').css('background-color', 'transparent');

    });

  }, methods: {
    sortByOption(event) {
      this.sort = event.target.value.split('-');
      this.page = 1;
      this.clickCallback(1);
    },

    // 頁面按鈕：更換頁面重新取得下一頁資料&返回最上頁
    clickCallback(pageNum) {
      this.isLoading = true;

      let url = `${this.basePath}/api/estate-century/21century?page=${pageNum}&keyword=${this.keyword}&address=${this.address}&store_name=${this.store_name}&county=${this.county}&district=${this.district}&total_floor=${this.total_floor}&min_floor=${this.floor}&max_floor=${this.floor}&ages=${this.age}&min_floor_space=${this.min_floor_space}&max_floor_space=${this.max_floor_space}&min_total_floor_space=${this.min_total_floor_space}&max_total_floor_space=${this.max_total_floor_space}&min_price=${this.min_price}&max_price=${this.max_price}&date_range=${this.date_range}&type=${this.type}&status=${this.status}&d_type=${this.d_type}&min_structure_room=${this.min_structure_room}&max_structure_room=${this.min_structure_room}&min_structure_hall=${this.min_structure_hall}&max_structure_hall=${this.min_structure_hall}&min_structure_bath=${this.min_structure_bath}&max_structure_bath=${this.min_structure_bath}`
      if(this.sort.length > 0) {
        url += `&sort_field=${this.sort[0]}&sort_type=${this.sort[1]}`;
      }

      let token = sessionStorage.getItem('token');
      let access_token = sessionStorage.getItem('access_token');
      let id_token = sessionStorage.getItem('id_token');

      url += `&access_token=${access_token}&id_token=${id_token}`;

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          token: token
        },
        method: "POST",
      }).then(res => {
        this.isLoading = false;
        return res.json();
      }).then(formData => {
        this.Packages = formData.data;
      })
    },


    // 收藏按鈕功能
    changeFavorite(id) {
      let type = 'estate_century';

      let token = sessionStorage.getItem('token');
      let url = `${this.basePath}/api/favorite/toggle`;

      let access_token = sessionStorage.getItem('access_token');
      let id_token = sessionStorage.getItem('id_token');

      url += `?access_token=${access_token}&id_token=${id_token}`;

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          token: token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        method: "POST",

        body: JSON.stringify(
          {
            id: id,
            type: type
          }
        )
      }).then(res => {
        return res.json();
      }).then(res => {
        let favorite = res.data.is_favorite;
        let favoriteID = document.getElementById('favorite' + id);


        if (favorite == 1) {
          favoriteID.innerHTML = '';
          favoriteID.innerHTML += `<i class="fa-solid fa-heart" style="color:#C0AC79;"></i>`
          alert('您已收藏此物件！')
        } else if (favorite == 0) {
          favoriteID.innerHTML = '';
          favoriteID.innerHTML += ` <i class="fa-regular fa-heart" style="color:#C0AC79;"></i>`
          alert('您已移除收藏此物件！')
        }

      })
    },

    objectLink(d_type, hUrl) {
      let [http, , domain, page, id] = hUrl.split('/');
      let url;
      if(d_type == 'R'){
        url = `${http}//${domain}/rentpage/${id}`;
      }else if(d_type == 'S'){
        url = `${http}//${domain}/buypage/${id}`;
      }
      window.open(url, '_black');
    },

    goObject(item) {
      const params = {
        name: item.name ?? '',
        d_type: item.d_type ?? '',
        type: item.type ?? '',
        structure_bath: item.structure_bath ?? 0,
        structure_hall: item.structure_hall ?? 0,
        structure_room: item.structure_room ?? 0,
        floor_space: item.total_floor_space ?? 0,
        price: item.price ?? 0,
        hUrl: item.hUrl ?? '',
        hSellerName: item.hSellerName ?? '',
        hSeller: item.hSeller ?? '',
        image: item.image ?? ''
      }
      this.$router.push({path: '/estate-century/object', query: params});
    },
    getDate(id) {
      let date;
      switch (id) {
        case "three-days":
          date = "三日內";
          break;
        case "one-week":
          date = "一週內";
          break;
        case "one-month":
          date = "本月";
          break;
        default:
          break;
      }
      return date;
    },

    saveFilter() {
      let body = JSON.stringify({
        keyword: this.keyword,
        address: this.address,
        store_name: this.store_name,
        county: this.county,
        district: this.district,
        floor: this.floor,
        total_floor: this.total_floor,
        structure: this.structure,
        age: this.age,
        min_floor_space: this.min_floor_space,
        max_floor_space: this.max_floor_space,
        min_total_floor_space: this.min_total_floor_space,
        max_total_floor_space: this.max_total_floor_space,
        min_price: this.min_price,
        max_price: this.max_price,
        date_range: this.date_range,
        type: this.type,
        status: this.status,
        d_type: this.d_type,
        min_structure_room: this.min_structure_room,
        min_structure_hall: this.min_structure_hall,
        min_structure_bath: this.min_structure_bath,
      })
      localStorage.setItem('estate_century_filter', body);
    },

    getFloor(floor, max_floor, total_floor) {
      return !total_floor && Number(total_floor) == 0 || (!floor && !max_floor) ? "未提供資訊" : 
      ((Number(floor) == 0 && Number(max_floor) == 0) || (Number(floor) == 0 && !max_floor) || (!floor && Number(max_floor) == 0)) ? "整棟" : 
      ((Number(floor) == -1 && Number(max_floor) == -1) || (Number(floor) == -1 && !max_floor) || (!floor && Number(max_floor) == -1)) ? "地下室" : 
      ((!floor || Number(floor) == 0) && Number(max_floor) != 0) ? `${max_floor}樓/${total_floor}樓` : 
      ((!max_floor || Number(max_floor) == 0) && Number(floor) != 0) ? `${floor}樓/${total_floor}樓` : 
      (floor == max_floor) ? `${floor}樓/${total_floor}樓`:`${floor}-${max_floor}樓/${total_floor}樓`;
    },

    dateFormat(date) {
      return date.replaceAll("-", "/");
    },

  },
  watch: {
    // 更換頁碼返回最上頁
    page: (NewVal, OldVal) => {
      window.scrollTo(0, 0);
    }

  },
  created() {
    // 搜尋欄的值
    const route = useRoute();

    let { keyword, address, store_name, county, district,
      floor, structure, age, min_floor_space, max_floor_space, min_total_floor_space, max_total_floor_space, min_price, max_price, date_range, type, status, d_type,
      min_structure_room, min_structure_hall, min_structure_bath, total_floor
    } = route.query;

    this.isLoading = true;

    this.saveFilter();

    // 獲取資料
    let url = `${this.basePath}/api/estate-century/21century?page=1&keyword=${keyword}&address=${address}&store_name=${store_name}&county=${county}&district=${district}&total_floor=${total_floor}&min_floor=${floor}&max_floor=${floor}&ages=${age}&min_floor_space=${min_floor_space}&max_floor_space=${max_floor_space}&min_total_floor_space=${min_total_floor_space}&max_total_floor_space=${max_total_floor_space}&min_price=${min_price}&max_price=${max_price}&date_range=${date_range}&type=${type}&status=${status}&d_type=${d_type}&min_structure_room=${min_structure_room}&max_structure_room=${min_structure_room}&min_structure_hall=${min_structure_hall}&max_structure_hall=${min_structure_hall}&min_structure_bath=${min_structure_bath}&max_structure_bath=${min_structure_bath}`

    let token = sessionStorage.getItem('token');
    let access_token = sessionStorage.getItem('access_token');
    let id_token = sessionStorage.getItem('id_token');

    if(this.sort.length > 0) {
      url += `&sort_field=${this.sort[0]}&sort_type=${this.sort[1]}`;
    }

    url += `&access_token=${access_token}&id_token=${id_token}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        token: token
      },
      method: "POST",
    }).then(res => {
      this.isLoading = false;
      return res.json();
    }).then(formData => {
      this.Packages = formData.data;

      // 頁碼
      if ((Packages.pagination.total_pages) <= 0) {
        Packages.pagination.total_pages = 1
      } else {
        Packages.pagination.total_pages = Packages.pagination.total_pages;
      }
    })
  },
}

</script>

<style lang="css">
.page-item {
  background-color: transparent;
  color: black;
}

.page-item.active .page-link {
  background-color: #C0AC79;
  color: black;
  border: 1px solid #C0AC79;
}

.page-item.active {
  background-color: #C0AC79;
  color: black;
}

.page-link {
  background-color: transparent;
  color: black;
  border: transparent;
}

.page-link:hover {
  background-color: transparent;
  color: black;
  border: transparent;
}

.favorite-button {
  height: 20px;
}
</style>

<template>
  <div class="edm-1-2 w-1240 mx-auto">
    <!-- EDM預覽 -->
    <section class="section py-0">
      <div class="container-fluid p-0">
        <!-- EDM風格一 -->
        <div class="edm-style-1-2">
          <!-- head -->
          <EdmHeader :user="user" />
          <div class="body pb-5 default-bg">
            <div class="mainContent d-flex justify-content-between openEdm">
              <div
                v-for="items in Packages.items"
                class="objectCard"
                style="width: 540px;"
                @click="goObject(items.hUrl, items.d_type)"
              >
                <div class="image">
                  <img :src="`${items.image}`" alt="object image" />
                  <div class="slogan px-4 py-2">
                    <span>我們為你</span>
                    <span>找到21世紀的家</span>
                  </div>
                </div>

                <div class="title py-3 px-4 d-flex align-items-center justify-content-center">
                  <span>
                    {{ items.name }}
                  </span>
                </div>

                <div class="total-box box py-3 d-flex align-items-center justify-content-center">
                  <div class="total">
                    總價 $ <b class="text-danger"> {{ items.price.toLocaleString() }}</b> 萬
                  </div>
                </div>

                <div class="baseInfo box">
                  <div class="title mb-3">物件基本資料</div>
                  <ul class="list">
                    <li style="height: 90px">
                      <div class="label">地址</div>
                      <div class="address-wrap">{{ items.county }}{{ items.district }}{{ items.address }}</div>
                    </li>
                    <li>
                      <div class="label">總坪數</div>
                      <span class="text-danger">{{
                        Number(items.total_floor_space).toFixed(2)
                      }}</span>
                      坪
                    </li>
                    <li>
                      <div class="label">格局</div>
                      <div class="text">
                        {{
                          setStructure(
                            Number(items.structure_room),
                            Number(items.structure_hall),
                            Number(items.structure_bath)
                          )
                        }}
                      </div>
                    </li>
                    <li>
                      <div class="label">樓層</div>
                      {{ items.floorDescription }}
                    </li>
                    <li>
                      <div class="label">屋齡</div>
                      {{ Number(items.age) > 0 ? items.age + "年" : "-" }}
                    </li>
                    <li>
                      <div class="label">種類</div>
                      <div class="text">{{ items.type }}</div>
                    </li>
                    <li>
                      <div class="label">車位</div>
                      {{ items.if_parking_space }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- footer -->
          <EdmFooter />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import EdmFooter from "@/components/EdmFooter.vue";
import EdmHeader from "@/components/EdmHeader.vue";
import { useRoute } from "vue-router";

export default {
  components: {
    EdmHeader,
    EdmFooter,
  },
  data() {
    return {
      Packages: [],
      basePath: process.env.VUE_APP_BASEPATH,
      user: {},
    };
  },
  created() {
    $("head").append(
      '<meta name="viewport" content="width=device-width,initial-scale=0.3,user-scalable=yes">'
    );
    // // 取得物件數量判定是符合此模板
    // let itemsArr = items.split(',')

    // 取得物件與列印
    const route = useRoute();
    let { id, c } = route.query;

    let url = `${this.basePath}/api/edm?id=${id}&c=${c}`;

    fetch(url, {})
      .then((res) => {
        return res.json();
      })
      .then((formData) => {
        let edmLength = formData.data.edm.items.length;
        // 若物件不符合模板轉跳回首頁
        if (edmLength != 2) {
          alert("物件數量不符合此風格！即將轉跳回首頁");
          window.location.href = "/";
        } else {
          this.Packages = formData.data.edm;
          this.user = this.Packages.user.user_info;
        }
      });
  },
  methods: {
    setStructure(room, hall, bath) {
      const rooms = [];
      if (room > 0) {
        rooms.push(room + " 房");
      }
      if (hall > 0) {
        rooms.push(hall + " 廳");
      }
      if (bath > 0) {
        rooms.push(bath + " 衛");
      }
      return rooms.length > 0 ? rooms.join(" | ") : "-";
    },
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : "";
    },
    getURL(url, type) {
      let urlArray = url.split("/");
      let id = urlArray[urlArray.length - 1];
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(this.user.employee_id)}/${
        type == "R" ? "rent" : "buy"
      }/${id}`;
    },
    goObject(url, type) {
      window.open(this.getURL(url, type));
    },
    getFloor(floor, max_floor, total_floor) {
      return (!total_floor && Number(total_floor) == 0) || (!floor && !max_floor)
        ? "未提供資訊"
        : (Number(floor) == 0 && Number(max_floor) == 0) ||
          (Number(floor) == 0 && !max_floor) ||
          (!floor && Number(max_floor) == 0)
        ? "整棟"
        : (Number(floor) == -1 && Number(max_floor) == -1) ||
          (Number(floor) == -1 && !max_floor) ||
          (!floor && Number(max_floor) == -1)
        ? "地下室"
        : (!floor || Number(floor) == 0) && Number(max_floor) != 0
        ? `${max_floor}樓/${total_floor}樓`
        : (!max_floor || Number(max_floor) == 0) && Number(floor) != 0
        ? `${floor}樓/${total_floor}樓`
        : floor == max_floor
        ? `${floor}樓/${total_floor}樓`
        : `${floor}-${max_floor}樓/${total_floor}樓`;
    },
  },
};
</script>
<style>
.objectCard {
  cursor: pointer;
}
</style>

<template>
    <footer class="page-footer">
        <div class="footer-logo">
            <img src="@/assets/imgs/edm/logo-footer_1.png" alt="logo">
        </div>
        <div class="footer-inner">
            <div class="footer-title-logo">
                <img src="@/assets/imgs/edm/logo-footer_2.png" alt="logo">
            </div>
            <p class="footer-des">AI 智慧串聯全球房市，掌握台灣趨勢動態，提供精準買賣策略讓我們為您找到21世紀的家</p>
        </div>
    </footer>
</template>

<script>
import { useRoute } from 'vue-router';
import QrcodeVue from 'qrcode.vue';
import imageToBase64 from "image-to-base64/browser";

export default {
  components: {
    QrcodeVue
  },
  data() {
    return {
      Packages: [],
      userImage: ""
    }
  }, 
  props: ['user'],
  async mounted() {
    this.userImage = await imageToBase64("https://www.century21.com.tw/store/images/jobnewsman.png");
  },
  methods: {
    toLowerCase(text) {
      return text ? text.toLowerCase().trim() : '';
    },
    getQRcode(employee_id) {
      return `${process.env.VUE_APP_WEBSITE}/agent/${this.toLowerCase(employee_id)}`;
    },
  },
}
</script>
<template>
  <!-- 內容 -->
  <div class="home">
    <section class="section">
      <div class="container">
        <div class="form-row" v-bind:class="{ DontShow: NewList }">
          <div class="col-4">
            <router-link to="/estate-competitor/filter" class="icon-text">
              <img alt="同業物件" src="/imgs/icon_01.png">
              <span>同業物件</span>
            </router-link>
          </div>
          <div class="col-4">
            <router-link to="/estate-fsbo/filter" class="icon-text">
              <img alt="屋主自售" src="/imgs/icon_02.png">
              <span>屋主自售</span>
            </router-link>
          </div>
          <div class="col-4">
            <router-link to="/estate-century/filter" class="icon-text">
              <img alt="21物件icon" src="/imgs/icon_03.png">
              <span>21物件</span>
            </router-link>
          </div>
          <div class="col-3"  v-if="NewList == true">
            <router-link to="/edm-generator/filter" class="icon-text">
              <img alt="製作EDM" src="/imgs/icon_04.png">
              <span>製作EDM</span>
            </router-link>
          </div>
        </div>

        <div class="form-row HomeCaseButton" v-if="NewList == true">
          <div class="col-6" v-on:click="getNewList">
            <div class="icon-text">
              <img alt="市場案件" src="/imgs/icon_0.png">
              <span style="color:#C0AC79;">市場案件</span>
            </div>
          </div>
          <div class="col-6">
            <router-link to="/edm-generator/filter" class="icon-text">
              <img alt="製作EDM" src="/imgs/icon_04.png">
              <span>製作EDM</span>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      NewList: true,
    }
  },
  methods: {
    getNewList: function () {
      this.NewList = false;
    }
  }
}
</script>

<style>
.DontShow {
  display: none;
}

.HomeCaseButton:hover {
  cursor: pointer;
}
</style>

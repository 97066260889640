<template>
  <NavBar />

  <section class="section px-3">
    <div class="container">

      <h5>C21物件/21物件聯賣</h5>
      <form method="GET" id="Form" action="/estate-century/index">

        <div class="form-group ">
          <label>關鍵字 </label>
          <input type="text" class="form-control input-field" name="keyword" id="keyword" placeholder="輸入案名/地址/加盟店名">
        </div>

        <!-- <div class="form-group " v-bind:class="{ showMore: isActive }">
          <label>地址 </label>
          <input type="text" class="form-control input-field" name="address" id="address" placeholder="輸入地址">
        </div> -->

        <div class="form-group " v-bind:class="{ showMore: isActive }">
          <label>加盟店 </label>
          <input type="text" class="form-control input-field" name="store_name" id="store_name" placeholder="輸入加盟店名稱">
        </div>

        <div class="form-row city-selector">
          <div class="col-6">
            <fieldset class="form-group">
              <label for="county">縣市</label>
              <select class="county custom-select select-field" data-name="county" id="county"
                data-style="custom-select" :data-value="county">
              </select>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset class="form-group">
              <label for="district">行政區</label>
              <select
                class="district custom-select select-field"
                data-name="district"
                id="district"
                data-style="custom-select"
                multiple="multiple"
                ></select>
            </fieldset>
          </div>
        </div>

        <div class="form-row">
          <div class="col-6">
            <fieldset class="form-group">
              <label>資料區分</label>
              <select class="custom-select select-field" name="d_type" id="d_type">
                <option value="">不限</option>
                <option value="R">租屋</option>
                <option value="S">售屋</option>
              </select>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset class="form-group">
              <label>類型</label>
              <select class="custom-select select-field" name="type" id="type" multiple="multiple">
                <!-- <option value="">不限</option> -->
                <option v-for="(type, index) in types" :value="index">
                  {{ type }}
                </option>
              </select>
            </fieldset>
          </div>
        </div>

        <div class="form-row">
          <div class="col-6">
            <fieldset class="form-group">
              <label>時間</label>
              <select class="custom-select select-field" name="date_range" id="date_range">
                <option value="">不限</option>
                <option value="three-days">三日內</option>
                <option value="one-week">一週內</option>
                <option value="one-month">本月</option>
              </select>
            </fieldset>
          </div>
          <div class="col-6">
            <fieldset class="form-group">
              <label>狀態</label>
              <select class="custom-select select-field" name="status" id="status">
                <option value="上架">上架</option>
                <!-- <option v-for="(status, index) in statuses" :value="index">
                  {{ status }}
                </option> -->
              </select>
            </fieldset>
          </div>
        </div>

        <div v-if="isActive == true" v-on:click="changeNoActive" v-bind:class="{ NoActive: isActive }">顯示更多</div>

        <div v-bind:class="{ showMore: isActive }">

          <div class="form-group">
            <label>總樓層 (請於下方欄位輸入數字) </label>
            <input
              type="text"
              class="form-control input-field"
              name="total_floor"
              placeholder="請輸入總樓層"
              id="total_floor"
            />
          </div>

          <div class="form-group">
            <label>所在樓層 (請於下方欄位輸入數字，如為地下1樓請輸入B1) </label>
            <input 
              type="text"
              class="form-control input-field"
              placeholder="請輸入樓層"
              v-model="floor"
            />
            <input
              v-show="false"
              type="text"
              class="form-control input-field"
              name="floor"
              placeholder="請輸入樓層"
              id="floor"
            />
          </div>

          <div class="form-group">
            <label>格局 </label>
            <div class="structure-block">
              <input
                type="text"
                class="form-control input-field"
                name="min_structure_room"
                placeholder="輸入房"
                id="min_structure_room"
              />
              <label>房 </label>
              <input
                type="text"
                class="form-control input-field"
                name="min_structure_hall"
                placeholder="輸入廳"
                id="min_structure_hall"
              />
              <label>廳 </label>
              <input
                type="text"
                class="form-control input-field"
                name="min_structure_bath"
                placeholder="輸入衛"
                id="min_structure_bath"
              />
              <label>衛 </label>
            </div>
          </div>

          <div class="form-group">
            <label>屋齡 (請於下方欄位輸入數字) </label>
            <div class="radio-container">
              <div class="radio-block">
                <input type="radio" class="form-control" value="unlimited" v-model="ageRadio"/>
                <label for="age">不限</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="0-5" v-model="ageRadio"/>
                <label for="age">0-5年</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="6-10" v-model="ageRadio"/>
                <label for="age">6-10年</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="11-20" v-model="ageRadio"/>
                <label for="age">11-20年</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="21-30" v-model="ageRadio"/>
                <label for="age">21-30年</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="31-40" v-model="ageRadio"/>
                <label for="age">31-40年</label>
              </div>
              <div class="radio-block">
                <input type="radio" class="form-control" value="41" v-model="ageRadio"/>
                <label for="age">41年以上</label>
              </div>
            </div>

            <div class="form-row form-group mt-3">
              <div class="col d-flex">
                <small class="age-text">最低</small>
                <input type="number" class="form-control input-field" v-model="min_age" placeholder="輸入數字"/>
                <small class="age-text">年</small>
              </div>
              <div class="col-auto d-flex px-0">
                <div class="my-auto text-muted">～</div>
              </div>
              <div class="col d-flex">
                <small class="age-text">最高</small>
                <input type="number" class="form-control input-field" v-model="max_age" placeholder="輸入數字"/>
                <small class="age-text">年</small>
              </div>
            </div>
            <input
              type="string"
              class="form-control input-field"
              name="age"
              id="age"
              v-show="false"
            />
          </div>

          <label>總坪數</label><br>
          <div class="form-row form-group">

            <div class="col d-flex">
              <input type="number" class="form-control input-field" name="min_total_floor_space" id="min_total_floor_space" placeholder="輸入總坪數最低坪數">
              <small class="mt-auto ml-1 text-muted">坪</small>
            </div>
            <div class="col-auto d-flex px-0">
              <div class="my-auto text-muted">～</div>
            </div>
            <div class="col d-flex">
              <input type="number" class="form-control input-field" name="max_total_floor_space" id="max_total_floor_space" placeholder="輸入總坪數最高坪數">
              <small class="mt-auto ml-1 text-muted">坪</small>
            </div>
          </div>

          <label>主建物坪數</label><br>
          <div class="form-row form-group">

            <div class="col d-flex">
              <input type="number" class="form-control input-field" name="min_floor_space" id="min_floor_space" placeholder="輸入主建物最低坪數">
              <small class="mt-auto ml-1 text-muted">坪</small>
            </div>
            <div class="col-auto d-flex px-0">
              <div class="my-auto text-muted">～</div>
            </div>
            <div class="col d-flex">
              <input type="number" class="form-control input-field" name="max_floor_space" id="max_floor_space" placeholder="輸入主建物最高坪數">
              <small class="mt-auto ml-1 text-muted">坪</small>
            </div>
          </div>

          <label>總價</label><br>
          <div class="form-row form-group">
            <div class="col d-flex">
              <input type="number" class="form-control input-field" name="min_price" id="min_price" placeholder="輸入最低總價">
              <small class="mt-auto ml-1 text-muted text-nowrap">萬元</small>
            </div>
            <div class="col-auto d-flex px-0">
              <div class="my-auto text-muted">～</div>
            </div>
            <div class="col d-flex">
              <input type="number" class="form-control input-field" name="max_price" id="max_price" placeholder="輸入最高總價">
              <small class="mt-auto ml-1 text-muted text-nowrap">萬元</small>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center mt-3 pt-3 flex-column">
          <button class="btn btn-primary btn-block" type="submit">確認</button>
          <button class="btn btn-dark btn-block mt-3" id="clear" type="button" @click="clearForm">清除</button>
        </div>
      </form>

    </div>
  </section>
</template>

<script>
// 引入
import NavBar from '@/components/NavBar.vue';
import collection from '@/assets/data/21century_collection.json';
import dictionary from '@/assets/data/dictionary.json'

// 縣市選單
import twCitySelector from "@/assets/js/tw-city-selector.js";
import multipleSelect from "@/assets/js/multiple-select.js";

// 輸出
export default {
  name: 'century',
  components: {
    NavBar,
  }, data() {
    return {
      // 改變
      isActive: true,

      // 時間
      date_ranges: dictionary.folders.date_range[0],
      // 類型
      types: dictionary.folders.type[0],
      // 狀態
      statuses: dictionary.folders.status[0],
      // 資料區分
      d_types: dictionary.folders.d_type[0],

      //屋齡
      ageRadio: 'unlimited',
      min_age: '',
      max_age: '',

      county: "",
      floor: '',

    }
  }, 
  watch: {
    min_age: function() {
      document.getElementById("age").value = `${this.min_age}-${this.max_age}`;
    },
    max_age: function() {
      document.getElementById("age").value = `${this.min_age}-${this.max_age}`;
    },
    ageRadio: function() {
      document.getElementById("age").value = this.ageRadio == 'unlimited' ? '' : this.ageRadio;
    },
    floor: function() {
      document.getElementById("floor").value = this.floor == 'B1' ? '-1' : this.floor;
    },
  },
  mounted() {
    const queryObject = JSON.parse(localStorage.getItem("estate_century_filter"));
    document.getElementById("d_type").value = "S";
    document.getElementById('status').value = '上架';
    if(queryObject) {
      document.getElementById("keyword").value = queryObject?.keyword ? queryObject?.keyword : "";
      // document.getElementById("county").value = queryObject?.county ?? "";
      this.county = queryObject?.county ?? "";
      // document.getElementById("district").value = queryObject?.district ?? "";
      document.getElementById("date_range").value = queryObject?.date_range ?? "";
      // document.getElementById("type").value = queryObject?.type ?? "";
      $('#type').val(queryObject?.type ?? "");
      document.getElementById("status").value = queryObject?.status ?? "上架";
      document.getElementById("d_type").value = queryObject?.d_type ?? "S";
      // document.getElementById('address').value = queryObject?.address ?? '';
      document.getElementById('store_name').value = queryObject?.store_name ?? '';
      document.getElementById("floor").value = queryObject?.floor ?? "";
      this.floor = document.getElementById("floor").value == '-1' ? 'B1' : document.getElementById("floor").value;
      document.getElementById("total_floor").value = queryObject?.total_floor ?? "";
      document.getElementById("min_structure_bath").value = queryObject?.min_structure_bath ?? "";
      document.getElementById("min_structure_hall").value = queryObject?.min_structure_hall ?? "";
      document.getElementById("min_structure_room").value = queryObject?.min_structure_room ?? "";
      document.getElementById("age").value = queryObject?.age ?? "";
      if (
        queryObject?.age == "0-5" ||
        queryObject?.age == "6-10" ||
        queryObject?.age == "11-20" ||
        queryObject?.age == "21-30" ||
        queryObject?.age == "31-40" ||
        queryObject?.age == "41"
      ) {
        this.ageRadio = queryObject?.age ?? "";
        this.min_age = "";
        this.max_age = "";
      } else {
        let ageArray = queryObject?.age.split("-");
        this.min_age = ageArray[0] ?? "";
        this.max_age = ageArray[1] ?? "";
        this.ageRadio = "";
      }
      document.getElementById("min_floor_space").value = queryObject?.min_floor_space ?? "";
      document.getElementById("max_floor_space").value = queryObject?.max_floor_space ?? "";
      document.getElementById("min_total_floor_space").value = queryObject?.min_total_floor_space ?? "";
      document.getElementById("max_total_floor_space").value = queryObject?.max_total_floor_space ?? "";
      document.getElementById("min_price").value = queryObject?.min_price ?? "";
      document.getElementById("max_price").value = queryObject?.max_price ?? "";
    }

    if(
      document.getElementById('store_name').value != "" ||
      document.getElementById("floor").value != "" ||
      document.getElementById("total_floor").value != "" ||
      document.getElementById("min_structure_bath").value != "" ||
      document.getElementById("min_structure_hall").value != "" ||
      document.getElementById("min_structure_room").value != "" ||
      document.getElementById("age").value != "" ||
      document.getElementById("min_floor_space").value != "" ||
      document.getElementById("max_floor_space").value != "" ||
      document.getElementById("min_total_floor_space").value != "" ||
      document.getElementById("max_total_floor_space").value != "" ||
      document.getElementById("min_price").value != "" ||
      document.getElementById("max_price").value
      ) {
        this.isActive = false;
      }

    // 縣市選單
    new twCitySelector({
      el: '.city-selector',
      elCounty: '.county', // 在 el 裡查找 county
      elDistrict: '.district', // 在 el 裡查找 District
      // 異體字 true是臺
      standardWords: true,
    });

    const vm = this;
    vm.initMultipleSelect();

    $('#county').change(function(event) {
      vm.initMultipleSelect();
    });
  },
  methods: {
    initMultipleSelect(){
      setTimeout(() => {
        $('#district option[value=""]').remove();
        $('#district').multipleSelect({
          width: '100%',
          placeholder: '選擇區域',
          ellipsis: true,
          minimumCountSelected: 8,
          selectAll: false,
        });
        
        $('#type').multipleSelect({
          width: '100%',
          placeholder: '不限',
          ellipsis: true,
          minimumCountSelected: 12,
          selectAll: false,
        });
      });
    },
    changeNoActive: function () {
      this.isActive = false
    },
    changeActive: function () {
      this.isActive = true
    },
    alertText() {
      let county = document.getElementById('county').value;
      if(!county.trim()) {
        alert("請先選擇縣市");
      }
    },
    clearForm() {
      document.getElementById('keyword').value = '';
      // document.getElementById('address').value = '';
      document.getElementById('store_name').value = '';
      document.getElementById('county').value = '';
      // document.getElementById('district').value = '';
      $('#district option').remove();
      $('#district').multipleSelect('refresh');
      document.getElementById('date_range').value = '';
      // document.getElementById('type').value = '';
      $('#type').multipleSelect('uncheckAll');
      document.getElementById('status').value = '上架';
      document.getElementById('d_type').value = 'S';
      document.getElementById("floor").value = "";
      document.getElementById("total_floor").value = "";
      document.getElementById("min_structure_bath").value = "";
      document.getElementById("min_structure_hall").value = "";
      document.getElementById("min_structure_room").value = "";
      document.getElementById('age').value = '';
      document.getElementById('min_floor_space').value = '';
      document.getElementById('max_floor_space').value = '';
      document.getElementById('min_total_floor_space').value = '';
      document.getElementById('max_total_floor_space').value = '';
      document.getElementById('min_price').value = '';
      document.getElementById('max_price').value = '';
      this.ageRadio = '';
      this.min_age = '';
      this.max_age = '';
      this.floor = '';
      localStorage.removeItem("estate_century_filter");
    }
  }
}
</script>


<style>
.showMore {
  display: none;
}

.NoActive {
  display: block;
  color: #C0AC79;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

@media screen and (min-width: 560px) {
  .radio-container {
    display: flex !important;
    justify-content: space-between;
  }
}
.radio-container {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 100px 100px 100px;
}
.age-text {
  white-space: nowrap;
  margin: auto 5px;
}
</style>

